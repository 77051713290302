import * as React from "react";
import { Modal, Form, InputNumber, message } from "antd";
import PicWall from "@/ui-components/PicWall";
import { repaymentApply } from "@/requests/business";

interface PropsType {
  visible: boolean;
  setVisible: Function;
  business: BusinessApplyRecordItem;
  reload: Function;
}

const RepaymentApplyModal = (props: PropsType) => {
  const { visible, setVisible, business, reload } = props;
  const [fetching, setFetching] = React.useState(false);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = (values: any) => {
    console.log(values);
    doRepay({
      amount: values["amount"],
      product_apply_id: business.id,
      transfer_certificate: values["transfer_certificate"]
        .filter((x: any) => x.status === "done")
        .map((x: any) => {
          return x.response.data;
        })
        .toString(),
    });
  };

  const doRepay = async (values: RepaymentApplyReq) => {
    setFetching(true);
    const res = await repaymentApply(values);
    if (res.code === 0) {
      message.success("提交成功");
      reload(business.id);
      setVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      title="还款申请"
      onOk={() => {
        form.submit();
      }}
      okButtonProps={{ loading: fetching }}
      cancelButtonProps={{ disabled: fetching }}
      destroyOnClose
    >
      <Form form={form} onFinish={handleOk} preserve={false}>
        <Form.Item label="还款金额">
          <Form.Item noStyle name={"amount"} initialValue={business.quota}>
            <InputNumber placeholder="还款金额" />
          </Form.Item>
          <span style={{ marginLeft: 8 }}>元</span>
        </Form.Item>
        <Form.Item label="还款截图" name={"transfer_certificate"}>
          <PicWall action="/api/file/upload" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RepaymentApplyModal;
