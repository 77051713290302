/// <reference path="type.d.ts" />
/// <reference path="credit.d.ts" />

import request from "./_base";

export const UPLOAD_BASE_ACTION = `/api/file/quota_application/upload`;

/**
 * 申请授信
 * @param  {ApplyCreditReq} input
 */
export async function applyCredit(
  input: CreditApplyReq
): Promise<ResponseProto<{ data: CreditInfoItem }>> {
  return request({
    method: "POST",
    url: `/comprehensive_credit_apply_approval/submit_apply_approval`,
    data: input,
  }) as any;
}

/**
 * 获取当前用户已经通过的授信信息
 */
export async function getMyCredit(): Promise<
  ResponseProto<{ data: CreditInfoItem }>
> {
  return request({
    method: "GET",
    url: `/comprehensive_credit/info`,
  }) as any;
}

/**
 * 获取当前用户的授信申请列表
 * @param  {GetMyCreditListReq} input
 */
export async function getMyCreditList(
  input: GetMyCreditListReq
): Promise<ResponseProto<{ data: CreditApplyItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/comprehensive_credit_apply_approval/list`,
    data: input,
  }) as any;
}
