import * as React from "react";
import {
  getMyBaseQuota,
  getQuotaFlowList,
  flowResearch,
} from "@/requests/quota";
import {
  message,
  PageHeader,
  Button,
  Empty,
  Table,
  Divider,
  Tooltip,
} from "antd";
import { Pie, measureTextWidth } from "@ant-design/charts";
import { useHistory, useLocation } from "react-router";
import { useCtxState } from "@/store/App.store";
import { toThousands } from "@/utils/string";

interface ChartDataItem {
  type: string;
  value: number;
}

const BaseQuota = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<BaseQuotaInfoItem | undefined>(
    undefined
  );
  const history = useHistory();
  const location = useLocation();
  const [chartData, setChartData] = React.useState<ChartDataItem[]>([]);
  const [{ userInfo }] = useCtxState();
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [flowData, setFlowData] = React.useState<QuotaFlowListItem[]>([]);

  const renderStatistic = (containerWidth: any, text: any, style: any) => {
    var _measureTextWidth = measureTextWidth(text, style),
      textWidth = _measureTextWidth.width,
      textHeight = _measureTextWidth.height;
    var R = containerWidth / 2;
    var scale = 1 as any;
    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }
    var textStyleStr = "width:".concat(containerWidth, "px;");
    return '<div style="'
      .concat(textStyleStr, ";font-size:")
      .concat(scale, "em;line-height:")
      .concat(scale < 1 ? "1" : "inherit", ';">')
      .concat(text, "</div>");
  };
  var config = {
    appendPadding: 10,
    data: chartData,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: function formatter(v: any) {
          return "".concat(v, " \xA5");
        },
      },
    },
    label: {
      type: "inner",
      offset: "-50%",
      style: { textAlign: "center" },
      autoRotate: false,
      content: "{value}",
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: function customHtml(container: any, view: any, datum: any) {
          var _container$getBoundin = container.getBoundingClientRect(),
            width = _container$getBoundin.width,
            height = _container$getBoundin.height;
          var d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          var text = datum ? datum.type : "总额度(元)";
          return renderStatistic(d, text, { fontSize: 24 });
        },
      },
      content: {
        offsetY: 4,
        style: { fontSize: "24px" },
        customHtml: function customHtml(
          container: any,
          view: any,
          datum: any,
          data: ChartDataItem[]
        ) {
          var _container$getBoundin2 = container.getBoundingClientRect(),
            width = _container$getBoundin2.width;
          var text = datum
            ? "\xA5 ".concat(datum.value)
            : "\xA5 ".concat(
                String(
                  data.reduce((r, d) => {
                    return r + d.value;
                  }, 0)
                )
              );
          return renderStatistic(width, text, { fontSize: 32 });
        },
      },
    },
    interactions: [
      { type: "element-selected" },
      { type: "element-active" },
      { type: "pie-statistic-active" },
    ],
  } as any;

  const fetchData = async () => {
    setLoading(true);
    const res = await getMyBaseQuota();
    if (res.code === 0) {
      setData(res.data);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };
  //获取额度流水
  const fetchFlowList = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }

    const res = await getQuotaFlowList({
      limit: 10,
      skip: 0,
    });
    if (res.code === 0) {
      setFlowData(res.data.records);
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
  };
  React.useEffect(() => {
    if (location.pathname === "/base/my-credit/base-quota") {
      fetchData();
      fetchFlowList();
    }
  }, [location]);
  React.useEffect(() => {
    if (data) {
      setChartData([
        { type: "已用额度(元)", value: data.used_quota },
        { type: "剩余额度(元)", value: data.remain_quota },
      ]);
    }
  }, [data]);
  return (
    <div
      style={{
        background: "#ffffff",
        padding: 16,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <PageHeader
        title={
          <>
            我的额度{" "}
            {data && (
              <span
                style={{ fontSize: 14, color: "#646464", fontWeight: "normal" }}
              >
                (额度有效期至：{data.credit_end_time})
              </span>
            )}
          </>
        }
        style={{ width: "100%", paddingBottom: 0 }}
        extra={[
          data && (
            <Button type="primary" onClick={() => message.info("暂不支持提额")}>
              申请提额
            </Button>
          ),
        ]}
      ></PageHeader>

      {data && (
        <div style={{}}>
          <Pie {...config} />
        </div>
      )}

      {!data && !loading && (
        <Empty description={<span>暂无额度信息</span>}>
          <Button
            type="primary"
            onClick={() => {
              const { info_complete_sign } = userInfo;
              if (info_complete_sign === 1) {
                history.push("/base/my-credit/credit-apply");
              } else {
                message.error(
                  "您的企业信息不完整，无法申请授信，请先完善您的企业资料"
                );
              }
            }}
          >
            立即申请
          </Button>
        </Empty>
      )}
      {data && <Divider>额度流水</Divider>}
      {data && (
        <Table
          style={{ width: "100%" }}
          dataSource={flowData}
          loading={loading}
          columns={[
            {
              title: "序号",
              render: (value, record, index) => index + 1,
              align: "center",
            },
            {
              title: "业务名称",
              render: (value, record, index) => record.flow_name,
              align: "center",
            },
            {
              title: "金额(元)",
              render: (value, record, index) => (
                <span
                  style={{
                    color: record.flow_name === "放款成功" ? "red" : "green",
                  }}
                >
                  {record.flow_name === "放款成功" && "-"}
                  {toThousands(record.credit_line)}
                </span>
              ),
              align: "center",
            },
            {
              title: "剩余额度(元)",
              render: (value, record, index) => (
                <span>{toThousands(record.remain_quota)}</span>
              ),
              align: "center",
            },
            {
              title: "流水号",
              render: (value, record, index) => (
                <Tooltip title="点击查看详情">
                  <a
                    onClick={async (e) => {
                      e.preventDefault();
                      if (record.flow_type === 1) {
                        const res = await flowResearch(record.id);
                        if (res.code === 0) {
                          if (res.data.apply_id) {
                            history.push({
                              pathname: "/base/business/record/detail",
                              search: `id=${res.data.apply_id}`,
                            });
                          }
                        }
                      }
                      if (record.flow_type === 0) {
                        history.push("/base/my-process/credit-process");
                      }
                    }}
                  >
                    {record.uuid}
                  </a>
                </Tooltip>
              ),
              align: "center",
            },
            {
              title: "时间",
              render: (value, record, index) => (
                <span>{record.create_time}</span>
              ),
              align: "center",
            },
          ]}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchFlowList(page, pageSize);
            },
            showTotal: (total) => <span>共 {total} 条数据</span>,
          }}
        />
      )}
    </div>
  );
};

export default BaseQuota;
