import * as React from "react";
import {
  Form,
  Input,
  Divider,
  Upload,
  Button,
  Layout,
  Modal,
  message,
  Select,
  InputNumber,
  Result,
  PageHeader,
} from "antd";
import { useHistory } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { applyCredit } from "@/requests/credit";
import { getCompanyInfo, UPLOAD_ACTION } from "@/requests/company";
import { download } from "@/utils/file";

interface FormValues extends CreditApplyReq {
  resolution: any;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const CreditApply = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [fetching, setFetching] = React.useState(false);
  const [companyInfo, setCompanyInfo] = React.useState<CompanyInfoItem>();
  const [isDone, setIsDone] = React.useState(false);

  const doBind = async (input: any) => {
    setFetching(true);
    const res = await applyCredit({
      ...input,
      credit_line: input.credit_line * 10000,
    });
    if (res.code === 0) {
      setIsDone(true);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };
  const submit = (input: any) => {
    Modal.confirm({
      title: "提示",
      content: "确认提交当前授信申请吗？请确认您提交的信息无误！",
      okText: "确认提交",
      onOk: () => doBind(input),
      cancelText: "取消",
      onCancel: () => {},
    });
  };
  const fetchCompanyInfo = async () => {
    const res = await getCompanyInfo();
    if (res.code === 0) {
      if (res.data !== null) {
        setCompanyInfo(res.data);
        const requiredInfo = {};
      }
    } else {
    }
  };
  React.useEffect(() => {
    fetchCompanyInfo();
  }, []);
  const onFinish = (values: FormValues) => {
    console.log("Received values of form: ", values);
    if (!companyInfo) return;
    submit({
      ...values,
      resolution: values.resolution[0].response.data,
    });
  };
  const UploadButton = ({ disabled = false }) => (
    <Button icon={<UploadOutlined />} disabled={disabled}>
      点击上传
    </Button>
  );
  const normFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return isDone ? (
    <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
      <Result
        status="success"
        title="授信申请提交成功"
        subTitle="我们将在1-2个工作日内完成授信审核，请耐心等待"
        extra={[
          <Button
            type="primary"
            key="return"
            onClick={() => history.replace("/")}
          >
            返回主页
          </Button>,
          <Button key="detail">查看详情</Button>,
        ]}
      />
    </div>
  ) : (
    <Layout style={{ backgroundColor: "#ffffff", padding: 16 }}>
      <PageHeader title="综合授信申请" />
      <Layout.Content style={{ margin: "0 auto" }}>
        <Form
          {...layout}
          colon
          form={form}
          onFinish={onFinish}
          style={{ width: "40vw", minWidth: 279, maxWidth: 600 }}
        >
          <Form.Item label="申请额度">
            <Form.Item
              name="credit_line"
              noStyle
              rules={[{ required: true, message: "请输入申请额度" }]}
            >
              <InputNumber style={{ marginRight: 10 }} />
            </Form.Item>
            <span>万元</span>
          </Form.Item>
          <Form.Item
            label="申请授信期限"
            name="credit_term"
            rules={[{ required: true, message: "请选择申请期限" }]}
            initialValue={12}
          >
            <Select>
              <Select.Option value={3}>三个月</Select.Option>
              <Select.Option value={6}>六个月</Select.Option>
              <Select.Option value={9}>九个月</Select.Option>
              <Select.Option value={12}>一年</Select.Option>
              <Select.Option value={24}>两年</Select.Option>
              <Select.Option value={36}>三年</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="股东会决议" required>
            <div style={{ display: "flex" }}>
              <Form.Item
                noStyle
                name="resolution"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: "请上传股东会决议" }]}
                hasFeedback
              >
                <Upload
                  action={UPLOAD_ACTION}
                  onChange={({ file, fileList }) => {
                    if (file.status === "success") {
                      console.log(file.response);
                    }
                  }}
                  onRemove={() => {
                    form.setFieldsValue({ resolution: undefined });
                  }}
                >
                  <UploadButton />
                </Upload>
              </Form.Item>
              <a
                href=""
                style={{ margin: "4px 8px" }}
                onClick={(e) => {
                  e.preventDefault();
                  download(
                    "/api/file/protocol/download/3",
                    "有限责任公司股东会决议"
                  );
                }}
              >
                下载模板
              </a>
            </div>
          </Form.Item>
          <Form.Item label="备注" name="comment">
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={fetching}>
              {fetching ? "正在提交" : "提交"}
            </Button>
          </Form.Item>
        </Form>
      </Layout.Content>
      <Divider>说明</Divider>
      <div style={{ margin: "0 48px" }}>
        <h4
          style={{ color: "rgba(0,0,0,.45)", fontSize: 15, lineHeight: "22px" }}
        >
          关于文件上传
        </h4>
        <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
          1.涉及资料请上传加盖公章后的扫描件，无公章的资料不具有法律效应，将会影响您的授信审核结果。
        </p>
        <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
          2.支持文件格式包括：".pdf",".jpg",".jpeg",".png",".bmp"。
        </p>
        <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
          3.单张图片可直接上传，若某一项需上传多张图片，请转为PDF文件后上传。
        </p>
      </div>
    </Layout>
  );
};
export default CreditApply;
