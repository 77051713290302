import * as React from "react";
import {
  Spin,
  Empty,
  Button,
  Descriptions,
  Badge,
  Layout,
  Image,
  Result,
  Divider,
  Table,
  Form,
  Input,
  Upload,
  DatePicker,
  Modal,
  message,
} from "antd";
import {
  getCompanyInfo,
  UPLOAD_ACTION,
  updateTaxInfo,
} from "@/requests/company";
import { useHistory } from "react-router-dom";
import FileViewer from "@/ui-components/FileViewer";

import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";

enum UploadType {
  "纳税记录" = 1,
  "近期财务报表",
  "审计报告",
  "工资发放记录",
}

const TaxInfo = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [companyInfo, setCompanyInfo] = React.useState<
    CompanyInfoItem | undefined
  >(undefined);
  const [orgData, setOrgData] = React.useState<CompanyInfoItem | undefined>(
    undefined
  );
  const [isProcess, setIsProcess] = React.useState(false);
  const [isBind, setIsBind] = React.useState(true);
  const [taxVisible, setTaxVisible] = React.useState(false);
  const [uploadType, setUploadType] = React.useState(1);
  const [edited1, setEdited1] = React.useState(false);
  const [edited2, setEdited2] = React.useState(false);
  const [edited3, setEdited3] = React.useState(false);
  const [edited4, setEdited4] = React.useState(false);

  const handleTaxCancel = () => {
    setTaxVisible(false);
  };

  const doSubmit1 = async () => {
    if (!companyInfo) return;
    const res = await updateTaxInfo({
      id: companyInfo.id,
      tax_record: companyInfo.tax_record,
    });
    if (res.code === 0) {
      message.success("提交成功，请等待后台人员审核");
      setEdited1(false);
    } else {
      message.error(res.message);
    }
  };
  const doSubmit2 = async () => {
    if (!companyInfo) return;
    const res = await updateTaxInfo({
      id: companyInfo.id,
      financial_statement: companyInfo.financial_statement,
    });
    if (res.code === 0) {
      message.success("提交成功，请等待后台人员审核");
      setEdited2(false);
    } else {
      message.error(res.message);
    }
  };
  const doSubmit3 = async () => {
    if (!companyInfo) return;
    const res = await updateTaxInfo({
      id: companyInfo.id,
      audit_report: companyInfo.audit_report,
    });
    if (res.code === 0) {
      message.success("提交成功，请等待后台人员审核");
      setEdited3(false);
    } else {
      message.error(res.message);
    }
  };
  const doSubmit4 = async () => {
    if (!companyInfo) return;
    const res = await updateTaxInfo({
      id: companyInfo.id,
      payroll_record: companyInfo.payroll_record,
    });
    if (res.code === 0) {
      message.success("提交成功，请等待后台人员审核");
      setEdited4(false);
    } else {
      message.error(res.message);
    }
  };

  const handleTaxOk = (values: any) => {
    if (!companyInfo) return;
    console.log(values, moment(values.time[0]).format("YYYY-MM-DD"));
    if (uploadType === 1) {
      let arr = Array.isArray(companyInfo.tax_record)
        ? companyInfo.tax_record.map((x) => x)
        : [];
      arr.push({
        time: `${moment(values.time[0]).format("YYYY-MM-DD")} 至 ${moment(
          values.time[1]
        ).format("YYYY-MM-DD")}`,
        files: values.files.fileList.map((x: any) => x.response.data),
      });
      setCompanyInfo({
        ...companyInfo,
        tax_record: arr,
      });
      setEdited1(true);
      setTaxVisible(false);
    }
    if (uploadType === 2) {
      let arr = Array.isArray(companyInfo.financial_statement)
        ? companyInfo.financial_statement.map((x) => x)
        : [];
      arr.push({
        time: `${moment(values.time[0]).format("YYYY-MM-DD")} 至 ${moment(
          values.time[1]
        ).format("YYYY-MM-DD")}`,
        files: values.files.fileList.map((x: any) => x.response.data),
      });
      setCompanyInfo({
        ...companyInfo,
        financial_statement: arr,
      });
      setEdited2(true);
      setTaxVisible(false);
    }
    if (uploadType === 3) {
      let arr = Array.isArray(companyInfo.audit_report)
        ? companyInfo.audit_report.map((x) => x)
        : [];
      arr.push({
        time: `${moment(values.time[0]).format("YYYY-MM-DD")} 至 ${moment(
          values.time[1]
        ).format("YYYY-MM-DD")}`,
        files: values.files.fileList.map((x: any) => x.response.data),
      });
      setCompanyInfo({
        ...companyInfo,
        audit_report: arr,
      });
      setEdited3(true);
      setTaxVisible(false);
    }
    if (uploadType === 4) {
      let arr = Array.isArray(companyInfo.payroll_record)
        ? companyInfo.payroll_record.map((x) => x)
        : [];
      arr.push({
        time: `${moment(values.time[0]).format("YYYY-MM-DD")} 至 ${moment(
          values.time[1]
        ).format("YYYY-MM-DD")}`,
        files: values.files.fileList.map((x: any) => x.response.data),
      });
      setCompanyInfo({
        ...companyInfo,
        payroll_record: arr,
      });
      setEdited4(true);
      setTaxVisible(false);
    }
  };

  const fetchCompanyInfo = async () => {
    const res = await getCompanyInfo();
    if (res.code === 0) {
      if (res.data !== null) {
        setCompanyInfo(res.data);
        setOrgData(res.data);
      }
    } else if (res.code === 13006) {
      setIsProcess(true);
    } else if (res.code === 13007) {
      setIsBind(false);
    }
    setLoading(false);
  };

  const goBindPage = () => {
    history.push("/base/my-company/bind-apply");
  };

  React.useEffect(() => {
    setLoading(true);
    fetchCompanyInfo();
  }, []);
  const UploadButton = ({ disabled = false }) => (
    <Button icon={<UploadOutlined />} disabled={disabled}>
      点击上传
    </Button>
  );

  const handleSortData = (_data: BaseTaxProfileItem[]) => {
    if (!_data) return [];
    if (_data.length === 0) {
      return [];
    }

    let arr = _data.sort((a, b) => {
      let time1 = new Date(a.time.slice(0, 10) + " " + "00:00:00");
      let time2 = new Date(b.time.slice(0, 10));
      console.log(time1);
      return time1 > time2 ? 1 : -1;
    });
    return arr;
  };

  const colunms_1 = [
    {
      title: "序号",
      width: 80,
      key: "order",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) =>
        `${index + 1}`,
    },
    {
      title: "时间",
      key: "time",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        return record.time;
      },
    },
    {
      title: "文件",
      key: "files",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        return record.files.map((x) => <FileViewer file={x} key={x} />);
      },
    },

    {
      title: "操作",
      key: "actions",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        return (
          <span>
            <a
              key="delete"
              onClick={(e) => {
                e.preventDefault();
                Modal.confirm({
                  title: "提示",
                  content: "确认删除该文件吗？",
                  okText: "确认",
                  onOk: async () => {
                    if (!companyInfo) return;
                    let arr = companyInfo.tax_record.filter(
                      (x, _index) => _index !== index
                    );
                    setCompanyInfo({
                      ...companyInfo,
                      tax_record: arr,
                    });
                    setEdited1(true);
                  },
                  cancelText: "取消",
                  onCancel: () => {},
                });
              }}
            >
              删除
            </a>
          </span>
        );
      },
    },
  ];

  const colunms_2 = [
    {
      title: "序号",
      width: 80,
      key: "order",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) =>
        `${index + 1}`,
    },
    {
      title: "时间",
      key: "time",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        return record.time;
      },
    },
    {
      title: "文件",
      key: "files",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        return record.files.map((x) => <FileViewer file={x} key={x} />);
      },
    },

    {
      title: "操作",
      key: "actions",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        return (
          <span>
            <a
              key="delete"
              onClick={(e) => {
                e.preventDefault();
                Modal.confirm({
                  title: "提示",
                  content: "确认删除该文件吗？",
                  okText: "确认",
                  onOk: async () => {
                    if (!companyInfo) return;
                    let arr = companyInfo.financial_statement.filter(
                      (x, _index) => _index !== index
                    );
                    setCompanyInfo({
                      ...companyInfo,
                      financial_statement: arr,
                    });
                    setEdited2(true);
                  },
                  cancelText: "取消",
                  onCancel: () => {},
                });
              }}
            >
              删除
            </a>
          </span>
        );
      },
    },
  ];
  const colunms_3 = [
    {
      title: "序号",
      width: 80,
      key: "order",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) =>
        `${index + 1}`,
    },
    {
      title: "时间",
      key: "time",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        return record.time;
      },
    },
    {
      title: "文件",
      key: "files",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        return record.files.map((x) => <FileViewer file={x} key={x} />);
      },
    },

    {
      title: "操作",
      key: "actions",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        return (
          <span>
            <a
              key="delete"
              onClick={(e) => {
                e.preventDefault();
                Modal.confirm({
                  title: "提示",
                  content: "确认删除该文件吗？",
                  okText: "确认",
                  onOk: async () => {
                    if (!companyInfo) return;
                    let arr = companyInfo.audit_report.filter(
                      (x, _index) => _index !== index
                    );
                    setCompanyInfo({
                      ...companyInfo,
                      audit_report: arr,
                    });
                    setEdited3(true);
                  },
                  cancelText: "取消",
                  onCancel: () => {},
                });
              }}
            >
              删除
            </a>
          </span>
        );
      },
    },
  ];

  const colunms_4 = [
    {
      title: "序号",
      width: 80,
      key: "order",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) =>
        `${index + 1}`,
    },
    {
      title: "时间",
      key: "time",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        return record.time;
      },
    },
    {
      title: "文件",
      key: "files",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        return record.files.map((x) => <FileViewer file={x} key={x} />);
      },
    },

    {
      title: "操作",
      key: "actions",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        return (
          <span>
            <a
              key="delete"
              onClick={(e) => {
                e.preventDefault();
                Modal.confirm({
                  title: "提示",
                  content: "确认删除该文件吗？",
                  okText: "确认",
                  onOk: async () => {
                    if (!companyInfo) return;
                    let arr = companyInfo.payroll_record.filter(
                      (x, _index) => _index !== index
                    );
                    setCompanyInfo({
                      ...companyInfo,
                      payroll_record: arr,
                    });
                    setEdited4(true);
                  },
                  cancelText: "取消",
                  onCancel: () => {},
                });
              }}
            >
              删除
            </a>
          </span>
        );
      },
    },
  ];
  return (
    <Layout>
      <Layout.Content>
        <Spin
          tip="加载中"
          spinning={loading}
          style={{ position: "absolute", top: "50%", left: "50%" }}
        ></Spin>

        {isProcess && (
          <div style={{ backgroundColor: "#ffffff" }}>
            <Result
              status="info"
              title="审核中"
              subTitle="您的企业信息资料正在审核中"
              extra={[
                <Button
                  key="view"
                  type="primary"
                  onClick={() =>
                    history.push({
                      pathname: "/base/my-process/company-info-process",
                    })
                  }
                >
                  点击查看
                </Button>,
              ]}
            />
          </div>
        )}
        {companyInfo && (
          <div
            style={{
              backgroundColor: "#ffffff",
              marginBottom: 16,
              padding: 16,
            }}
          >
            <Divider>纳税记录</Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 16,
              }}
            >
              {edited1 && (
                <Button
                  type="primary"
                  onClick={() => {
                    doSubmit1();
                  }}
                  style={{ marginRight: 16 }}
                >
                  保存更改
                </Button>
              )}
              <Button
                onClick={() => {
                  setUploadType(1);
                  setTaxVisible(true);
                }}
              >
                添加纳税记录
              </Button>
            </div>
            <Table
              dataSource={handleSortData(companyInfo.tax_record)}
              bordered
              columns={colunms_1}
            />
            <Divider>近期财务报表</Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 16,
              }}
            >
              {edited2 && (
                <Button
                  type="primary"
                  onClick={() => {
                    doSubmit2();
                  }}
                  style={{ marginRight: 16 }}
                >
                  保存更改
                </Button>
              )}
              <Button
                onClick={() => {
                  setUploadType(2);
                  setTaxVisible(true);
                }}
              >
                添加财务报表
              </Button>
            </div>
            <Table
              dataSource={handleSortData(companyInfo.financial_statement)}
              bordered
              columns={colunms_2}
            />
            <Divider>审计报告</Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 16,
              }}
            >
              {edited3 && (
                <Button
                  type="primary"
                  onClick={() => {
                    doSubmit3();
                  }}
                  style={{ marginRight: 16 }}
                >
                  保存更改
                </Button>
              )}
              <Button
                onClick={() => {
                  setUploadType(3);
                  setTaxVisible(true);
                }}
              >
                添加审计报告
              </Button>
            </div>
            <Table
              dataSource={handleSortData(companyInfo.audit_report)}
              bordered
              columns={colunms_3}
            />
            <Divider>工资发放记录</Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 16,
              }}
            >
              {edited4 && (
                <Button
                  type="primary"
                  onClick={() => {
                    doSubmit4();
                  }}
                  style={{ marginRight: 16 }}
                >
                  保存更改
                </Button>
              )}
              <Button
                onClick={() => {
                  setUploadType(4);
                  setTaxVisible(true);
                }}
              >
                添加工资发放记录
              </Button>
            </div>
            <Table
              dataSource={handleSortData(companyInfo.payroll_record)}
              bordered
              columns={colunms_4}
            />
            <Modal
              title={
                <span>
                  <div className="title-prefix"></div>
                  {`添加${UploadType[uploadType]}`}
                </span>
              }
              visible={taxVisible}
              onCancel={handleTaxCancel}
              footer={null}
              destroyOnClose
            >
              <Form onFinish={handleTaxOk} preserve={false}>
                <Form.Item
                  label="时间"
                  name="time"
                  rules={[{ required: true, message: "请选择文件有效时间" }]}
                >
                  <DatePicker.RangePicker />
                </Form.Item>
                <Form.Item
                  label="文件"
                  name="files"
                  rules={[{ required: true, message: "请上传文件" }]}
                  extra="请上传原件照片、扫描件、或复印件加盖公章，支持格式包括.jpg/.png/.pdf，文件大小不超过10M，支持多文件上传"
                >
                  <Upload
                    action={UPLOAD_ACTION}
                    onChange={({ file, fileList }) => {
                      if (file.status === "success") {
                        console.log(file.response);
                      }
                    }}
                  >
                    <UploadButton />
                  </Upload>
                </Form.Item>
                <Form.Item noStyle>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      margin: "36px 0 24px 0",
                      padding: "0 100px",
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType={"submit"}
                      style={{ padding: "0 36px" }}
                    >
                      确认
                    </Button>
                    <Button
                      onClick={handleTaxCancel}
                      style={{ padding: "0 36px" }}
                    >
                      取消
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Modal>
          </div>
        )}
        {!isBind && (
          <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
            <Empty
              description={
                <span>您还未绑定企业信息，请先绑定您的企业信息</span>
              }
            >
              <Button type="primary" onClick={goBindPage}>
                立即绑定
              </Button>
            </Empty>
          </div>
        )}
      </Layout.Content>
    </Layout>
  );
};
export default TaxInfo;
