import "./App.less";
import React, { FC } from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Login from "@/ui-auth/Login";
import Register from "@/ui-auth/Register";
import Home from "@/pages/Home";
import { connect } from "@/store/App.store";
import NoMatched from "@/pages/404";
import UserInfoCenter from "@/ui-auth/UserInfoCenter";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import ResetPasswordPage from "@/ui-auth/ResetPassword";

const App: FC = () => {
  const [locale] = React.useState(zhCN);
  return (
    <ConfigProvider locale={locale}>
      <Router basename="/">
        <Switch>
          <Route path="/" exact>
            <Redirect to="/base" />
          </Route>
          <Route path="/base">
            <Home />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/userinfo-center" component={UserInfoCenter}></Route>
          <Route path="/reset-password" component={ResetPasswordPage}></Route>
          <Route path="*">
            <NoMatched />
          </Route>
        </Switch>
      </Router>
    </ConfigProvider>
  );
};

export default connect(App);
