/// <reference path="type.d.ts" />
/// <reference path="contract.d.ts" />

import request from "./_base";

/**
 * 获取当前正在申请的合同
 */
export async function getApplyingContracts(
  input: any
): Promise<ResponseProto<{ data: ApplyingCreditContractItem[] }>> {
  return request({
    method: "POST",
    url: `/contract-apply-approval/list`,
    data: input,
  }) as any;
}

/**
 * 申请综合保理合同
 */
export async function applyCreditContract(
  input: ApplyCreditContractReq
): Promise<ResponseProto<{ data: ApplyingCreditContractItem }>> {
  return request({
    method: "POST",
    url: `/contract-apply-approval/submit_apply_approval`,
    data: input,
  }) as any;
}

// /**
//  * 查看合同列表
//  */
// export async function getContractList(
//   input: GetContractListReq
// ): Promise<ResponseProto<{ data: CreditContractListItem[]; total: number }>> {
//   return request({
//     method: "POST",
//     url: `/contract/list`,
//     data: input,
//   }) as any;
// }

/**
 * 获取签署合同跳转链接
 */
export async function getSignLink(
  contract_id: CreditContractListItem["id"]
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "GET",
    url: `/bestSign/users/sso-link/${contract_id}`,
  }) as any;
}

/**
 * 下载合同
 */
export function downloadContract(
  contract_id: CreditContractListItem["id"]
): string {
  return `/api/bestSign/contracts/download-file/${contract_id}`;
}

////通用合同接口
/**
 * 合同预览
 * @param  {ContractPreviewReq} input
 */
export async function contractPreview(
  input: ContractPreviewReq
): Promise<ResponseProto<{ data: ContractPreviewRes }>> {
  return request({
    method: "POST",
    url: `/bestSign/sso-link`,
    data: input,
  }) as any;
}

/**
 * 获取合同列表
 * @param  {GetContractListReq} input
 */
export async function getContractList(
  input: GetContractListReq
): Promise<ResponseProto<{ data: ContractListItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/contract-common/list`,
    data: input,
  }) as any;
}
