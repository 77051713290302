/// <reference path="type.d.ts" />
/// <reference path="business.d.ts" />

import request from "./_base";
import formRequest from "./formReq";

export const UPLOAD_ACTION = `/api/file/upload`;
export const DOWNLOAD_ACTION = `/api/file/download/`;

/**
 * 获取产品列表 JSON版本
 *  @param  {GetProductListReq} input
 */
export async function getProductList(
  input: GetProductListReq
): Promise<ResponseProto<{ data: ProductItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/product/list`,
    data: input,
  }) as any;
}

/**
 * 获取产品列表 formdata版
 *  @param  {any} input
 */
export async function getProductListByForm(
  input: any
): Promise<ResponseProto<{ data: ProductItem[]; total: number }>> {
  const formData = new FormData();
  for (let key in input) {
    formData.append(key, input[key]);
  }
  return formRequest({
    method: "POST",
    url: `/product/list`,
    data: formData,
  }) as any;
}

/**
 *  用户提交业务申请
 *  @param  {ApplyProductReq} input
 */
export async function applyProduct(
  input: ApplyProductReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/product-apply/submit`,
    data: input,
  }) as any;
}

/**
 *  获取用户提交的业务申请记录
 *  @param  {BaseListReq} input
 */
export async function getBusinessApplyRecord(
  input: BaseListReq
): Promise<ResponseProto<{ data: BusinessApplyRecordItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/product-apply/list`,
    data: input,
  }) as any;
}

/**
 *  获取用户提交的业务申请记录详情
 *  @param  {number} id
 */
export async function getBusinessApplyRecordDetail(
  id: number
): Promise<ResponseProto<{ data: BusinessApplyRecordItem; total: number }>> {
  return request({
    method: "GET",
    url: `/product-apply/info/${id}`,
  }) as any;
}

/**
 *  用户提交还款申请
 *  @param  {RepaymentApplyReq} input
 */
export async function repaymentApply(
  input: RepaymentApplyReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/repayment-apply/submit`,
    data: input,
  }) as any;
}

/**
 *  获取用户提交的还款申请列表
 *  @param  {GetRepaymentApplyListReq} input
 */
export async function getRepaymentApplyList(
  input: GetRepaymentApplyListReq
): Promise<ResponseProto<{ data: RepaymentApplyListItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/repayment-apply/list`,
    data: input,
  }) as any;
}

/**
 *  根据还款申请id查询还款申请详情
 *  @param  {RepaymentApplyListItem['repayment_id']} id
 */
export async function getRepaymentApplyDetail(
  id: RepaymentApplyListItem["repayment_id"]
): Promise<ResponseProto<{ data: RepaymentApplyListDetail }>> {
  return request({
    method: "POST",
    url: `/repayment-apply/detail/${id}`,
  }) as any;
}
