/// <reference path="type.d.ts" />
/// <reference path="bestSign.d.ts" />

import request from "./_base";

/**
 * 获取用户电子平台绑定信息
 */
export async function getESignBindInfo(): Promise<
  ResponseProto<{ data: BindRes }>
> {
  return request({
    method: "GET",
    url: `/bestSign/binding/sign`,
  }) as any;
}

/**
 * 绑定电子平台账号
 */
export async function bindESign(
  input: BindBestSignAccountReq
): Promise<ResponseProto<{ data: BindAccountRes }>> {
  return request({
    method: "POST",
    url: `/bestSign/binding/user`,
    data: input,
  }) as any;
}

/**
 * 代传认证信息
 */
export async function submitInfo(
  input: any
): Promise<ResponseProto<{ data: SubmitInfoRes }>> {
  return request({
    method: "POST",
    url: `/bestSign/ents/auth/information`,
    data: input,
  }) as any;
}

/**
 * 查询企业实名状态
 */
export async function getAuthInfo(): Promise<
  ResponseProto<{ data: AuthInfoRes }>
> {
  return request({
    method: "GET",
    url: `/bestSign/ent/auth-info`,
  }) as any;
}
