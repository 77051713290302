import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

const Footer = (props: RouteComponentProps) => {
  return (
    <div>
      {" "}
      Copyright © 2020-2021{" "}
      <a href="http://www.lbaoli.com/" target="_blank">
        深圳前海兰青商业保理有限公司
      </a>{" "}
      版权所有
    </div>
  );
};

export default withRouter(Footer);
