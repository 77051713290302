import * as React from "react";
import { getProductList } from "@/requests/business";
import { Form, Button, List, Card, Input, Tag, Tooltip, message } from "antd";
import { RedoOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { useCtxState } from "@/store/App.store";
import "../styles/index.less";

import { useHistory } from "react-router";

const ServiceTag = ({ type }: { type: number }) => {
  switch (type) {
    case 1:
      return <Tag color={"cyan"}>管理</Tag>;
    case 2:
      return <Tag color={"volcano"}>融资</Tag>;
    case 3:
      return <Tag color={"magenta"}>催收</Tag>;
    case 4:
      return <Tag color={"geekblue"}>担保</Tag>;
    default:
      return null;
  }
};

const ProductList = () => {
  const [data, setData] = React.useState<ProductItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPaination] = React.useState({
    page: 1,
    pageSize: 30,
    total: 0,
  });
  const [{ userInfo }] = useCtxState();

  const history = useHistory();

  const fetchData = async (page?: number, pageSize?: number) => {
    setLoading(true);
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    const res = await getProductList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });
    if (res.code === 0) {
      const _data = res.data.reverse().filter((x) => x.public_sign === 1);
      setData(_data);
      setPaination({
        page,
        pageSize,
        total: res.total - (res.data.length - _data.length),
      });
    } else {
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="product-list-page">
      <div className="tools-wrapper">
        <div className="product-list-filter-input-container">
          <Form>
            <Form.Item label="产品名称" className="filter-item">
              <Input.Search></Input.Search>
            </Form.Item>
          </Form>
        </div>
        <div style={{ display: "flex" }}>
          <Button
            onClick={() => {
              fetchData();
            }}
            icon={<RedoOutlined />}
            style={{ marginRight: 16 }}
          >
            刷新
          </Button>
          <Button
            type="primary"
            onClick={() => {
              history.push("/base/my-process/business-process");
            }}
            icon={<UnorderedListOutlined />}
            style={{ marginRight: 16 }}
          >
            我的办理
          </Button>
        </div>
      </div>
      <div style={{ backgroundColor: "#FFFFFF", padding: 16 }}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 4,
          }}
          dataSource={data}
          loading={loading}
          renderItem={(item) => {
            if (item === null) return null;
            return (
              <List.Item key={item.id}>
                <Card
                  title={item.product_name}
                  actions={[
                    <Button
                      type="primary"
                      style={{ width: 150 }}
                      onClick={() => {
                        const { credit_status } = userInfo;
                        if (credit_status === 1) {
                          history.push({
                            pathname: `/base/business/apply`,
                            search: `product_id=${item.id}`,
                            state: {
                              product: item,
                            },
                          });
                        } else {
                          message.error(
                            "您尚未完成授信，请先申请综合授信，再办理业务"
                          );
                        }
                      }}
                    >
                      立即申请
                    </Button>,
                  ]}
                  extra={[
                    <Tooltip title="查看详情">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          history.push({
                            pathname: `/base/business/product-list/detail`,
                            search: `id=${item.id}`,
                            state: {
                              data: item,
                            },
                          });
                        }}
                      >
                        详情
                      </a>
                    </Tooltip>,
                  ]}
                >
                  <Form labelCol={{ md: 5, lg: 5 }} labelAlign={"left"}>
                    <Form.Item label="产品描述" style={{ marginBottom: 0 }}>
                      <div
                        style={{
                          height: 22,
                          overflow: "hidden",
                          maxWidth: 251,
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.product_describe}
                      </div>
                    </Form.Item>
                    <Form.Item label="服务类型" style={{ marginBottom: 0 }}>
                      {item.service_type.split(",").map((x) => (
                        <ServiceTag type={Number(x)} />
                      ))}
                    </Form.Item>
                    <Form.Item label="产品属性" style={{ marginBottom: 0 }}>
                      {/* {item.open_type === 1 && (
                        <Tag color={"gold"}>公开型保理</Tag>
                      )}
                      {item.open_type === 0 && (
                        <Tag color={"gold"}>隐蔽型保理</Tag>
                      )}

                      {item.right_of_recourse === 1 && (
                        <Tag color={"orange"}>有追索权</Tag>
                      )}
                      {item.right_of_recourse === 0 && (
                        <Tag color={"orange"}>无追索权</Tag>
                      )} */}
                      {item.pledge_need === 1 && (
                        <Tag color={"lime"}>需要质押</Tag>
                      )}
                      {item.pledge_need === 0 && (
                        <Tag color={"lime"}>无需质押</Tag>
                      )}
                      {item.pledge_need === 2 && (
                        <Tag color={"lime"}>无需质押</Tag>
                      )}
                      {item.mortgage_need === 1 && (
                        <Tag color={"green"}>需要抵押</Tag>
                      )}
                      {item.mortgage_need === 0 && (
                        <Tag color={"green"}>无需抵押</Tag>
                      )}
                      {item.mortgage_need === 2 && (
                        <Tag color={"green"}>无需抵押</Tag>
                      )}
                      {item.guarantee_contract_sign === 1 && (
                        <Tag color={"purple"}>需要保证</Tag>
                      )}
                      {item.guarantee_contract_sign === 0 && (
                        <Tag color={"purple"}>无需保证</Tag>
                      )}
                      {item.guarantee_contract_sign === 2 && (
                        <Tag color={"purple"}>无需保证</Tag>
                      )}
                    </Form.Item>
                  </Form>
                </Card>
              </List.Item>
            );
          }}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
            style: { marginRight: 16 },
            showTotal: (total) => <span>共{total}条数据</span>,
          }}
        />
      </div>
    </div>
  );
};

export default ProductList;
