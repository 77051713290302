import * as React from "react";
import { message, Form, DatePicker, Button, List, Tag, Select } from "antd";
import { getAllBillList } from "@/requests/billing";
import { useHistory, useLocation } from "react-router";
import { SearchOutlined, RedoOutlined } from "@ant-design/icons";
import { toThousands } from "@/utils/string";
import "../styles/index.less";

enum Status {
  "放款中" = 0,
  "放款中 " = 1,
  "已放款" = 2,
}

const StatusTag = ({ status }: { status: number }) => {
  if (status === 0) {
    return <Tag color={"red"}>{Status[status]}</Tag>;
  }
  if (status === 1) {
    return <Tag color={"volcano"}> {Status[status]}</Tag>;
  }
  if (status === 2) {
    return <Tag color={"green"}>{Status[status]}</Tag>;
  }
  return null;
};

const AllBillList = () => {
  const [data, setData] = React.useState<BillingListItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [renderData, setRenderData] = React.useState<BillingListItem[]>([]);
  const [filterType, setFilterType] = React.useState(0);

  const location = useLocation();
  const history = useHistory();

  const fetchData = async (page?: number, pageSize?: number) => {
    setLoading(true);
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    if (!page) {
      page = pagination.page;
    }

    const res = await getAllBillList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });

    if (res.code === 0) {
      setData(res.data.records);
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (location.pathname === "/base/my-process/billing-process") {
      fetchData();
    }
  }, [location]);

  React.useEffect(() => {
    if (filterType === 0) {
      setRenderData(data.filter((x) => x));
    }
    if (filterType === 1) {
      setRenderData(data.filter((x) => x.status === 0 || x.status === 1));
    }
    if (filterType === 2) {
      setRenderData(data.filter((x) => x.status === 2));
    }
  }, [filterType, data]);

  return (
    <div className="billing-list-page">
      <div className="tools-wrapper">
        <Form colon={false} layout="inline">
          <Form.Item label="状态" className="filter-item">
            <Select
              value={filterType}
              onChange={(value) => {
                setFilterType(value);
              }}
            >
              <Select.Option value={0}>全部</Select.Option>
              <Select.Option value={1}>放款中</Select.Option>
              <Select.Option value={2}>放款完成</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="申请时间" className="filter-item">
            <DatePicker.RangePicker style={{ minWidth: 200 }} showTime />
          </Form.Item>
        </Form>

        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            style={{ marginRight: 10 }}
            onClick={() => fetchData()}
          >
            搜索
          </Button>
          <Button icon={<RedoOutlined />}>重置</Button>
        </div>
      </div>
      <div style={{ backgroundColor: "#ffffff", padding: 16, marginTop: 16 }}>
        <List
          itemLayout="horizontal"
          dataSource={renderData}
          loading={loading}
          renderItem={(item) => {
            return (
              <List.Item
                actions={[
                  <StatusTag status={item.status} />,
                  <a
                    key="view"
                    onClick={() => {
                      history.push({
                        pathname: `/base/my-process/billing-detail`,
                        state: {
                          record: item,
                        },
                        search: `id=${item.id}`,
                      });
                    }}
                  >
                    查看详情
                  </a>,
                ]}
              >
                <List.Item.Meta
                  title={
                    <span style={{ color: "inherit" }}>
                      金额：
                      <span>{toThousands(item.amount)} 元</span>
                    </span>
                  }
                  description={
                    <div style={{ display: "flex" }}>
                      <span>{`申请时间：${item.create_time}`}</span>
                    </div>
                  }
                ></List.Item.Meta>
              </List.Item>
            );
          }}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
          }}
        ></List>
      </div>
    </div>
  );
};

export default AllBillList;
