import * as React from "react";
import { Empty, Button, Result, Table, Divider, Modal, message } from "antd";
import { getCompanyInfo, updateShareholderInfo } from "@/requests/company";
import { useHistory } from "react-router-dom";
import AddShareholderModal from "./components/AddShareholderModal";
import EditShareholderModal from "./components/EditShareholderModal";

const ShareholderInfo = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [companyInfo, setCompanyInfo] = React.useState<
    CompanyInfoItem | undefined
  >(undefined);
  const [isProcess, setIsProcess] = React.useState(false);
  const [isBind, setIsBind] = React.useState(true);
  const [addVisible, setAddVisible] = React.useState(false);
  const [editVisible, setEditVisible] = React.useState(false);
  const [edited, setEdited] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState(-1);

  const fetchCompanyInfo = async () => {
    const res = await getCompanyInfo();
    if (res.code === 0) {
      if (res.data !== null) {
        setCompanyInfo(res.data);
      }
    } else if (res.code === 13006) {
      setIsProcess(true);
    } else if (res.code === 13007) {
      setIsBind(false);
    }
    setLoading(false);
  };

  const goBindPage = () => {
    history.push("/base/my-company/bind-apply");
  };
  const doSubmit = async (input: UpdateShareHolderInfoReq) => {
    if (!companyInfo) return;
    const ratio = companyInfo.shareholder_message.reduce(
      (pre, res) => pre + Number(res.shareholding_ratio),
      0
    );
    if (ratio !== 100) {
      message.error("各股东持股比例不正确，请正确输入各股东持股比例");
      return;
    }
    setLoading(true);

    const res = await updateShareholderInfo(input);
    if (res.code === 0) {
      message.success("提交成功，请等待后台人员审核，审核时间为1-3个工作日");
      setEdited(false);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);
    fetchCompanyInfo();
  }, []);

  const colunms = [
    {
      title: "序号",
      width: 80,
      key: "order",
      align: "center" as "left",
      render: (text: any, record: ShareholderItem, index: number) =>
        `${index + 1}`,
    },
    {
      title: "名称",
      key: "name",
      align: "center" as "left",
      render: (text: any, record: ShareholderItem, index: number) => {
        return record.type === 0 ? record.name : record.company_name;
      },
    },
    {
      title: "类型",
      key: "type",
      align: "center" as "left",
      render: (text: any, record: ShareholderItem, index: number) => {
        return record.type === 0 ? "个人" : "企业";
      },
    },

    {
      title: "持股比例",
      key: "ratio",
      align: "center" as "left",
      render: (text: any, record: ShareholderItem, index: number) =>
        `${record.shareholding_ratio}%`,
    },
    {
      title: "操作",
      key: "actions",
      align: "center" as "left",
      render: (text: any, record: ShareholderItem, index: number) => {
        return (
          <span>
            <a
              key="edit"
              onClick={(e) => {
                e.preventDefault();
                setEditIndex(index);
                setEditVisible(true);
              }}
            >
              编辑
            </a>

            <Divider type="vertical" />
            <a
              key="view"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: "/base/my-company/shareholder-info/detail",
                  state: {
                    item: record,
                  },
                });
              }}
            >
              详情
            </a>

            <Divider type="vertical" />

            <a
              key="delete"
              onClick={(e) => {
                e.preventDefault();
                Modal.confirm({
                  title: "提示",
                  content: "确认删除该股东吗？",
                  okText: "确认",
                  onOk: async () => {
                    if (!companyInfo) return;
                    let arr = companyInfo.shareholder_message.filter(
                      (x, _index) => _index !== index
                    );
                    setCompanyInfo({
                      ...companyInfo,
                      shareholder_message: arr,
                    });
                    setEdited(true);
                  },
                  cancelText: "取消",
                  onCancel: () => {},
                });
              }}
            >
              删除
            </a>
          </span>
        );
      },
    },
  ];
  return (
    <div className="shareholder-info-page">
      {companyInfo && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 16,
          }}
        >
          <Button onClick={() => setAddVisible(true)}>添加股东</Button>
          {edited && (
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => {
                if (!companyInfo) return;
                doSubmit({
                  id: companyInfo.id,
                  shareholder_message: companyInfo.shareholder_message,
                });
              }}
            >
              保存变更
            </Button>
          )}
        </div>
      )}
      <div style={{ backgroundColor: "#ffffff" }}>
        {isProcess && (
          <div style={{ backgroundColor: "#ffffff" }}>
            <Result
              status="info"
              title="审核中"
              subTitle="您的企业信息资料正在审核中"
              extra={[
                <Button
                  key="view"
                  type="primary"
                  onClick={() =>
                    history.push({
                      pathname: "/base/my-process/company-info-process",
                    })
                  }
                >
                  点击查看
                </Button>,
              ]}
            />
          </div>
        )}
        {!isBind && (
          <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
            <Empty
              description={
                <span>您还未绑定企业信息，请先绑定您的企业信息</span>
              }
            >
              <Button type="primary" onClick={goBindPage}>
                立即绑定
              </Button>
            </Empty>
          </div>
        )}
        {companyInfo && (
          <Table
            dataSource={companyInfo.shareholder_message as any}
            columns={colunms}
            loading={loading}
            bordered
            style={{
              padding: 16,
            }}
          />
        )}
      </div>
      {companyInfo && (
        <AddShareholderModal
          visible={addVisible}
          setVisible={setAddVisible}
          companyInfo={companyInfo}
          setCompanyInfo={setCompanyInfo}
          setEdited={setEdited}
        />
      )}
      {companyInfo && editIndex > -1 && (
        <EditShareholderModal
          visible={editVisible}
          setVisible={setEditVisible}
          companyInfo={companyInfo}
          setCompanyInfo={setCompanyInfo}
          setEdited={setEdited}
          index={editIndex}
          setIndex={setEditIndex}
        />
      )}
    </div>
  );
};
export default ShareholderInfo;
