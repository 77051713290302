/// <reference path="type.d.ts" />
/// <reference path="billing.d.ts" />

import request from "./_base";

/**
 * 全部放款记录列表
 * @param  {GetBillingListReq} input
 */
export async function getAllBillList(
  input: GetBillingListReq
): Promise<ResponseProto<{ data: BillingList; total: number }>> {
  return request({
    method: "POST",
    url: `/billing-loan/list`,
    data: input,
  }) as any;
}
