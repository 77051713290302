import * as React from "react";
import { Empty, Button, message, Result, Form, Tag, Modal } from "antd";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { getMyCredit } from "@/requests/credit";
import { VerifiedOutlined } from "@ant-design/icons";
import { useCtxState } from "@/store/App.store";
import { toThousands } from "@/utils/string";
import ContractPreviewer from "@/ui-components/ContractPreviewer";
import { CheckCircleTwoTone } from "@ant-design/icons";

const MyCredit = (props: RouteComponentProps) => {
  const [data, setData] = React.useState<CreditInfoItem | undefined>(undefined);
  const [{ userInfo }] = useCtxState();
  const [isCredited, setIsCredited] = React.useState(true);
  const [isSign, setIsSign] = React.useState(true);
  const history = useHistory();
  const location = useLocation();

  const fetchData = async () => {
    const res = await getMyCredit();
    if (res.code === 0) {
      if (res.data) {
        setData(res.data);
        if (res.data.contract_sign === 0) {
          setIsSign(false);
        }
      } else {
      }
    } else {
    }
  };

  React.useEffect(() => {
    const { credit_status } = userInfo;
    if (credit_status === 0) {
      setIsCredited(false);
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    if (location.pathname === "/base/contract/applying-contracts") return;
    if (!isSign)
      Modal.confirm({
        title: "提示",
        content:
          "根据《e保理注册协议》以及国家法律规定，您必须签署《综合授信合同》后授信信息方能生效，请在“合同管理-我的申请”中尽快完成综合授信合同申请并签署",
        okText: "立即前往",
        cancelText: "以后再说",
        onCancel: () => {},
        cancelButtonProps: { style: { display: "none" } },
        onOk: () => {
          history.push("/base/contract/applying-contracts");
        },
      });
  }, [isSign, location]);
  return (
    <div style={{ height: "100%", backgroundColor: "#ffffff", padding: 16 }}>
      {data && (
        <div>
          <Result
            icon={<VerifiedOutlined />}
            title={"您已通过授信"}
            extra={[
              <Form style={{ width: 300, margin: "0 auto" }}>
                <Form.Item label="授信类型" style={{ marginBottom: 10 }}>
                  <>
                    {data.is_reverse_factoring === true && (
                      <Tag color="cyan">反向保理</Tag>
                    )}
                    {data.is_reverse_factoring === false && (
                      <Tag color="cyan">正向保理</Tag>
                    )}
                    {data.is_undisclosed_factoring === true && (
                      <Tag color="magenta">暗保理</Tag>
                    )}
                    {data.is_undisclosed_factoring === false && (
                      <Tag color="magenta">明保理</Tag>
                    )}
                    {data.is_non_recourse_factoring === true && (
                      <Tag color="volcano">无追索</Tag>
                    )}
                    {data.is_non_recourse_factoring === false && (
                      <Tag color="volcano">有追索</Tag>
                    )}
                  </>
                </Form.Item>
                <Form.Item label="授信额度" style={{ marginBottom: 10 }}>
                  {toThousands(data.submit_credit_line)} 元
                </Form.Item>
                <Form.Item label="授信日期" style={{ marginBottom: 10 }}>
                  {data.approval_time}
                </Form.Item>
                <Form.Item label="授信期限" style={{ marginBottom: 10 }}>
                  {data.credit_term} 个月
                </Form.Item>
                <Form.Item label="授信到期日" style={{ marginBottom: 10 }}>
                  {data.invalid_time}
                </Form.Item>
                <Form.Item label="综合授信合同" style={{ marginBottom: 10 }}>
                  <span style={{ paddingRight: 8 }}>
                    {isSign ? (
                      <>
                        <CheckCircleTwoTone /> 已签署
                      </>
                    ) : (
                      "未签署"
                    )}
                  </span>
                  {isSign && data.contract_resps && (
                    <ContractPreviewer
                      contract_id={data.contract_resps[0].contract_id}
                    />
                  )}
                </Form.Item>
              </Form>,
            ]}
          />
        </div>
      )}
      {!isCredited && (
        <Empty description={<span>您还未申请授信</span>}>
          <Button
            type="primary"
            onClick={() => {
              const { info_complete_sign } = userInfo;
              if (info_complete_sign === 1) {
                history.push("/base/my-credit/credit-apply");
              } else {
                message.error(
                  "您的企业信息不完整，无法申请授信，请先完善您的企业资料"
                );
              }
            }}
          >
            立即申请
          </Button>
        </Empty>
      )}
    </div>
  );
};

export default MyCredit;
