import * as React from "react";
import {
  getCompanyInfo,
  UPLOAD_ACTION,
  updateLegalPersonInfo,
} from "@/requests/company";
import {
  Form,
  Button,
  Input,
  Upload,
  message,
  Result,
  Modal,
  Image,
  Divider,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { UploadPicWidget } from "@/ui-components";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const SubmitLegalPersonInfo = () => {
  const [companyInfo, setCompanyInfo] = React.useState<
    CompanyInfoItem | undefined
  >(undefined);
  const [fetching, setFeching] = React.useState(false);
  const [form] = Form.useForm();
  const [isDone, setIsDone] = React.useState(false);
  const history = useHistory();
  const [personAVisible, setPersonAVisible] = React.useState(false);
  const [personBVisible, setPersonBVisible] = React.useState(false);
  const fetchCompanyInfo = async () => {
    const res = await getCompanyInfo();
    if (res.code === 0) {
      setCompanyInfo(res.data);
    }
  };

  const doSubmit = async (input: UpdateLegalPersonInfoReq) => {
    setFeching(true);
    const res = await updateLegalPersonInfo(input);
    if (res.code === 0) {
      message.success("提交成功");
      setIsDone(true);
    } else {
      message.error(res.message);
    }
  };

  const handleFinish = (values: any) => {
    if (!companyInfo) return;
    doSubmit({
      id: companyInfo.id,
      legal_person: values.legal_person,
      legal_person_phone: values.legal_person_phone,
      legal_person_address: values.legal_person_address,
      legal_person_email: values.legal_person_email,
      legal_person_id_card_num: values.legal_person_id_card_num,
      legal_person_id_card_pic: companyInfo.legal_person_id_card_pic.map(
        (x, index) => {
          if (index === 0) {
            return values.legal_person_id_card_pic_1
              ? {
                  file_name:
                    values.legal_person_id_card_pic_1[
                      values.legal_person_id_card_pic_1.length - 1
                    ].response.data,
                  type: 1,
                }
              : x;
          }
          if (index === 1) {
            return values.legal_person_id_card_pic_2
              ? {
                  file_name:
                    values.legal_person_id_card_pic_2[
                      values.legal_person_id_card_pic_1.length - 1
                    ].response.data,
                  type: 0,
                }
              : x;
          }
          return x;
        }
      ),
      legal_person_position: values.legal_person_position,
      legal_credit: values.legal_credit
        ? values.legal_credit.fileList[0].response.data
        : companyInfo.legal_credit,
    });
  };

  React.useEffect(() => {
    fetchCompanyInfo();
  }, []);
  const UploadButton = ({ disabled = false }) => (
    <Button icon={<UploadOutlined />} disabled={disabled}>
      点击上传
    </Button>
  );
  if (!companyInfo) return null;
  return (
    <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
      {isDone ? (
        <Result
          status="success"
          title="提交成功!"
          subTitle="工作人员将在1-3个工作日内完成资料审核，请耐心等待"
          extra={[
            <Button
              key="goback"
              type="primary"
              onClick={() => history.goBack()}
            >
              返回
            </Button>,
          ]}
        />
      ) : (
        <div>
          <Form
            colon
            {...layout}
            form={form}
            onFinish={handleFinish}
            style={{ width: "40vw", minWidth: 279, maxWidth: 600 }}
          >
            <Form.Item
              label="姓名"
              name="legal_person"
              rules={[{ required: true, message: "请输入法人姓名" }]}
              initialValue={companyInfo.legal_person}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="职位"
              name="legal_person_position"
              rules={[{ required: true, message: "请输入法人职位" }]}
              initialValue={companyInfo.legal_person_position}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="电话"
              name="legal_person_phone"
              rules={[{ required: true, message: "请输入法人电话" }]}
              initialValue={companyInfo.legal_person_phone}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="联系地址"
              name="legal_person_address"
              rules={[{ required: true, message: "请输入法人联系地址" }]}
              initialValue={companyInfo.legal_person_address}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="电子邮箱"
              name="legal_person_email"
              rules={[
                { required: true, message: "请输入法人电子邮箱" },
                { type: "email", message: "请输入正确格式的邮箱地址" },
              ]}
              initialValue={companyInfo.legal_person_email}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="身份证号"
              name="legal_person_id_card_num"
              rules={[{ required: true, message: "请输入法人身份证号" }]}
              initialValue={companyInfo.legal_person_id_card_num}
            >
              <Input />
            </Form.Item>
            <Form.Item label="身份证照片">
              <div style={{ marginTop: 4, color: "red" }}>
                请上传原件正反面照片或扫描件
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  paddingTop: 8,
                }}
              >
                <Form.Item
                  style={{ display: "inline-block" }}
                  name="legal_person_id_card_pic_1"
                  rules={[{ required: true, message: "请上传法人身份证正面" }]}
                  extra={
                    <span>
                      身份证正面{" "}
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setPersonAVisible(true);
                        }}
                      >
                        示例
                      </a>
                    </span>
                  }
                  valuePropName="value"
                >
                  <UploadPicWidget action={UPLOAD_ACTION} />
                </Form.Item>
                <Form.Item
                  style={{ display: "inline-block" }}
                  name="legal_person_id_card_pic_2"
                  rules={[{ required: true, message: "请上传法人身份证反面" }]}
                  extra={
                    <span>
                      身份证反面{" "}
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setPersonBVisible(true);
                        }}
                      >
                        示例
                      </a>
                    </span>
                  }
                >
                  <UploadPicWidget action={UPLOAD_ACTION} />
                </Form.Item>
              </div>
            </Form.Item>

            <Form.Item
              label="征信报告"
              name="legal_credit"
              extra="请上传原件照片、扫描件、或复印件加盖公章，支持格式包括.jpg/.png/.pdf，文件大小不超过10M"
              hasFeedback
            >
              <Upload
                action={UPLOAD_ACTION}
                onChange={({ file, fileList }) => {
                  if (file.status === "success") {
                    console.log(file.response);
                  }
                }}
                onRemove={() => {
                  form.setFieldsValue({ business_license_pic: undefined });
                }}
              >
                <UploadButton />
              </Upload>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" loading={fetching}>
                {fetching ? "正在提交" : "提交"}
              </Button>
            </Form.Item>
          </Form>
          <Modal
            title=""
            footer={null}
            visible={personAVisible}
            onCancel={() => {
              setPersonAVisible(false);
            }}
          >
            <Image
              src={
                "https://lq-fe-img.oss-cn-hangzhou.aliyuncs.com/lq-credit/sample/person-A.jpg"
              }
            />
          </Modal>
          <Modal
            title=""
            footer={null}
            visible={personBVisible}
            onCancel={() => {
              setPersonBVisible(false);
            }}
          >
            <Image
              src={
                "https://lq-fe-img.oss-cn-hangzhou.aliyuncs.com/lq-credit/sample/person-B.jpg"
              }
            />
          </Modal>
        </div>
      )}
      <Divider>说明</Divider>
      <div style={{ margin: "0 48px" }}>
        <h4
          style={{ color: "rgba(0,0,0,.45)", fontSize: 15, lineHeight: "22px" }}
        >
          温馨提示
        </h4>
        <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
          1.带 * 的为必填项。
        </p>
        <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
          2.文件项请严格按照其下方说明进行上传。
        </p>
        <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
          3.单张图片可直接上传，若某一项需上传多张图片，请转为PDF文件后上传。
        </p>
      </div>
    </div>
  );
};

export default SubmitLegalPersonInfo;
