import * as React from "react";
import {
  CreditCardOutlined,
  BankOutlined,
  PayCircleOutlined,
  OrderedListOutlined,
  FileDoneOutlined,
  InsuranceOutlined,
} from "@ant-design/icons";

import { Link, useHistory, withRouter, useLocation } from "react-router-dom";
import { useCtxState } from "@/store/App.store";
import { Layout, Menu } from "antd";
import "./index.less";

const { SubMenu } = Menu;

interface MenuChildItem {
  to: string;
  label: string;
  hideInSiderBar: boolean;
  key: string;
}
interface MenuItem {
  to?: string;
  key: string;
  label: string;
  icon: React.ReactNode;
  children?: MenuChildItem[];
  hideInSiderBar: boolean;
}

interface SysMenuConfig {
  defaultActive: string;
  defaultOpeneds: string[];
  menus: MenuItem[];
}

const rootSubmenuKeys = ["my-company", "my-credit", "my-quota"];

const SiderBarMenuConfig: SysMenuConfig = {
  defaultActive: "company-info",
  defaultOpeneds: ["my-company"],
  menus: [
    {
      label: "我的企业",
      key: "my-company",
      icon: <BankOutlined />,
      children: [
        {
          to: "/base/my-company/company-info",
          label: "企业信息",
          hideInSiderBar: false,
          key: "company-info",
        },
        {
          to: "/base/my-company/legal-person-info",
          label: "法人信息",
          hideInSiderBar: false,
          key: "legal-person-info",
        },
        {
          to: "/base/my-company/identification",
          label: "实名认证",
          hideInSiderBar: false,
          key: "identification",
        },
        {
          to: "/base/my-company/agent-info",
          label: "经办人信息",
          hideInSiderBar: false,
          key: "agent-info",
        },

        {
          to: "/base/my-company/shareholder-info",
          label: "股东信息",
          hideInSiderBar: false,
          key: "shareholder-info",
        },
        {
          to: "/base/my-company/tax-info",
          label: "财税资料",
          hideInSiderBar: false,
          key: "tax-info",
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: "我的授信",
      key: "my-credit",
      icon: <CreditCardOutlined />,
      children: [
        {
          to: "/base/my-credit/credit-info",
          label: "授信信息",
          hideInSiderBar: false,
          key: "credit-info",
        },
        {
          to: "/base/my-credit/base-quota",
          label: "综合额度",
          hideInSiderBar: false,
          key: "base-quota",
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: "合同管理",
      key: "contract",
      icon: <FileDoneOutlined />,
      children: [
        {
          to: "/base/contract/all-contracts",
          label: "所有合同",
          hideInSiderBar: false,
          key: "all-contracts",
        },
        {
          to: "/base/contract/signing-contracts",
          label: "待签署的",
          hideInSiderBar: false,
          key: "signing-contracts",
        },
        {
          to: "/base/contract/applying-contracts",
          label: "我申请的",
          hideInSiderBar: false,
          key: "applying-contracts",
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: "e保理业务",
      key: "business",
      icon: <InsuranceOutlined />,
      children: [
        {
          to: "/base/business/product-list",
          label: "业务办理",
          hideInSiderBar: false,
          key: "product-list",
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: "我的办理",
      key: "my-process",
      icon: <OrderedListOutlined />,
      children: [
        {
          to: "/base/my-process/company-info-process",
          label: "基础资料变更记录",
          hideInSiderBar: false,
          key: "company-info-process",
        },
        {
          to: "/base/my-process/credit-process",
          label: "综合授信申请记录",
          hideInSiderBar: false,
          key: "credit-process",
        },
        {
          to: "/base/my-process/business-process",
          label: "业务办理记录",
          hideInSiderBar: false,
          key: "business-process",
        },
        {
          to: "/base/my-process/billing-process",
          label: "放款记录",
          hideInSiderBar: false,
          key: "billing-process",
        },
        {
          to: "/base/my-process/repayment-process",
          label: "还款记录",
          hideInSiderBar: false,
          key: "repayment-process",
        },
      ],
      hideInSiderBar: false,
    },
  ],
};

function getNavLinkNode(child_item: MenuChildItem, collapsed: boolean) {
  return (
    <span>
      <Link
        id={child_item.key}
        to={child_item.to}
        style={{
          color: "inherit",
          paddingRight: collapsed ? 0 : "100%",
        }}
      >
        {child_item.label}
      </Link>
    </span>
  );
}

const Sidebar = () => {
  const [{ userSysMenus, userInfo, sidebarCollapsed }, dispatch] =
    useCtxState();
  const [openKeys, setOpenKeys] = React.useState(["assets-management"]);
  const history = useHistory();
  const location = useLocation();
  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  React.useEffect(() => {
    const key = location.pathname.split("/")[2];
    if (key) {
      setOpenKeys([key]);
    } else {
      return;
    }
  }, [location]);

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={sidebarCollapsed}
      className="workbench-page__sidebar"
    >
      <div className="logo">
        {sidebarCollapsed ? (
          <div style={{ display: "flex" }}>
            <div
              style={{
                fontSize: 24,
                color: "#1DA57A",

                fontWeight: 500,
              }}
            >
              LQ
            </div>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <div
              style={{
                fontSize: 24,
                color: "#1DA57A",
                paddingRight: 36,
                fontWeight: 500,
              }}
            >
              兰青e保理
            </div>
          </div>
        )}
      </div>
      <Menu
        defaultSelectedKeys={[SiderBarMenuConfig.defaultActive]}
        defaultOpenKeys={SiderBarMenuConfig.defaultOpeneds}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        selectedKeys={[
          `${
            document.location.hash.slice(2)
              ? document.location.hash.slice(2).split("/")[2]
                ? document.location.hash.slice(2).split("/")[2].split("?")[0]
                : ""
              : ""
          }`,
        ]}
        mode="inline"
        theme="dark"
      >
        {SiderBarMenuConfig.menus.map((menu, _index) => {
          if (menu.children && menu.children.length > 0) {
            return (
              <SubMenu icon={menu.icon} key={menu.key} title={menu.label}>
                {menu.children.map((child) => {
                  return (
                    <Menu.Item key={child.key}>
                      {getNavLinkNode(child, sidebarCollapsed)}
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item key={menu.key} icon={menu.icon}>
                {menu.label}
              </Menu.Item>
            );
          }
        })}
      </Menu>
    </Layout.Sider>
  );
};

export default withRouter(Sidebar);
