/// <reference path="type.d.ts" />
/// <reference path="company.d.ts" />

import request from "./_base";
export const UPLOAD_ACTION = `/api/file/upload`;
export const DOWNLOAD_ACTION = `/api/file/download/`;

/**
 * 获取当前登陆人绑定的企业信息
 */
export async function getCompanyInfo(): Promise<
  ResponseProto<{ data: CompanyInfoItem }>
> {
  return request({
    method: "GET",
    url: `/company_info/info`,
  }) as any;
}

/**
 * 企业绑定
 * @param  {BindCompanyReq} input
 */
export async function bindCompany(
  input: BindCompanyReq
): Promise<ResponseProto<{ data: CompanyInfoItem }>> {
  return request({
    method: "POST",
    url: `/company_info/approval/bind`,
    data: input,
  }) as any;
}

/**
 * 经办人手机号唯一校验
 * @param  {string} phone
 */
export async function validateAgentPhone(input: {
  phone: string;
}): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/company_info/phone/unique`,
    data: input,
  }) as any;
}

/**
 * 经办人邮箱唯一校验
 * @param  {string} email
 */
export async function validateAgentEmail(input: {
  email: string;
}): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: ` /company_info/email/unique`,
    data: input,
  }) as any;
}
/**
 * 查询提交的基础资料申请list
 * @param  {GetCompanyApplyList} input
 */
export async function getCompanyApplyList(
  input: GetCompanyApplyList
): Promise<ResponseProto<{ data: CompanyInfoItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/company_info/list`,
    data: input,
  }) as any;
}

/**
 * 获取基础资料审批操作历史
 * @param  {GetCompanyApprovalReacordReq} input
 */
export async function getCompanyApprovalReacord(
  input: GetCompanyApprovalReacordReq
): Promise<ResponseProto<{ data: CompanyApprovalLogItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/operation/log/company-info`,
    data: input,
  }) as any;
}

/**
 * 变更企业信息申请
 * @param  {UpdateCompanyInfoReq} input
 */
export async function changeCompanyInfo(
  input: UpdateCompanyInfoReq
): Promise<ResponseProto<{ data: CompanyInfoItem }>> {
  return request({
    method: "PUT",
    url: `/company_info/update/approval_success`,
    data: input,
  }) as any;
}

/**
 * 绑定公司信息再提交
 * @param  {UpdateCompanyInfoReq} input
 */
export async function bindCompanyInfoResubmit(
  input: UpdateCompanyInfoReq
): Promise<ResponseProto<{ data: CompanyInfoItem }>> {
  return request({
    method: "PUT",
    url: `/company_info/update/`,
    data: input,
  }) as any;
}

/**
 * 变更公司信息再提交
 * @param  {UpdateCompanyInfoReq} input
 */
export async function updateCompanyInfoResubmit(
  input: UpdateCompanyInfoReq
): Promise<ResponseProto<{ data: CompanyInfoItem }>> {
  return request({
    method: "PUT",
    url: `/company_info/update/approval_fail`,
    data: input,
  }) as any;
}

/**
 * 查询我的基础资料提交记录
 * @param  {BaseListReq} input
 */
export async function getMyCompanyApplyList(
  input: BaseListReq
): Promise<ResponseProto<{ data: CompanyProfileApplyItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/company_info/approval/current_user/list`,
    data: input,
  }) as any;
}

/**
 * 查询单条提交详情
 * @param  {number} id
 */
export async function getCompanyApplyDetail(
  id: number
): Promise<ResponseProto<{ data: CompanyProfileApplyItem }>> {
  return request({
    method: "GET",
    url: `/company_info/approval/${id}`,
  }) as any;
}

/**
 * 提交企业基础信息
 * @param  {UpdateCompanyBaseInfoReq} input
 */
export async function updateCompanyBaseInfo(
  input: UpdateCompanyBaseInfoReq
): Promise<ResponseProto<{ data: CompanyProfileApplyItem }>> {
  return request({
    method: "POST",
    url: `/company_info/approval/submit_update_base_info_approval`,
    data: input,
  }) as any;
}

/**
 * 提交法人信息
 * @param  {UpdateLegalPersonInfoReq} input
 */
export async function updateLegalPersonInfo(
  input: UpdateLegalPersonInfoReq
): Promise<ResponseProto<{ data: CompanyProfileApplyItem }>> {
  return request({
    method: "POST",
    url: `/company_info/approval/submit_legal_person_approval`,
    data: input,
  }) as any;
}

/**
 * 提交经办人信息
 * @param  {UpdateAgentInfoReq} input
 */
export async function updateAgentInfo(
  input: UpdateAgentInfoReq
): Promise<ResponseProto<{ data: CompanyProfileApplyItem }>> {
  return request({
    method: "POST",
    url: `/company_info/approval/submit_agent_info_approval`,
    data: input,
  }) as any;
}

/**
 * 提交财税信息
 * @param  {UpdateTaxInfoReq} input
 */
export async function updateTaxInfo(
  input: UpdateTaxInfoReq
): Promise<ResponseProto<{ data: CompanyProfileApplyItem }>> {
  return request({
    method: "POST",
    url: `/company_info/approval/submit_finance_taxation_info_approval`,
    data: input,
  }) as any;
}

/**
 * 提交股东信息
 * @param  {UpdateShareHolderInfoReq} input
 */
export async function updateShareholderInfo(
  input: UpdateShareHolderInfoReq
): Promise<ResponseProto<{ data: CompanyProfileApplyItem }>> {
  return request({
    method: "POST",
    url: `/company_info/approval/submit_shareholder_info_approval`,
    data: input,
  }) as any;
}

/**
 * 提交章程
 * @param  {UpdateCharterReq} input
 */
export async function updateCharter(
  input: UpdateCharterReq
): Promise<ResponseProto<{ data: CompanyProfileApplyItem }>> {
  return request({
    method: "POST",
    url: `/company_info/approval/submit_charter_info_approval`,
    data: input,
  }) as any;
}

/**
 * 提交开户许可证
 * @param  {UpdateOpeningPermitReq} input
 */
export async function updateOpeningPermit(
  input: UpdateOpeningPermitReq
): Promise<ResponseProto<{ data: CompanyProfileApplyItem }>> {
  return request({
    method: "POST",
    url: `/company_info/approval/submit_opening_permit_info_approval`,
    data: input,
  }) as any;
}
