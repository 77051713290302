/// <reference path="type.d.ts" />
/// <reference path="quota.d.ts" />

import request from "./_base";

/**
 * 获取当前用户的综合额度信息
 */
export async function getMyBaseQuota(): Promise<
  ResponseProto<{ data: BaseQuotaInfoItem }>
> {
  return request({
    method: "get",
    url: `/quota/quota`,
  }) as any;
}

/**
 * 获取额度流水
 */
export async function getQuotaFlowList(
  input: GetQuotaFlowListReq
): Promise<ResponseProto<{ data: GetQuotaFlowListRes; total: number }>> {
  return request({
    method: "POST",
    url: `/quota-flow/list`,
    data: input,
  }) as any;
}

/**
 * 流水反查
 */
export async function flowResearch(
  id: number
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/quota-flow/info/${id}`,
  }) as any;
}
