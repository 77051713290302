import * as React from "react";
import { useLocation } from "react-router";
import ShareholderDisplayer from "../ShareholderInfo/components/ShareholderDisplayer";

const ShareholderViewPage = () => {
  const location = useLocation();
  const [data, setData] = React.useState<ShareholderItem | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (!location.state) return;
    const { item } = location.state as any;
    if (item) {
      setData(item);
    }
  }, [location]);
  if (!data) return null;
  return (
    <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
      <ShareholderDisplayer item={data} />
    </div>
  );
};

export default ShareholderViewPage;
