import * as React from "react";
import { useHistory, useLocation } from "react-router";
import {
  Button,
  Descriptions,
  Divider,
  Empty,
  PageHeader,
  message,
} from "antd";
import { useCtxState } from "@/store/App.store";

enum ServiceType {
  "管理" = 1,
  "融资",
  "催收",
  "担保",
}
enum ProductProps {
  "否" = 0,
  "是",
  "不限定",
}
enum OpenType {
  "隐蔽型" = 1,
  "公开型",
  "不限定",
}

const ProductDetail = () => {
  const [data, setData] = React.useState<ProductItem | undefined>(undefined);
  const history = useHistory();
  const location = useLocation();
  const [{ userInfo }] = useCtxState();
  React.useEffect(() => {
    if (location.pathname === "/base/business/product-list/detail") {
      const state = location.state as any;
      if (state) {
        if (state.data) {
          setData(state.data);
        }
      }
    }
  }, [location]);
  return (
    <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
      {data ? (
        <div>
          <PageHeader
            title={data.product_name}
            onBack={() => history.goBack()}
            extra={[
              <Button
                type="primary"
                onClick={() => {
                  const { credit_status } = userInfo;
                  if (credit_status === 1) {
                    history.push({
                      pathname: `/base/business/apply`,
                      search: `product_id=${data.id}`,
                      state: {
                        product: data,
                      },
                    });
                  } else {
                    message.error(
                      "您尚未完成授信，请先申请综合授信，再办理业务"
                    );
                  }
                }}
              >
                立即申请
              </Button>,
            ]}
          />
          <Descriptions
            title={
              <span>
                <div className="title-prefix"></div>产品信息
              </span>
            }
            style={{ margin: "0 48px" }}
          >
            <Descriptions.Item label="产品名称">
              {data.product_name}
            </Descriptions.Item>
            <Descriptions.Item label="服务类型">
              {data.service_type
                .split(",")
                .map((x) => ServiceType[Number(x)])
                .toString()}
            </Descriptions.Item>
            <Descriptions.Item label="是否有追索权">
              {ProductProps[data.right_of_recourse]}
            </Descriptions.Item>
            <Descriptions.Item label="公开/隐蔽型保理">
              {OpenType[data.open_type]}
            </Descriptions.Item>
            <Descriptions.Item label="是否需要抵押">
              {ProductProps[data.mortgage_need]}
            </Descriptions.Item>
            <Descriptions.Item label="是否需要质押">
              {ProductProps[data.pledge_need]}
            </Descriptions.Item>
            <Descriptions.Item label="是否需要保证">
              {ProductProps[data.guarantee_contract_sign]}
            </Descriptions.Item>
            <Descriptions.Item label="融资期限">\ 天</Descriptions.Item>
            <Descriptions.Item label="授信总额">\ 元</Descriptions.Item>
            <Descriptions.Item label="单笔融资最小限额">\ 元</Descriptions.Item>
            <Descriptions.Item label="单笔融资最大限额">\ 元</Descriptions.Item>
            <Descriptions.Item label="最低综合利率">\ %</Descriptions.Item>
            <Descriptions.Item label="最高综合利率">\ %</Descriptions.Item>
          </Descriptions>
          <Divider>产品简介</Divider>
          <div style={{ margin: "0 48px" }}>
            <p>{data.product_describe}</p>
          </div>
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default ProductDetail;
