import * as React from "react";
import { useLocation } from "react-router";
import { Divider, Result, Descriptions } from "antd";
import { toThousands } from "@/utils/string";
import FileViewer from "@/ui-components/FileViewer";

const BillingDetail = () => {
  const [data, setData] = React.useState<BillingListItem>();

  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === "/base/my-process/billing-detail") {
      if (location.state) {
        const { record } = location.state as any;
        setData(record);
      }
    }
  }, [location]);

  const getTitle = () => {
    if (!data) return;
    if (data.status === 0) {
      return "放款中";
    }
    if (data.status === 1) {
      return "放款中";
    }
    return "已放款";
  };

  const getStatus = () => {
    if (!data) return;

    if (data.status === 2) {
      return "success";
    }
    return "info";
  };

  const getSubTitle = () => {
    if (!data) return;
    if (data.status === 0) {
      return "放款流程审核中，请耐心等待";
    }
    if (data.status === 1) {
      return `放款流程审核中，请耐心等待`;
    }
    return "放款流程完成，已放款";
  };
  if (!data) return null;

  return (
    <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
      <Result
        title={getTitle()}
        status={getStatus()}
        subTitle={getSubTitle()}
      ></Result>
      <Divider>放款信息</Divider>
      <Descriptions>
        <Descriptions.Item label="金额">
          {toThousands(data.amount)} 元
        </Descriptions.Item>
        <Descriptions.Item label="申请时间">
          {data.create_time}
        </Descriptions.Item>
        <Descriptions.Item label="约定放款日期">
          {data.deadline}
        </Descriptions.Item>
        {data.account && (
          <>
            <Descriptions.Item label="收款账户户名">
              {JSON.parse(data.account)["开户名"] ||
                JSON.parse(data.account)["户名"]}
            </Descriptions.Item>
            <Descriptions.Item label="收款账户账号">
              {JSON.parse(data.account)["账号"]}
            </Descriptions.Item>
            <Descriptions.Item label="收款账户开户行">
              {JSON.parse(data.account)["开户行"]}
            </Descriptions.Item>
          </>
        )}

        <Descriptions.Item label="回单">
          {data.loan_receipt
            ? data.loan_receipt
                .split(",")
                .map((img: string) => <FileViewer file={img} key={img} />)
            : "无"}
        </Descriptions.Item>

        <Descriptions.Item label="放款时间">
          {data.audit_time ? data.audit_time : "无"}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default BillingDetail;
