import * as React from "react";
import { Spin, Empty, Button, Descriptions, Layout, Image, Result } from "antd";
import { getCompanyInfo, DOWNLOAD_ACTION } from "@/requests/company";
import { useHistory } from "react-router-dom";
import ContractPreviewer from "@/ui-components/ContractPreviewer";

const AgentInfo = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [companyInfo, setCompanyInfo] = React.useState<
    CompanyInfoItem | undefined
  >(undefined);
  const [isProcess, setIsProcess] = React.useState(false);
  const [isBind, setIsBind] = React.useState(true);

  const fetchCompanyInfo = async () => {
    const res = await getCompanyInfo();
    if (res.code === 0) {
      if (res.data !== null) {
        setCompanyInfo(res.data);
      }
    } else if (res.code === 13006) {
      setIsProcess(true);
    } else if (res.code === 13007) {
      setIsBind(false);
    }
    setLoading(false);
  };

  const goBindPage = () => {
    history.push("/base/my-company/bind-apply");
  };

  React.useEffect(() => {
    setLoading(true);
    fetchCompanyInfo();
  }, []);
  return (
    <Layout>
      <Layout.Content>
        <Spin
          tip="加载中"
          spinning={loading}
          style={{ position: "absolute", top: "50%", left: "50%" }}
        ></Spin>

        {isProcess && (
          <div style={{ backgroundColor: "#ffffff" }}>
            <Result
              status="info"
              title="审核中"
              subTitle="您的企业信息资料正在审核中"
              extra={[
                <Button
                  key="view"
                  type="primary"
                  onClick={() =>
                    history.push({
                      pathname: "/base/my-process/company-info-process",
                    })
                  }
                >
                  点击查看
                </Button>,
              ]}
            />
          </div>
        )}
        {companyInfo && (
          <div
            style={{
              backgroundColor: "#ffffff",
              marginBottom: 16,
              padding: 16,
            }}
          >
            <Descriptions
              title="经办人信息"
              bordered
              extra={[
                <Button
                  type="primary"
                  onClick={() => {
                    history.push({
                      pathname: "/base/my-company/submit-agent-info",
                      state: {
                        type: 1,
                      },
                    });
                  }}
                >
                  申请变更
                </Button>,
              ]}
            >
              <Descriptions.Item label="姓名">
                {companyInfo.agent_name}
              </Descriptions.Item>
              <Descriptions.Item label="职位">
                {companyInfo.agent_position}
              </Descriptions.Item>
              <Descriptions.Item label="联系电话">
                {companyInfo.agent_phone}
              </Descriptions.Item>
              <Descriptions.Item label="电子邮箱">
                {companyInfo.agent_email}
              </Descriptions.Item>

              <Descriptions.Item label="联系地址">
                {companyInfo.agent_address}
              </Descriptions.Item>
              <Descriptions.Item label="身份证号">
                {companyInfo.agent_id_card_num}
              </Descriptions.Item>
              <Descriptions.Item label="身份证信息">
                {companyInfo.agent_id_card_pic.map((x, index) => {
                  return x ? (
                    <span style={{ marginRight: 8 }}>
                      <Image
                        width={29}
                        height={29}
                        src={DOWNLOAD_ACTION + x.file_name}
                      />
                    </span>
                  ) : null;
                })}
              </Descriptions.Item>
              <Descriptions.Item label="法人授权书">
                <ContractPreviewer
                  contract_id={companyInfo.legal_person_auth_pic}
                />
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}
        {!isBind && (
          <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
            <Empty
              description={
                <span>您还未绑定企业信息，请先绑定您的企业信息</span>
              }
            >
              <Button type="primary" onClick={goBindPage}>
                立即绑定
              </Button>
            </Empty>
          </div>
        )}
      </Layout.Content>
    </Layout>
  );
};
export default AgentInfo;
