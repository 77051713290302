import * as React from "react";
import { List, Tag, Tooltip } from "antd";
import { getMyCreditList } from "@/requests/credit";
import { toThousands } from "@/utils/string";
import { useLocation } from "react-router";

const CreditProcess = () => {
  const [loading, setLoading] = React.useState(false);

  const [data, setData] = React.useState<CreditApplyItem[]>([]);

  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });

  const location = useLocation();

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    setLoading(true);
    const res = await getMyCreditList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });
    if (res.code === 0) {
      setData(res.data);
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    }
    setLoading(false);
  };
  const StatusEl = ({ item }: { item: CreditApplyItem }) => {
    const status = item.approval_status;
    if (
      status === 0 ||
      status === 1 ||
      status === 3 ||
      status === 5 ||
      status === 7 ||
      status === 9
    ) {
      return <Tag color="processing">审核中</Tag>;
    }
    if (
      status === 2 ||
      status === 4 ||
      status === 6 ||
      status === 8 ||
      status === 10 ||
      status === 12
    ) {
      return (
        <span>
          <a onClick={(e) => e.preventDefault()} style={{ marginRight: 8 }}>
            <Tooltip
              title={item.operation_logs[0].comment}
              color="volcano"
              overlayStyle={{ minWidth: 200, textAlign: "center" }}
            >
              查看原因
            </Tooltip>
          </a>
          <Tag color="error">审核不通过</Tag>
        </span>
      );
    }
    if (status === 11) {
      return <Tag color="success">审核通过</Tag>;
    }
    return null;
  };

  React.useEffect(() => {
    if (location.pathname === "/base/my-process/credit-process") {
      fetchData();
    }
  }, [location]);
  return (
    <div className="company-apply-list-page">
      <div style={{ backgroundColor: "#FFFFFF", padding: 16 }}>
        <List
          dataSource={data}
          loading={loading}
          renderItem={(item) => {
            return (
              <List.Item key={item.id} actions={[<StatusEl item={item} />]}>
                <List.Item.Meta
                  title={`综合授信申请`}
                  description={
                    <div>
                      <span style={{ paddingRight: 20 }}>
                        {`申请额度：${toThousands(
                          Number(item.credit_line.toFixed(2))
                        )}`}
                        元
                      </span>
                      <span style={{ paddingRight: 20 }}>
                        {`授信期限：${item.credit_term}`} 个月
                      </span>
                      <span style={{ paddingRight: 20 }}>
                        {`申请时间：${item.apply_time}`}{" "}
                      </span>
                    </div>
                  }
                />
              </List.Item>
            );
          }}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
            style: { marginRight: 16 },
            showTotal: (total) => <span>共{total}条数据</span>,
          }}
        />
      </div>
    </div>
  );
};

export default CreditProcess;
