/// <reference path="router.d.ts" />
import { withRouter } from "react-router-dom";
import BaseCompanyInfo from "@/pages/Company/pages/BaseCompanyInfo";
import CreditInfo from "@/pages/Credit/pages/MyCredit";
import CreditApply from "@/pages/Credit/pages/CreditApply";
import BindCompany from "@/pages/Company/pages/BindCompany";
import NoMatched from "@/pages/404";
import LegalPersonInfo from "@/pages/Company/pages/LegalPersonInfo";
import AgentInfo from "@/pages/Company/pages/AgentInfo";
import TaxInfo from "@/pages/Company/pages/TaxInfo";
import ShareholderInfo from "@/pages/Company/pages/ShareholderInfo";
import SubmitBaseCompanyInfo from "@/pages/Company/pages/BaseCompanyInfo/Submit";
import SubmitLegalPersonInfo from "@/pages/Company/pages/LegalPersonInfo/Submit";
import SubmitAgentInfo from "@/pages/Company/pages/AgentInfo/Submit";
import IdentificationInfo from "@/pages/Company/pages/IdentficationInfo";

import CompanyInfoProcess from "@/pages/MyProcess/pages/CompanyInfoProcess";
import ShareholderViewPage from "@/pages/Company/pages/ShareholderView";
import CreditProcess from "@/pages/MyProcess/pages/CreditProcess";
import BaseQuota from "@/pages/MyQuota/pages/BaseQuota";
import AllContracts from "@/pages/MyContract/pages/AllContracts";
import SigningContracts from "@/pages/MyContract/pages/SigningContracts";
import ApplyingContracts from "@/pages/MyContract/pages/ApplyingContracts";
import ProductList from "@/pages/Business/pages/ProductList";
import ProductDetail from "@/pages/Business/pages/ProductDetail";
import BusinessApply from "@/pages/Business/pages/BusinessApply";
import BusinessApplyRecord from "@/pages/Business/pages/BusinessApplyReacord";
import BusinessApplyDetail from "@/pages/Business/pages/BusinessApplyDetail";
import BillingProcess from "@/pages/MyProcess/pages/BillingProcess";
import BillingDetail from "@/pages/MyProcess/pages/BillingDetail";
import RepaymentRecord from "@/pages/MyProcess/pages/RepaymentProcess";
import RepaymentApplyDetail from "@/pages/MyProcess/pages/RepaymentApplyDetail";

export const ROUTE_CONFIG: BaseRouteConfigItem[] = [
  {
    exact: true,
    path: "/base/my-company/company-info",
    component: withRouter(BaseCompanyInfo),
  },
  {
    exact: true,
    path: "/base/my-company/submit-company-info",
    component: withRouter(SubmitBaseCompanyInfo),
  },
  {
    exact: true,
    path: "/base/my-company/legal-person-info",
    component: withRouter(LegalPersonInfo),
  },
  {
    exact: true,
    path: "/base/my-company/submit-legal-person-info",
    component: withRouter(SubmitLegalPersonInfo),
  },
  {
    exact: true,
    path: "/base/my-company/agent-info",
    component: withRouter(AgentInfo),
  },
  {
    exact: true,
    path: "/base/my-company/submit-agent-info",
    component: withRouter(SubmitAgentInfo),
  },
  {
    exact: true,
    path: "/base/my-company/tax-info",
    component: withRouter(TaxInfo),
  },
  {
    exact: true,
    path: "/base/my-company/shareholder-info",
    component: withRouter(ShareholderInfo),
  },
  {
    exact: true,
    path: "/base/my-company/shareholder-info/detail",
    component: withRouter(ShareholderViewPage),
  },

  {
    exact: true,
    path: "/base/my-company/bind-apply",
    component: withRouter(BindCompany),
  },
  {
    exact: true,
    path: "/base/my-company/identification",
    component: withRouter(IdentificationInfo),
  },
  {
    exact: true,
    path: "/base/my-credit/credit-info",
    component: withRouter(CreditInfo),
  },
  {
    exact: true,
    path: "/base/my-credit/credit-apply",
    component: withRouter(CreditApply),
  },
  {
    exact: true,
    path: "/base/my-credit/base-quota",
    component: withRouter(BaseQuota),
  },
  {
    exact: true,
    path: "/base/my-process/company-info-process",
    component: withRouter(CompanyInfoProcess),
  },
  {
    exact: true,
    path: "/base/my-process/credit-process",
    component: withRouter(CreditProcess),
  },
  {
    exact: true,
    path: "/base/my-process/business-process",
    component: withRouter(BusinessApplyRecord),
  },
  {
    exact: true,
    path: "/base/contract/all-contracts",
    component: withRouter(AllContracts),
  },
  {
    exact: true,
    path: "/base/contract/signing-contracts",
    component: withRouter(SigningContracts),
  },
  {
    exact: true,
    path: "/base/contract/applying-contracts",
    component: withRouter(ApplyingContracts),
  },
  {
    exact: true,
    path: "/base/business/product-list",
    component: withRouter(ProductList),
  },
  {
    exact: true,
    path: "/base/business/product-list/detail",
    component: withRouter(ProductDetail),
  },
  {
    exact: true,
    path: "/base/business/apply",
    component: withRouter(BusinessApply),
  },

  {
    exact: true,
    path: "/base/business/record/detail",
    component: withRouter(BusinessApplyDetail),
  },
  {
    exact: true,
    path: "/base/my-process/billing-process",
    component: withRouter(BillingProcess),
  },
  {
    exact: true,
    path: "/base/my-process/billing-detail",
    component: withRouter(BillingDetail),
  },
  {
    exact: true,
    path: "/base/my-process/repayment-process",
    component: withRouter(RepaymentRecord),
  },

  {
    exact: true,
    path: "/base/my-process/repayment-process/detail",
    component: withRouter(RepaymentApplyDetail),
  },

  { exact: true, path: "*", component: NoMatched },
];
