import * as React from "react";
import {
  RouteComponentProps,
  withRouter,
  HashRouter as Router,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Layout, Modal } from "antd";

import * as UserRequests from "@/requests/user";
import { useCtxState } from "@/store/App.store";
import MyHeader from "@/ui-layout/Header";
import MyFooter from "@/ui-layout/Footer";
import MySider from "@/ui-layout/Sider";
import { ROUTE_CONFIG } from "@/router";

import "./index.less";
import CacheRoute, { CacheSwitch } from "react-router-cache-route";
import { useWindowSize } from "@/react-helpers/hooks/xom";

const { Header, Content, Footer } = Layout;

const Home = (props: RouteComponentProps) => {
  const [{ userInfo, sidebarCollapsed }, dispatch] = useCtxState();
  const location = useLocation();
  const history = useHistory();
  // const pathSnippets = location.hash
  //   .split("/")
  //   .filter((i) => i)
  //   .slice(1);

  const getUserInfo = async () => {
    const res = await UserRequests.getUserInfo();
    if (res.code === 0) {
      dispatch({
        type: "SAVE_INFO",
        info: res.data,
      });
      if (
        res.data.accreditation_sign === 1 &&
        res.data.legal_person_auth === 0
      ) {
        Modal.confirm({
          title: "提示",
          content:
            "您还未签署法人授权书，根据《e保理注册协议》及相关法律规定，您的账号必须签署法人授权书方可进行相关操作，请及时完成签署",
          okText: "前往签署",
          cancelText: "以后再说",
          onOk: () => history.push("/base/contract/signing-contracts"),
          onCancel: () => {},
          cancelButtonProps: { style: { display: "none" } },
        });
      }
    } else {
    }
  };
  React.useEffect(() => {
    getUserInfo();
  }, []);
  React.useEffect(() => {}, [location]);
  const { width: wWidth = Infinity } = useWindowSize();
  React.useLayoutEffect(() => {
    const nextCollapsed = wWidth <= 960;
    if (nextCollapsed === sidebarCollapsed) return;
    dispatch({
      type: "toggle:sidebarCollapsed",
      sidebarCollapsed: nextCollapsed,
    });
  }, [wWidth]);

  if (!userInfo) return null;
  return (
    <Layout className="home">
      <MySider></MySider>
      <Layout className="page_container">
        <MyHeader {...props} />
        <Content className="page_content">
          <CacheSwitch>
            {ROUTE_CONFIG.map((route) => {
              return (
                <CacheRoute
                  key={route.path}
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                  cacheKey={route.path}
                ></CacheRoute>
              );
            })}
          </CacheSwitch>
        </Content>
        <Route path="/base" exact>
          <Redirect to="/base/my-company/company-info" />
        </Route>
        <Footer className="home_footer">
          <MyFooter />
        </Footer>
      </Layout>
    </Layout>
  );
};

export default withRouter(Home);
