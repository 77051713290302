import * as React from "react";
import {
  getCompanyInfo,
  UPLOAD_ACTION,
  updateCompanyBaseInfo,
} from "@/requests/company";
import {
  Form,
  Button,
  Input,
  Upload,
  message,
  Result,
  Switch,
  Divider,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const SubmitBaseCompanyInfo = () => {
  const [companyInfo, setCompanyInfo] = React.useState<
    CompanyInfoItem | undefined
  >(undefined);
  const [fetching, setFeching] = React.useState(false);
  const [form] = Form.useForm();
  const [isDone, setIsDone] = React.useState(false);
  const history = useHistory();
  const fetchCompanyInfo = async () => {
    const res = await getCompanyInfo();
    if (res.code === 0) {
      setCompanyInfo(res.data);
    }
  };
  const [companyFileType, setCompanyFileType] = React.useState(false);

  const doSubmit = async (input: UpdateCompanyBaseInfoReq) => {
    setFeching(true);
    const res = await updateCompanyBaseInfo(input);
    if (res.code === 0) {
      message.success("提交成功");
      setIsDone(true);
    } else {
      message.error(res.message);
    }
    setFeching(false);
  };

  const handleFinish = (values: any) => {
    if (!companyInfo) return;
    console.log(
      values,
      !!values.company_profile,
      typeof values.company_profile === "string",
      typeof values.company_profile !== "string"
    );
    let company_profile = "";
    if (!values.company_profile) {
      company_profile = companyInfo.company_profile;
    }
    if (typeof values.company_profile === "string") {
      console.log("sssssssss");
      company_profile = values.company_profile;
    }
    if (typeof values.company_profile !== "string" && values.company_profile) {
      console.log("hahahahahhah");
      company_profile = values.company_profile.fileList[0].response.data;
    }

    doSubmit({
      id: companyInfo.id,
      company_name: values.company_name,
      register_address: values.register_address,
      contact_address: values.contact_address,
      company_phone: values.company_phone,
      business_license_pic: values.business_license_pic
        ? values.business_license_pic.fileList[0].response.data
        : companyInfo.business_license_pic,
      uniform_social_credit_code: values.uniform_social_credit_code,
      company_profile,
      company_credit: values.company_credit
        ? values.company_credit.fileList[0].response.data
        : companyInfo.company_credit,
    });
  };

  React.useEffect(() => {
    fetchCompanyInfo();
  }, []);
  const UploadButton = ({ disabled = false }) => (
    <Button icon={<UploadOutlined />} disabled={disabled}>
      点击上传
    </Button>
  );
  if (!companyInfo) return null;
  return (
    <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
      {isDone ? (
        <Result
          status="success"
          title="提交成功!"
          subTitle="工作人员将在1-3个工作日内完成资料审核，请耐心等待"
          extra={[
            <Button
              key="goback"
              type="primary"
              onClick={() => history.goBack()}
            >
              返回
            </Button>,
          ]}
        />
      ) : (
        <Form
          colon
          {...layout}
          form={form}
          onFinish={handleFinish}
          style={{ width: "40vw", minWidth: 279, maxWidth: 600 }}
        >
          <Form.Item
            label="企业名称"
            name="company_name"
            rules={[{ required: true, message: "请输入公司名称" }]}
            initialValue={companyInfo.company_name}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="注册地址"
            name="register_address"
            rules={[{ required: true, message: "请输入注册地址" }]}
            initialValue={companyInfo.register_address}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="联系地址"
            name="contact_address"
            rules={[{ required: true, message: "请输入联系地址" }]}
            initialValue={companyInfo.contact_address}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="企业电话"
            name="company_phone"
            rules={[{ required: true, message: "请输入企业电话" }]}
            initialValue={companyInfo.company_phone}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="营业执照"
            name="business_license_pic"
            extra="请上传原件照片、扫描件、或复印件加盖公章，支持格式包括.jpg/.png/.pdf，文件大小不超过10M"
            hasFeedback
          >
            <Upload
              action={UPLOAD_ACTION}
              onChange={({ file, fileList }) => {
                if (file.status === "success") {
                  console.log(file.response);
                }
              }}
              onRemove={() => {
                form.setFieldsValue({ business_license_pic: undefined });
              }}
            >
              <UploadButton />
            </Upload>
          </Form.Item>
          <Form.Item
            label="社会统一信用代码"
            name="uniform_social_credit_code"
            rules={[{ required: true, message: "请输入社会统一信用代码" }]}
            initialValue={companyInfo.uniform_social_credit_code}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="企业简介"
            extra={
              companyFileType
                ? "文件需加盖公章，支持格式包括.jpg/.png/.pdf/.docx等，文件大小不超过10M"
                : ""
            }
          >
            <Form.Item noStyle name="company_profile">
              {companyFileType ? (
                <Upload
                  action={UPLOAD_ACTION}
                  onChange={({ file, fileList }) => {
                    if (file.status === "success") {
                      console.log(file.response);
                    }
                  }}
                  onRemove={() => {
                    form.setFieldsValue({ business_license_pic: undefined });
                  }}
                >
                  <UploadButton />
                </Upload>
              ) : (
                <Input.TextArea
                  placeholder="请输入企业简介"
                  showCount
                  maxLength={1000}
                />
              )}
            </Form.Item>
            <Switch
              checked={companyFileType}
              checkedChildren="文件"
              unCheckedChildren="文字"
              onChange={(checked) => {
                setCompanyFileType(checked);
              }}
              style={{ marginTop: 5 }}
            ></Switch>
          </Form.Item>

          <Form.Item
            label="企业征信"
            name="company_credit"
            extra="文件需加盖公章，支持格式包括.jpg/.png/.pdf/.docx等，文件大小不超过10M"
          >
            <Upload
              action={UPLOAD_ACTION}
              onChange={({ file, fileList }) => {
                if (file.status === "success") {
                  console.log(file.response);
                }
              }}
              onRemove={() => {
                form.setFieldsValue({ business_license_pic: undefined });
              }}
            >
              <UploadButton />
            </Upload>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={fetching}>
              {fetching ? "正在提交" : "提交"}
            </Button>
          </Form.Item>
        </Form>
      )}
      <Divider>说明</Divider>
      <div style={{ margin: "0 48px" }}>
        <h4
          style={{ color: "rgba(0,0,0,.45)", fontSize: 15, lineHeight: "22px" }}
        >
          温馨提示
        </h4>
        <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
          1.带 * 的为必填项。
        </p>
        <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
          2.文件项请严格按照其下方说明进行上传。
        </p>
        <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
          3.单张图片可直接上传，若某一项需上传多张图片，请转为PDF文件后上传。
        </p>
      </div>
    </div>
  );
};

export default SubmitBaseCompanyInfo;
