import * as React from "react";
import { useLocation } from "react-router";
import {
  Divider,
  Result,
  Descriptions,
  message,
  Col,
  Tag,
  Table,
  Modal,
  PageHeader,
  Button,
} from "antd";
import { toThousands } from "@/utils/string";
import FileViewer from "@/ui-components/FileViewer";
import { getBusinessApplyRecordDetail } from "@/requests/business";
import RepaymentApplyModal from "../components/RepaymentApplyModal";

import ContractPreviewer from "@/ui-components/ContractPreviewer";
enum AccountType {
  "同授信账户" = 1,
  "其他账户",
}

//保理服务内容
enum FactoringContent {
  "融资" = 1,
  "管理",
  "催收",
  "付款担保",
}
//公开隐蔽型保理
enum OpenType {
  "公开型" = 1,
  "隐蔽型",
}
//回款方式
enum ReturnedType {
  "直接回款" = 1,
  "间接回款",
  "其他",
}
//追索权
enum RecoveryCompensation {
  "有追索权" = 1,
  "无追索权",
}
//保理服务费支付方式
enum FactoringCostPayType {
  "期初一次性" = 1,
  "到期一次性",
  "其他",
}
//保理融资款收款账户类型
enum FanancingAccountType {
  "同授信合同" = 1,
  "其他账户",
}
//保理融资款支付方式
enum FanancingPayType {
  "同授信合同" = 1,
  "其他",
}

//保理融资款用途
enum FanacingPurpose {
  "不限定用途" = 1,
  "限定用途",
}

//宽限期类型
enum GraceType {
  "没有宽限期" = 1,
  "有宽限期",
}

//支付方式
enum PayType {
  "期初一次性" = 1,
  "分期支付",
  "到期一次性",
  "其他",
}

//增信措施
enum CreditVoucher {
  "无增信措施" = 0,
  "自身不动产抵押",
  "第三方不动产抵押",
  "自身动产质押",
  "第三方动产质押",
  "第三方担保",
}
//合同状态
enum ContractStatus {
  "未发送" = 1,
  "待签署",
  "签署完成",
  "拒绝签署",
}
// 涉诉情况
enum Litagation {
  "否" = 0,
  "是",
}
enum SatisFyRegulation {
  "否" = 0,
  "是" = 1,
}
enum HaveAdvance {
  "否" = 0,
  "是" = 1,
}

const BusinessApplyDetail = () => {
  const [data, setData] = React.useState<BusinessApplyRecordItem>();
  const [productInfo, setProductInfo] = React.useState<ProductItem>();
  const [repayVisible, setRepayVisible] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === "/base/business/record/detail") {
      if (location.state) {
        const { record, product } = location.state as any;

        fetchData(record.id);
        setProductInfo(product);
      } else {
        if (location.search) {
          fetchData(Number(location.search.split("=")[1]));
        }
      }
    }
  }, [location]);

  const fetchData = async (id: number) => {
    const res = await getBusinessApplyRecordDetail(id);
    if (res.code === 0) {
      setData(res.data);
    } else {
      message.error(res.message);
    }
  };

  if (!data) return null;

  const getTitle = () => {
    if (!data) return;
    if (data.status === 11) {
      return "申请通过，放款中";
    }
    if (data.status === 15) {
      return "放款中";
    }
    if (data.status === 16) {
      return "已放款";
    }
    if (data.status === 2) {
      return "申请失败";
    }
    if (data.status > 16 && data.status < 22) {
      return "还款申请审核中";
    }
    if (data.status === 22) {
      return "已还款";
    }
    return "申请中";
  };

  const getStatus = () => {
    if (!data) return;
    if (data.status === 16) {
      return "success";
    }
    if (data.status === 11) {
      return "info";
    }
    if (data.status === 15) {
      return "info";
    }
    if (data.status === 2) {
      return "error";
    }
    if (data.status === 22) {
      return "success";
    }
    return "info";
  };

  const getSubTitle = () => {
    if (!data) return;
    if (data.status === 11) {
      return "您的申请已通过，已进入放款流程，请在放款记录中查看放款进度";
    }
    if (data.status === 15) {
      return "您的申请已通过，已进入放款流程，请在放款记录中查看放款进度";
    }
    if (data.status === 16) {
      return "该申请已完成放款，可在放款管记录中查看详情";
    }
    if (data.status === 2) {
      return `业务审核失败`;
    }
    if (data.status > 16 && data.status < 22) {
      return "您已提交还款，后台人员审核中，可在还款记录中查看审核进度";
    }
    if (data.status === 22) {
      return "您已完成还款";
    }
    return "后台审核中，请耐心等待";
  };

  const getContractStatusColor = (status: number) => {
    switch (status) {
      case 1:
        return "orange";
      case 2:
        return "volcano";
      case 3:
        return "green";
      case 4:
        return "red";
      default:
        return "orange";
    }
  };

  const handleReapyClick = () => {
    setRepayVisible(true);
  };
  if (!data) return null;
  return (
    <div>
      {data.status === 16 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 16,
          }}
        >
          <div>
            <Button type="primary" onClick={handleReapyClick}>
              申请还款
            </Button>
          </div>
        </div>
      )}
      <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
        <Result
          title={getTitle()}
          status={getStatus()}
          subTitle={getSubTitle()}
        ></Result>
        <Divider>申请内容</Divider>
        <Descriptions>
          {productInfo && (
            <Descriptions.Item label="产品">
              {productInfo.product_name}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="申请额度">
            {toThousands(data.quota)} 元
          </Descriptions.Item>
          <Descriptions.Item label="融资期限">
            {data.quota_deadline} 天
          </Descriptions.Item>

          <Descriptions.Item label="融资收款账户">
            {AccountType[data.account_type]}
          </Descriptions.Item>
          {data.account_type === 2 && (
            <Descriptions.Item label="其他收款账户账户名">
              {JSON.parse(data.other_account)["账户名"]}
            </Descriptions.Item>
          )}
          {data.account_type === 2 && (
            <Descriptions.Item label="其他收款账户账户名">
              {JSON.parse(data.other_account)["账号"]}
            </Descriptions.Item>
          )}
          {data.account_type === 2 && (
            <Descriptions.Item label="其他收款账户账户名">
              {JSON.parse(data.other_account)["开户行"]}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="申请时间">
            {data.create_time}
          </Descriptions.Item>
        </Descriptions>
        {data.status > 0 && data.status !== 2 && (
          <>
            <Divider>业务信息</Divider>
            <Descriptions>
              <Descriptions.Item label="保理服务内容">
                {data.approval_config.factoring_content.split(",").map((x) => (
                  <Tag color={"geekblue"}>{FactoringContent[Number(x)]}</Tag>
                ))}
              </Descriptions.Item>
              <Descriptions.Item label="公开/隐蔽型保理">
                {OpenType[data.approval_config.open_type]}
              </Descriptions.Item>
              <Descriptions.Item label="追索权">
                {
                  RecoveryCompensation[
                    data.approval_config.recovery_compensation
                  ]
                }
              </Descriptions.Item>
              <Descriptions.Item label="回款方式">
                {ReturnedType[data.approval_config.returned_type]}
              </Descriptions.Item>
              <Descriptions.Item label="其他回款方式">
                {data.approval_config.other_returned_type}
              </Descriptions.Item>
            </Descriptions>
            <Divider>应收账款信息</Divider>
            <Descriptions>
              <Descriptions.Item label="应收账款总额">
                {toThousands(data.approval_account.account_total)} 元
              </Descriptions.Item>
              <Descriptions.Item label="应收账款债务人">
                {data.debtor}
              </Descriptions.Item>

              <Descriptions.Item label="债务人电话">
                {data.approval_account.obligor_phone}
              </Descriptions.Item>

              <Descriptions.Item label="贸易合同">
                <FileViewer file={data.contract} />
              </Descriptions.Item>

              <Descriptions.Item label="贸易发票">
                <FileViewer file={data.invoice} />
              </Descriptions.Item>
              {data.invoice && (
                <Descriptions.Item label="发票数量">
                  {data.invoice_number} 张
                </Descriptions.Item>
              )}
              {data.invoice && (
                <Descriptions.Item label="发票总金额">
                  {toThousands(data.invoice_total)} 元
                </Descriptions.Item>
              )}
              <Descriptions.Item label="其他材料">
                <FileViewer file={data.other_information} />
              </Descriptions.Item>
              {data.approval_account.varied_sign === 0 && (
                <Descriptions.Item label="应收账款描述" span={2}>
                  {data.approval_account.described}
                </Descriptions.Item>
              )}

              {data.approval_account.varied_sign === 1 && (
                <Descriptions.Item label="应收账款详情" span={3}>
                  <Table
                    pagination={false}
                    style={{ width: "100%" }}
                    dataSource={data.approval_account.approval_account_children}
                    columns={[
                      {
                        title: "序号",
                        key: "order",
                        align: "center",
                        render: (text, record, index) => index + 1,
                      },
                      {
                        dataIndex: "contract_sn",
                        key: "contract_sn",
                        title: "基础交易合同及编号",
                        align: "center",
                      },
                      {
                        dataIndex: "bill_name",
                        key: "bill_name",
                        title: "单据名称",
                        align: "center",
                      },
                      {
                        dataIndex: "bill_sn",
                        key: "bill_sn",
                        title: "单据号码",
                        align: "center",
                      },
                      {
                        key: "account_total",
                        title: "应收账款总额",
                        align: "center",
                        render: (text, record, index) => (
                          <span>{toThousands(record.account_total)} 元</span>
                        ),
                      },
                      {
                        dataIndex: "account_expire",
                        key: "account_expire",
                        title: "应收账款到期日",
                        align: "center",
                      },
                    ]}
                  ></Table>
                </Descriptions.Item>
              )}
            </Descriptions>
            <Divider>费用信息</Divider>
            <Descriptions>
              <Descriptions.Item label="保理融资款金额">
                {toThousands(data.cost_config.financing_total)} 元
              </Descriptions.Item>
              <Descriptions.Item label="保理融资款比例">
                {data.cost_config.financing_proportion}%
              </Descriptions.Item>
              <Descriptions.Item label="保理融资款支付方式">
                {FanancingPayType[data.cost_config.financing_pay_type]}
              </Descriptions.Item>
              {data.cost_config.financing_pay_type === 2 && (
                <Descriptions.Item label="其他保理融资款支付方式">
                  {data.cost_config.other_pay_type}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="保理融资款收款账户类型">
                {FanancingAccountType[data.cost_config.financing_account_type]}
              </Descriptions.Item>
              {data.cost_config.financing_account_type === 2 && (
                <>
                  <Descriptions.Item label="其他保理融资款账户账号">
                    {
                      JSON.parse(data.cost_config.other_financing_account)[
                        "账号"
                      ]
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label="其他保理融资款账户开户名">
                    {
                      JSON.parse(data.cost_config.other_financing_account)[
                        "开户名"
                      ]
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label="其他保理融资款账户开户行">
                    {
                      JSON.parse(data.cost_config.other_financing_account)[
                        "开户行"
                      ]
                    }
                  </Descriptions.Item>
                </>
              )}
              <Descriptions.Item label="保理融资期限">
                {data.cost_config.financing_expriy_date}
              </Descriptions.Item>
              <Descriptions.Item label="保理融资款发放日">
                {data.cost_config.financing_issue_date}
              </Descriptions.Item>
              <Descriptions.Item label="保理融资款用途">
                {FanacingPurpose[data.cost_config.financing_purpose]}
              </Descriptions.Item>
              {data.cost_config.financing_purpose === 2 && (
                <Descriptions.Item label="限定保理融资款用途">
                  {data.cost_config.financing_purpose_explain}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="保理服务费">
                {toThousands(data.cost_config.factoring_cost)} 元
              </Descriptions.Item>
              <Descriptions.Item label="保理服务费支付方式">
                {FactoringCostPayType[data.cost_config.factoring_cost_pay_type]}
              </Descriptions.Item>
              <Descriptions.Item label="逾期利率">
                {data.cost_config.overdue_interest_rate}%
              </Descriptions.Item>

              <Descriptions.Item label="是否有宽限期">
                {GraceType[data.cost_config.have_grace]}
              </Descriptions.Item>
              {data.cost_config.have_grace === 2 && (
                <>
                  <Descriptions.Item label="宽限期天数">
                    {JSON.parse(data.cost_config.grace_info)["天数"]}
                  </Descriptions.Item>
                  <Descriptions.Item label="宽限期届满日">
                    {JSON.parse(data.cost_config.grace_info)["届满日"]}
                  </Descriptions.Item>

                  <Descriptions.Item label="宽限期利息支付方式">
                    {PayType[data.cost_config.grace_interest_pay_type]}
                  </Descriptions.Item>
                  <Descriptions.Item label="宽限期利率">
                    {data.cost_config.grace_interest_rate}%
                  </Descriptions.Item>
                  {data.cost_config.grace_interest_pay_type === 2 && (
                    <>
                      <Descriptions.Item label="分期支付宽限期利息周期">
                        {
                          JSON.parse(
                            data.cost_config.periodization_grace_interest_rate
                          )["周期"]
                        }
                        天
                      </Descriptions.Item>
                      <Descriptions.Item label="分期支付宽限期利息支付日">
                        每月
                        {
                          JSON.parse(
                            data.cost_config.periodization_grace_interest_rate
                          )["支付日"]
                        }
                        日
                      </Descriptions.Item>
                    </>
                  )}
                  {data.cost_config.grace_interest_pay_type === 4 && (
                    <Descriptions.Item label="其他宽限期利息支付方式">
                      {data.cost_config.periodization_interest_pay_type}
                    </Descriptions.Item>
                  )}
                </>
              )}
              <Descriptions.Item label="正常期利率">
                {data.cost_config.normal_interest_rate}%
              </Descriptions.Item>
              <Descriptions.Item label="正常期利息支付方式">
                {PayType[data.cost_config.normal_interest_pay_type]}
              </Descriptions.Item>

              {data.cost_config.normal_interest_pay_type === 4 && (
                <Descriptions.Item label="其他正常期利息支付方式">
                  {data.cost_config.other_normal_interest_pay_type}
                </Descriptions.Item>
              )}

              {data.cost_config.normal_interest_pay_type === 2 && (
                <>
                  <Descriptions.Item label="分期支付正常期利息周期">
                    {
                      JSON.parse(data.cost_config.periodization_interest_rate)[
                        "周期"
                      ]
                    }
                    天
                  </Descriptions.Item>
                  <Descriptions.Item label="分期支付正常期利息支付日">
                    每月
                    {
                      JSON.parse(data.cost_config.periodization_interest_rate)[
                        "支付日"
                      ]
                    }
                    日
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>
            <Divider>增信措施及合同</Divider>
            <Descriptions>
              <Descriptions.Item label="增信措施">
                {data.credit_voucher.split(",").map((x: string) => (
                  <Tag key={x}>{CreditVoucher[Number(x)]}</Tag>
                ))}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions>
              <Descriptions.Item label="业务合同">
                <Col>
                  {data.contract_list.length > 0
                    ? data.contract_list.map((x, index) => (
                        <Col key={x.template_id}>
                          {x.template_name ? x.template_name : ""}{" "}
                          <Tag color={getContractStatusColor(x.status)}>
                            {ContractStatus[x.status]}
                          </Tag>
                          {x.status === 3 && (
                            <ContractPreviewer
                              contract_id={data.contract_id[index]}
                            />
                          )}
                        </Col>
                      ))
                    : "无"}
                </Col>
              </Descriptions.Item>
            </Descriptions>
            {data.status === 16 && (
              <>
                <Divider>放款信息</Divider>
                <Descriptions>
                  <Descriptions.Item label="金额">
                    {toThousands(data.billing_loan.amount)} 元
                  </Descriptions.Item>

                  {data.billing_loan.account && (
                    <>
                      <Descriptions.Item label="收款账户户名">
                        {JSON.parse(data.billing_loan.account)["户名"] ||
                          JSON.parse(data.billing_loan.account)["开户名"]}
                      </Descriptions.Item>
                      <Descriptions.Item label="收款账户账号">
                        {JSON.parse(data.billing_loan.account)["账号"]}
                      </Descriptions.Item>
                      <Descriptions.Item label="收款账户开户行">
                        {JSON.parse(data.billing_loan.account)["开户行"]}
                      </Descriptions.Item>
                    </>
                  )}

                  <Descriptions.Item label="回单">
                    {data.billing_loan.loan_receipt
                      ? data.billing_loan.loan_receipt
                          .split(",")
                          .map((img: string) => (
                            <FileViewer file={img} key={img} />
                          ))
                      : "无"}
                  </Descriptions.Item>

                  <Descriptions.Item label="放款时间">
                    {data.billing_loan.audit_time
                      ? data.billing_loan.audit_time
                      : "无"}
                  </Descriptions.Item>
                </Descriptions>
              </>
            )}

            {/* {data.status > 4 && (
            <>
              <Divider>风控合规管理信息</Divider>
              <Descriptions>
                <Descriptions.Item label="是否涉诉">
                  {Litagation[data.have_litigation]}
                </Descriptions.Item>
                <Descriptions.Item label="涉诉查询截图">
                  {data.litigation_img
                    ? data.litigation_img.map((img, index) => {
                        return <FileViewer file={img} key={index} />;
                      })
                    : "无"}
                </Descriptions.Item>
                <Descriptions.Item label="企业经营状况">
                  {data.management_stauts}
                </Descriptions.Item>
                <Descriptions.Item label="是否合规">
                  {SatisFyRegulation[data.satisfy_regulations]}
                </Descriptions.Item>
              </Descriptions>
            </>
          )} */}
            {/* {(data.status === 5 || data.status === 11) && (
            <>
              <Divider>财务审核信息</Divider>
              <Descriptions>
                <Descriptions.Item label="是否有预付款">
                  {HaveAdvance[data.have_advance]}
                </Descriptions.Item>
                {data.have_advance === 1 && (
                  <>
                    <Descriptions.Item label="预付款类目">
                      {data.advance_type}
                    </Descriptions.Item>
                    <Descriptions.Item label="预付款金额">
                      {toThousands(data.advance_money)} 元
                    </Descriptions.Item>
                    <Descriptions.Item label="是否到账">
                      {data.advance_arrival === 1 ? "是" : "否"}
                    </Descriptions.Item>
                  </>
                )}
              </Descriptions>
            </>
          )} */}
          </>
        )}
      </div>
      <RepaymentApplyModal
        visible={repayVisible}
        setVisible={setRepayVisible}
        business={data}
        reload={fetchData}
      />
    </div>
  );
};

export default BusinessApplyDetail;
