import * as React from "react";
import { message, List, Form, Button, DatePicker, Tag, Select } from "antd";
import { getBusinessApplyRecord, getProductList } from "@/requests/business";
import { RedoOutlined } from "@ant-design/icons";
import "../styles/index.less";
import { useHistory, useLocation } from "react-router";
import { toThousands } from "@/utils/string";

const StatusTag = ({ status }: { status: number }) => {
  switch (status) {
    case 0:
      return <Tag color={"gold"}>申请中</Tag>;
    case 11:
      return <Tag color={"orange"}>放款中</Tag>;
    case 15:
      return <Tag color={"orange"}>放款中</Tag>;
    case 2:
      return <Tag color={"red"}>申请失败</Tag>;
    case 16:
      return <Tag color={"green"}>已放款</Tag>;
    case 17:
      return <Tag color={"cyan"}>还款申请中</Tag>;

    case 22:
      return <Tag color={"magenta"}>已还款</Tag>;
    default:
      if (status > 16) {
        return <Tag color={"cyan"}>还款申请中</Tag>;
      } else {
        return <Tag color={"gold"}>申请中</Tag>;
      }
  }
};

const BusinessApplyRecord = () => {
  const [data, setData] = React.useState<BusinessApplyRecordItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [productList, setProductList] = React.useState<ProductItem[]>([]);
  const [filterType, setFilterType] = React.useState(0);
  const [renderData, setRenderData] = React.useState<BusinessApplyRecordItem[]>(
    []
  );
  const location = useLocation();
  const history = useHistory();

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    setLoading(true);
    const res = await getBusinessApplyRecord({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });
    if (res.code === 0) {
      setData(res.data);
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const fetchProductList = async () => {
    const res = await getProductList({
      limit: 100,
      skip: 0,
    });
    if (res.code === 0) {
      const _data = res.data.reverse().filter((x) => x.public_sign === 1);
      setProductList(_data);
    } else {
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (location.pathname === "/base/my-process/business-process") {
      fetchData();
      fetchProductList();
    }
  }, [location]);

  React.useEffect(() => {
    if (filterType === 0) {
      setRenderData(data);
    }
    if (filterType === 1) {
      setRenderData(
        data.filter((x) => (x.status === 0 || x.status < 11) && x.status !== 2)
      );
    }
    if (filterType === 2) {
      setRenderData(data.filter((x) => x.status === 2));
    }
    if (filterType === 3) {
      setRenderData(data.filter((x) => x.status === 11));
    }
    if (filterType === 4) {
      setRenderData(data.filter((x) => x.status == 14));
    }
  }, [filterType]);

  React.useEffect(() => {
    if (filterType === 0) {
      setRenderData(data);
    }
    if (filterType === 1) {
      setRenderData(
        data.filter((x) => (x.status === 0 || x.status < 11) && x.status !== 2)
      );
    }
    if (filterType === 2) {
      setRenderData(data.filter((x) => x.status === 2));
    }
    if (filterType === 3) {
      setRenderData(data.filter((x) => x.status === 11));
    }
    if (filterType === 4) {
      setRenderData(data.filter((x) => x.status == 14));
    }
  }, [data]);
  React.useEffect(() => {
    setPagination({ ...pagination, total: renderData.length });
  }, [renderData]);

  return (
    <div className="business-record-page">
      <div className="tools-wrapper">
        <div className="business-record-filter-input-container">
          <Form layout="inline">
            <Form.Item label="办理时间" className="filter-item">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label="状态" className="filter-item">
              <Select
                value={filterType}
                onChange={(value) => {
                  setFilterType(value);
                }}
              >
                <Select.Option value={0}>全部</Select.Option>
                <Select.Option value={1}>申请中</Select.Option>
                <Select.Option value={2}>申请失败</Select.Option>
                <Select.Option value={3}>放款中</Select.Option>
                <Select.Option value={4}>已完成</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </div>
        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            onClick={() => {
              fetchData();
            }}
            icon={<RedoOutlined />}
            style={{ marginRight: 16 }}
          >
            刷新
          </Button>
        </div>
      </div>
      <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
        <List
          dataSource={renderData}
          loading={loading}
          renderItem={(item) => {
            const product = productList.find((x) => x.id === item.product_id);
            return (
              <List.Item
                key={item.id}
                actions={[
                  <StatusTag status={item.status}></StatusTag>,
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/base/business/record/detail`,
                        state: {
                          record: item,
                          product: product,
                        },
                        search: `id=${item.id}`,
                      });
                    }}
                  >
                    详情
                  </a>,
                ]}
              >
                <List.Item.Meta
                  title={`${product ? product.product_name : "未知"}`}
                  description={
                    <span>
                      <span style={{ paddingRight: 16 }}>
                        申请金额：{toThousands(item.quota)}元
                      </span>
                      <span>申请时间：{item.create_time}</span>
                    </span>
                  }
                />
              </List.Item>
            );
          }}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
            style: { marginRight: 16 },
            showTotal: (total) => <span>共{total}条数据</span>,
          }}
        />
      </div>
    </div>
  );
};

export default BusinessApplyRecord;
