import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  Form,
  Layout,
  Input,
  Checkbox,
  Button,
  Row,
  Col,
  message,
  Select,
  Modal,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import MyFooter from "@/ui-layout/Footer";
import * as UserRequests from "@/requests/user";
import "./index.less";
import { USER_REGISTER_PROTOCOL } from "@/assets/protocol";
import { download } from "@/utils/file";
import FileViewer from "react-file-viewer";
import FormItem from "antd/lib/form/FormItem";

const { Footer, Content } = Layout;

interface RegiterFormValues extends RegisterReq {}

const Register = (props: RouteComponentProps) => {
  const [fetching, setFetching] = React.useState(false);
  const [form] = Form.useForm();
  const [hasSendSms, setHasSendSms] = React.useState(false);
  let [timer, setTimer] = React.useState(60);
  const [phone, setPhone] = React.useState("");
  const [visible, setVisible] = React.useState(false);
  const onFinish = (values: RegiterFormValues) => {
    console.log("Received values of form: ", values);
    const { user_name, password, email, phone, sm_code } = values;
    doRegister({
      user_name,
      password,
      sm_code,
      email,
      phone,
    });
  };

  const doRegister = async (input: RegisterReq) => {
    try {
      setFetching(true);
      const res = await UserRequests.register(input);
      if (res.code === 0) {
        message.success("注册成功，即将跳转登录！");
        setTimeout(() => props.history.replace("login"), 1000);
      } else {
        message.error(res.message);
      }
      setFetching(false);
    } catch (e) {
      console.log(e);
    }
  };

  const prefixSelector = (
    <Select style={{ width: 70 }} defaultValue="86">
      <Select.Option value="86">+86</Select.Option>
      <Select.Option value="87">+87</Select.Option>
    </Select>
  );

  return (
    <div className="register-container">
      <Layout>
        <Content>
          <Modal
            title="用户注册协议"
            okText="下载至本地"
            cancelText="已阅读并同意"
            visible={visible}
            onCancel={() => {
              form.setFieldsValue({
                agreement: true,
              });
              setVisible(false);
            }}
            width={"70vw"}
            destroyOnClose
            onOk={() => {
              download(
                "/api/file/protocol/download/1",
                "兰青e保理用户注册协议"
              );
              setVisible(false);
            }}
          >
            <div style={{ maxHeight: 500, minHeight: 400, overflow: "scroll" }}>
              <p>{USER_REGISTER_PROTOCOL}</p>
            </div>
          </Modal>
          <div className="register-content">
            <div className="form-container">
              <h2
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: 24,
                  lineHeight: 1.5,
                  color: "#1DA57A",
                  marginBottom: 24,
                }}
              >
                欢迎注册！
              </h2>
              <Form
                name="normal_register"
                className="register-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                form={form}
                scrollToFirstError
                onValuesChange={(c_value) => {
                  if (Object.keys(c_value)[0] === "phone")
                    setPhone(c_value[Object.keys(c_value)[0]]);
                }}
              >
                <Form.Item
                  name="user_name"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: "请输入您的用户名",
                    },
                    {
                      validator: async (_, value) => {
                        if (!value) return;
                        const res = await UserRequests.validateUserName({
                          user_name: value,
                        });
                        if (res.code === 0) {
                          return Promise.resolve();
                        } else if (res.code === 12009) {
                          return Promise.reject("该用户名已被注册！");
                        }
                      },
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    prefix={
                      <UserOutlined
                        className="site-form-item-icon"
                        style={{ color: "#1DA57A" }}
                      />
                    }
                    placeholder="用户名"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  validateTrigger="onBlur"
                  rules={[
                    { required: true, message: "请输入您的邮箱" },
                    {
                      validator: async (_, value) => {
                        if (!value) return;
                        const res = await UserRequests.validateEmail({
                          email: value,
                        });
                        if (res.code === 0) {
                          return Promise.resolve();
                        } else if (res.code === 12009) {
                          return Promise.reject("该邮箱已被注册！");
                        }
                      },
                    },
                    {
                      type: "email",
                      message: "请输入正确格式的邮箱地址",
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    prefix={
                      <MailOutlined
                        className="site-form-item-icon"
                        style={{ color: "#1DA57A" }}
                      />
                    }
                    placeholder="电子邮箱"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: "请输入您的密码!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    prefix={
                      <KeyOutlined
                        className="site-form-item-icon"
                        style={{ color: "#1DA57A" }}
                      />
                    }
                    placeholder="密码"
                  />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  validateTrigger="onBlur"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "请确认您的密码!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("两次输入密码不一致!");
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={
                      <LockOutlined
                        className="site-form-item-icon"
                        style={{ color: "#1DA57A" }}
                      />
                    }
                    placeholder="确认密码"
                  />
                </Form.Item>
                <Form.Item
                  name="phone"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: "请输入您的手机号",
                    },
                    {
                      validator: async (_, value) => {
                        if (!value) return;
                        const res = await UserRequests.validatePhone({
                          phone: value,
                        });
                        if (res.code === 0) {
                          return Promise.resolve();
                        } else if (res.code === 120013) {
                          return Promise.reject("该手机号已被注册！");
                        }
                      },
                    },
                    {
                      len: 11,
                      message: "请输入正确格式的手机号",
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    addonBefore={prefixSelector}
                    placeholder="注册手机号"
                  />
                </Form.Item>
                <Form.Item>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="image_code"
                        validateTrigger="onBlur"
                        noStyle
                        rules={[
                          { required: true, message: "请输入图形验证码" },
                        ]}
                      >
                        <Input disabled={fetching} placeholder="图形验证码" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      {phone && phone.length === 11 && (
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Col span={16}>
                            <img
                              style={{ height: 32 }}
                              src={`/api/user/register/image/${phone}`}
                              id="img-code"
                              alt="图形验证码"
                            />
                          </Col>
                          <Col
                            span={8}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                const img = document.getElementById(
                                  "img-code"
                                ) as HTMLImageElement;
                                if (img) {
                                  img.src = `/api/user/register/image/${phone}?code=${Math.random()}`;
                                }
                              }}
                            >
                              换一张
                            </a>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                  extra="系统将向您的手机发送短信验证码"
                  style={{ marginBottom: 8 }}
                >
                  <Row
                    gutter={8}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Col span={16}>
                      <Form.Item
                        name="sm_code"
                        validateTrigger="onBlur"
                        noStyle
                        rules={[
                          { required: true, message: "请输入手机短信验证码" },
                        ]}
                      >
                        <Input disabled={fetching} placeholder="短信验证码" />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        type="primary"
                        onClick={async () => {
                          const { getFieldValue, validateFields } = form;
                          const phone = getFieldValue("phone");
                          const image_code = getFieldValue("image_code");
                          console.log(image_code);
                          if (!phone) {
                            validateFields(["phone"]);
                            return;
                          }
                          if (!image_code) {
                            validateFields(["image_code"]);
                            return;
                          }

                          const res = await UserRequests.getRegisterSmCode({
                            phone,
                            image_code,
                          });
                          if (res.code === 0) {
                            message.success(
                              "已成功向您的手机发送验证码，请注意查收!"
                            );
                            setHasSendSms(true);
                            const timers = setInterval(() => {
                              const btn = document.getElementById(
                                "send-msm"
                              ) as any;
                              if (!btn) {
                                clearInterval(timers);
                                return;
                              }
                              btn.innerText = `${timer}s重新获取`;
                              timer -= 1;
                              if (timer === 0) {
                                setHasSendSms(false);
                                btn.innerText = `点击获取`;
                                clearInterval(timers);
                              }
                            }, 1000);
                          } else if (res.code === 10020) {
                            message.error("图形验证码错误！");
                          } else {
                            message.error(res.message);
                          }
                        }}
                        disabled={hasSendSms || fetching}
                        id="send-msm"
                      >
                        {hasSendSms ? `${timer}s重新获取` : "点击获取"}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject("请先阅读并同意《注册协议》"),
                    },
                  ]}
                  // {...tailFormItemLayout}
                >
                  <Checkbox>
                    我已阅读并同意
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        setVisible(true);
                      }}
                    >
                      《用户注册协议》
                    </a>
                  </Checkbox>
                </Form.Item>

                <Form.Item style={{ marginBottom: 8 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="register-form-button"
                    style={{ width: "100%" }}
                    loading={fetching}
                  >
                    {fetching ? "正在注册" : "注册"}
                  </Button>
                </Form.Item>
                <div className="form-row-wrapper">
                  <span>
                    已有账号？
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.history.push("/login");
                      }}
                    >
                      {"前往登录>"}
                    </a>
                  </span>
                </div>
              </Form>
            </div>
          </div>
        </Content>
        <Footer className="register-footer">
          <MyFooter />
        </Footer>
      </Layout>
    </div>
  );
};

export default withRouter(Register);
