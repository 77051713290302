import * as React from "react";
import {
  getRepaymentApplyDetail,
  getBusinessApplyRecordDetail,
} from "@/requests/business";
import { useHistory, useLocation } from "react-router";
import { message, Spin, Result, Divider, Descriptions } from "antd";
import { toThousands } from "@/utils/string";
import FileViewer from "@/ui-components/FileViewer";
import moment from "moment";

enum RepaymentType {
  "正常期还款" = 1,
  "逾期还款",
  "提前还款",
  "提前回购",
  "其他还款",
}

const RepaymentApplyDetail = () => {
  const [data, setData] = React.useState<RepaymentApplyListDetail>();
  const [busibess, setBusiness] = React.useState<BusinessApplyRecordItem>();
  const location = useLocation();
  const history = useHistory();

  const getTitle = () => {
    if (!data) return;
    if (data.status === 17) {
      return "审核中";
    }
    if (data.status === 18) {
      return "审核中";
    }
    if (data.status === 19) {
      return "审核不通过";
    }
    if (data.status === 20) {
      return "审核中";
    }
    if (data.status === 21) {
      return "审核中";
    }
    if (data.status === 22) {
      return "已完成";
    }
    if (data.status === 24) {
      return "审核中";
    }

    return "审核中";
  };

  const getStatus = () => {
    if (!data) return;
    if (data.status === 22) {
      return "success";
    }

    if (data.status === 19) {
      return "error";
    }
    return "info";
  };

  const getSubTitle = () => {
    if (!data) return;
    if (data.status === 17) {
      return "待业务员审核";
    }
    if (data.status === 18) {
      return "待业务主管审核";
    }
    if (data.status === 19) {
      return "业务员审核不通过，申请关闭";
    }
    if (data.status === 20) {
      return "待财务人员审核";
    }
    if (data.status === 21) {
      return "业务主管审核不通过，待业务员重新审核";
    }
    if (data.status === 22) {
      return "还款审核通过，还款已生效";
    }
    if (data.status === 24) {
      return "财务人员审核不通过，待业务主管重新审核";
    }

    return "审核中";
  };

  const fetchData = async (id: RepaymentApplyListItem["repayment_id"]) => {
    const res = await getRepaymentApplyDetail(id);
    if (res.code === 0) {
      setData(res.data);
    } else {
      message.error("网络错误");
    }
  };

  const fetchBusiness = async (id: number) => {
    const res = await getBusinessApplyRecordDetail(id);
    if (res.code === 0) {
      setBusiness(res.data);
    } else {
      message.error("网络错误");
    }
  };

  React.useEffect(() => {
    if (location.pathname === "/base/my-process/repayment-process/detail") {
      if (location.search) {
        const repaymenId = location.search.split("&")[0].split("=")[1];
        const businessId = location.search.split("&")[1].split("=")[1];
        fetchData(Number(repaymenId));
        fetchBusiness(Number(businessId));
      }
    }
  }, [location]);
  if (!data || !busibess) return null;
  return (
    <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
      <Result
        title={getTitle()}
        status={getStatus()}
        subTitle={getSubTitle()}
      ></Result>
      <Divider>还款详情</Divider>
      <Descriptions>
        <Descriptions.Item label="还款类型">
          {RepaymentType[data.type]}
        </Descriptions.Item>
        <Descriptions.Item label="还款金额">
          {toThousands(data.amount)} 元
        </Descriptions.Item>

        <Descriptions.Item label="还款凭证">
          <FileViewer file={data.transfer_certificate} />
        </Descriptions.Item>
        <Descriptions.Item label="申请时间">
          {data.create_time}
        </Descriptions.Item>
        <Descriptions.Item label="逾期天数">
          {data.overdue_day ? <span>{data.overdue_day} 天</span> : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="逾期利率">
          {data.overdue_rate ? <span>{data.overdue_rate}%</span> : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="正常期利率">
          {data.normal_rate ? <span>{data.normal_rate}%</span> : "-"}
        </Descriptions.Item>

        <Descriptions.Item label="宽限期利率">
          {data.grace_rate ? <span>{data.grace_rate}%</span> : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="计息天数">
          {data.interest_day ? <span>{data.interest_day} 天</span> : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="业务审核备注">
          {data.memo ? data.memo : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="应还总金额">
          {data.total ? <span>{toThousands(data.total)} 元</span> : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="实际收款金额">
          {data.collection_amount ? (
            <span>{toThousands(data.collection_amount)} 元</span>
          ) : (
            "-"
          )}
        </Descriptions.Item>

        <Descriptions.Item label="收款时间">
          {data.collection_time ? data.collection_time : "-"}
        </Descriptions.Item>
      </Descriptions>
      <Divider>业务信息</Divider>
      <Descriptions>
        <Descriptions.Item label="保理融资款金额">
          {toThousands(busibess.quota)} 元
        </Descriptions.Item>
        <Descriptions.Item label="申请时间">
          {busibess.create_time}
        </Descriptions.Item>
        <Descriptions.Item label="放款时间">
          {busibess.billing_loan ? busibess.billing_loan.billing_time : "-"}
        </Descriptions.Item>

        <Descriptions.Item label="保理融资期限">
          {busibess.quota_deadline ? (
            <span>{busibess.quota_deadline} 天</span>
          ) : (
            "-"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="到期日">
          {moment(
            new Date(busibess.create_time).setDate(
              new Date(busibess.create_time).getDate() + busibess.quota_deadline
            )
          ).format("YYYY-MM-DD")}
        </Descriptions.Item>
        <Descriptions.Item label="">
          <a
            onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname: `/base/business/record/detail`,

                search: `id=${busibess.id}`,
              });
            }}
          >
            查看业务详情
          </a>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default RepaymentApplyDetail;
