import * as React from "react";
import { getApplyingContracts } from "@/requests/contract";
import { message, Button, Menu, Dropdown, Table } from "antd";
import { DownOutlined } from "@ant-design/icons";
import ApplyCreditContractModal from "../components/ApplyCreditContractModal";
import { useCtxState } from "@/store/App.store";
const ContractTypes = {
  submit_contract_approval: "综合授信合同",
} as any;
enum ContractApprovalStatus {
  "待业务员审核" = 0,
  "待业务主管审核" = 1,
  "业务员审核失败，申请已关闭" = 2,
  "待风控人员审核" = 3,
  "待业务员重新审核" = 4,
  "待总经理审核" = 5,
  "待风控主管重新审核" = 6,
  "待风控主管审核" = 7,
  "待业务主管重新审核" = 8,
  "待财务人员审核" = 9,
  "待风控人员重新审核" = 10,
  "审批通过" = 11,
  "待财务人员重新审核" = 12,
}

const ApplyingContracts = () => {
  const [applyCreditContractVisible, setApplyCreditContractVisible] =
    React.useState(false);
  const [{ userInfo }] = useCtxState();
  const [data, setData] = React.useState<ApplyingCreditContractItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) page = 1;
    if (!pageSize) pageSize = 10;
    const res = await getApplyingContracts({});
    if (res.code === 0) {
      console.log(res.data);
      setData(res.data);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  //申请合同菜单
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          const { credit_status, accreditation_sign } = userInfo;
          if (credit_status === 1 && accreditation_sign === 1) {
            setApplyCreditContractVisible(true);
          } else if (credit_status !== 1) {
            message.error(
              "您尚未完成综合授信，请先完成综合授信再申请综合授信合同"
            );
            return;
          } else if (accreditation_sign !== 1) {
            message.error("您尚未完成实名认证，请先完成实名认证");
            return;
          }
          setApplyCreditContractVisible(true);
        }}
      >
        综合保理合同
      </Menu.Item>
    </Menu>
  );

  React.useEffect(() => {
    setLoading(true);
    fetchData();
    console.log(userInfo);
  }, []);
  const colunms = [
    {
      title: "序号",
      dataIndex: "order",
      key: "order",
      align: "center" as "center",
      render: (text: any, record: ApplyingCreditContractItem, index: number) =>
        `${index + 1}`,
    },
    {
      title: "合同类型",
      dataIndex: "process_definition_key",
      key: "process_definition_key",
      align: "center" as "center",
      render: (text: any, record: ApplyingCreditContractItem, index: number) =>
        // `${ContractTypes[record.process_definition_key]}`,
        "综合授信合同",
    },
    {
      title: "申请时间",
      dataIndex: "create_time",
      key: "create_time",
      align: "center" as "center",
      render: (text: any, record: ApplyingCreditContractItem, index: number) =>
        `${record.create_time}`,
    },
    {
      title: "审核状态",
      dataIndex: "approval_status",
      key: "approval_status",
      align: "center" as "center",
      render: (text: any, record: ApplyingCreditContractItem, index: number) =>
        `${ContractApprovalStatus[record.approval_status]}`,
    },
  ];
  return (
    <div style={{ padding: 16, backgroundColor: "#ffffff" }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          marginBottom: 16,
        }}
      >
        <Dropdown overlay={menu}>
          <Button type="primary" icon={<DownOutlined />}>
            申请合同
          </Button>
        </Dropdown>
      </div>
      <Table
        dataSource={data}
        columns={colunms}
        loading={loading}
        pagination={{
          ...pagination,
          onChange: (page, pagesize) => {
            setPagination({ ...pagination, page: page });
            fetchData(page, pagesize);
          },
          showTotal: (total: number) => {
            return <span>{`共 ${total} 条数据`}</span>;
          },
          style: { marginRight: 16 },
          showQuickJumper: true,
        }}
      ></Table>
      <ApplyCreditContractModal
        visible={applyCreditContractVisible}
        setVisible={setApplyCreditContractVisible}
        fetchData={fetchData}
      />
    </div>
  );
};

export default ApplyingContracts;
