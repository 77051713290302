import * as React from "react";
import { message, List, Form, Button, DatePicker, Tag, Select } from "antd";
import { getRepaymentApplyList } from "@/requests/business";
import { RedoOutlined } from "@ant-design/icons";
import "../styles/index.less";
import { useHistory, useLocation } from "react-router";
import { toThousands } from "@/utils/string";

enum RepaymentType {
  "正常期还款" = 1,
  "逾期还款",
  "提前还款",
  "提前回购",
  "其他还款",
}

const StatusTag = ({ status }: { status: number }) => {
  switch (status) {
    case 17:
      return <Tag color={"cyan"}>审核中</Tag>;
    case 19:
      return <Tag color="red">审核失败</Tag>;
    case 22:
      return <Tag color={"magenta"}>已完成</Tag>;
    default:
      if (status > 16) {
        return <Tag color={"cyan"}>审核中</Tag>;
      } else {
        return <Tag color={"gold"}>申请中</Tag>;
      }
  }
};

const RepaymentRecord = () => {
  const [data, setData] = React.useState<RepaymentApplyListItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [filterType, setFilterType] = React.useState(0);
  const [renderData, setRenderData] = React.useState<RepaymentApplyListItem[]>(
    []
  );
  const location = useLocation();
  const history = useHistory();

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    setLoading(true);
    const res = await getRepaymentApplyList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });
    if (res.code === 0) {
      setData(res.data);
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (location.pathname === "/base/my-process/repayment-process") {
      fetchData();
    }
  }, [location]);

  React.useEffect(() => {
    if (filterType === 0) {
      setRenderData(data);
    }
    if (filterType === 1) {
      setRenderData(
        data.filter(
          (x) =>
            x.status === 17 ||
            x.status === 18 ||
            x.status === 20 ||
            x.status === 21 ||
            x.status === 24
        )
      );
    }
    if (filterType === 2) {
      setRenderData(data.filter((x) => x.status === 19));
    }
    if (filterType === 3) {
      setRenderData(data.filter((x) => x.status === 22));
    }
  }, [filterType]);

  React.useEffect(() => {
    if (filterType === 0) {
      setRenderData(data);
    }
    if (filterType === 1) {
      setRenderData(
        data.filter(
          (x) =>
            x.status === 17 ||
            x.status === 18 ||
            x.status === 20 ||
            x.status === 21 ||
            x.status === 24
        )
      );
    }
    if (filterType === 2) {
      setRenderData(data.filter((x) => x.status === 19));
    }
    if (filterType === 3) {
      setRenderData(data.filter((x) => x.status === 22));
    }
  }, [data]);
  React.useEffect(() => {
    setPagination({ ...pagination, total: renderData.length });
  }, [renderData]);

  return (
    <div className="repayment-record-page">
      <div className="tools-wrapper">
        <div className="repayment-record-filter-input-container">
          <Form layout="inline">
            <Form.Item label="办理时间" className="filter-item">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label="状态" className="filter-item">
              <Select
                value={filterType}
                onChange={(value) => {
                  setFilterType(value);
                }}
              >
                <Select.Option value={0}>全部</Select.Option>
                <Select.Option value={1}>申请中</Select.Option>
                <Select.Option value={2}>申请失败</Select.Option>

                <Select.Option value={3}>已完成</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </div>
        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            onClick={() => {
              fetchData();
            }}
            icon={<RedoOutlined />}
            style={{ marginRight: 16 }}
          >
            刷新
          </Button>
        </div>
      </div>
      <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
        <List
          dataSource={renderData}
          loading={loading}
          renderItem={(item) => {
            // const product = productList.find((x) => x.id === item.product_id);
            return (
              <List.Item
                key={item.repayment_id}
                actions={[
                  <StatusTag status={item.status}></StatusTag>,
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/base/my-process/repayment-process/detail`,
                        search: `id=${item.repayment_id}&business_id=${item.product_apply_id}`,
                      });
                    }}
                  >
                    详情
                  </a>,
                ]}
              >
                <List.Item.Meta
                  title={`还款金额：${toThousands(item.amount)}元`}
                  description={
                    <span>
                      <span style={{ paddingRight: 16 }}>
                        还款类型：{RepaymentType[item.type]}
                      </span>
                      <span>申请时间：{item.create_time}</span>
                    </span>
                  }
                />
              </List.Item>
            );
          }}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
            style: { marginRight: 16 },
            showTotal: (total) => <span>共{total}条数据</span>,
          }}
        />
      </div>
    </div>
  );
};

export default RepaymentRecord;
