import * as React from "react";
import { getContractList } from "@/requests/contract";
import { Table } from "antd";
import ContractPreviewer from "@/ui-components/ContractPreviewer";
//合同状态
enum ContractStatus {
  "未发起" = 1,
  "待签署",
  "签署完成",
  "拒绝签署",
}

const AllContracts = () => {
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [data, setData] = React.useState<ContractListItem[]>([]);
  const [loading, setLoading] = React.useState(false);

  const fetchData = async (page?: number, pageSize?: number) => {
    setLoading(true);
    if (!page) {
      page = 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    const res = await getContractList({
      limit: pageSize,
      current: page,
      // status: 3,
    });
    if (res.code === 0) {
      setData(res.data);
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ padding: 16, backgroundColor: "#ffffff" }}>
      <Table
        dataSource={data}
        columns={[
          {
            title: "序号",
            align: "center",
            render: (value, record, index) => index + 1,
          },
          {
            title: "合同名称",
            align: "center",
            render: (value, record, index) => record.template_name,
          },
          {
            title: "接收方",
            align: "center",
            render: (value, record, index) =>
              record.recipient
                ? JSON.parse(record.recipient)
                    .map((x: Recipient) =>
                      x.enterpriseName ? x.enterpriseName : x.name
                    )
                    .toString()
                : "无",
          },
          {
            title: "合同状态",
            align: "center",
            render: (value, record, index) => ContractStatus[record.status],
          },
          {
            title: "合同编号",
            align: "center",
            render: (value, record, index) =>
              record.contract_id ? record.contract_id : "无",
          },
          {
            title: "创建日期",
            align: "center",
            render: (value, record, index) => record.create_time,
          },
          {
            title: "操作",
            align: "center",
            render: (value, record, index) => (
              <span>
                {record.status === 3 && (
                  <ContractPreviewer contract_id={record.contract_id} />
                )}
                {record.status === 2 && <a style={{ paddingRight: 8 }}>签署</a>}
              </span>
            ),
          },
        ]}
        loading={loading}
        pagination={{
          ...pagination,
          onChange: (page, pagesize) => {
            setPagination({ ...pagination, page: page });
            fetchData(page, pagesize);
          },
          showTotal: (total: number) => {
            return <span>{`共 ${total} 条数据`}</span>;
          },
          style: { marginRight: 16 },
          showQuickJumper: true,
        }}
      ></Table>
    </div>
  );
};

export default AllContracts;
