import * as React from "react";
import { message } from "antd";

import { contractPreview } from "@/requests/contract";
import { newWin } from "@/utils/network";

interface PropsType {
  contract_id: string | undefined | null;
}

const ContractPreviewer = (props: PropsType) => {
  const { contract_id } = props;

  const preview = async (contract_id: string) => {
    message.loading("即将前往预览");
    const res = await contractPreview({
      page_parameter: {
        contractId: contract_id,
      },
      target_page: "permissionView",
    });
    if (res.code === 0) {
      newWin(res.data.value, res.data.value);
    } else {
      message.error("网络错误");
    }
  };

  if (contract_id === undefined || contract_id === null) return <span>无</span>;
  if (!contract_id) {
    return <span>无</span>;
  }
  return (
    <a
      style={{ paddingRight: 8 }}
      onClick={(e) => {
        e.preventDefault();
        preview(contract_id);
      }}
    >
      查看
    </a>
  );
};

export default ContractPreviewer;
