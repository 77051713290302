import * as React from "react";
import "./index.less";
import {
  Button,
  PageHeader,
  message,
  Spin,
  Tabs,
  Form,
  Input,
  Radio,
  Avatar,
  Upload,
  List,
  Modal,
} from "antd";
import { HomeOutlined, LeftOutlined } from "@ant-design/icons";
import {
  useHistory,
  withRouter,
  Route,
  RouteComponentProps,
} from "react-router-dom";
import {
  getUserInfo,
  updateUserInfo,
  modifyPassword,
  validateEmail,
} from "@/requests/user";

import UploadAvatarWidget from "@/ui-components/UploadAvatarWidget";

const { TabPane } = Tabs;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};

interface UserinfoFormValues {
  user_name: string;
  email: string;
  avatar: string;
}

interface ModifyPasswordFormValues {
  old_password: string;
  password: string;
  confirm: string;
}

const UserInfoCenter = (props: RouteComponentProps) => {
  const [infoForm] = Form.useForm();
  const history = useHistory();
  const [userInfo, setUserInfo] = React.useState<UserInfoItem | undefined>(
    undefined
  );
  const [fetching, setFetching] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [avatar, setAvatar] = React.useState<any>(null);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [updateEmailVisible, setUpdateEmailVisible] = React.useState(false);

  const [passwordForm] = Form.useForm();
  const [updateEmailForm] = Form.useForm();

  const data = [
    {
      title: "账户密码",
      description: "密码等级：强",
      actions: [
        <Button
          style={{ padding: "0 36px" }}
          onClick={() =>
            history.push("/userinfo-center/modify-password", {
              userInfo: userInfo,
            })
          }
        >
          修改
        </Button>,
      ],
    },
    {
      title: "手机号码",
      description: userInfo
        ? `已绑定手机：${
            userInfo.phone.slice(0, 3) + "****" + userInfo.phone.slice(7)
          }`
        : "未绑定手机号",
      actions: [
        <Button style={{ padding: "0 36px" }} onClick={(e) => {}} disabled>
          修改
        </Button>,
      ],
    },
    {
      title: "电子邮箱",
      description: userInfo
        ? `已绑定邮箱：${
            userInfo.email.slice(0, 3) + "****" + userInfo.email.slice(7)
          }`
        : "未绑定邮箱",
      actions: [
        <Button
          style={{ padding: "0 36px" }}
          onClick={(e) => {
            setUpdateEmailVisible(true);
          }}
        >
          修改
        </Button>,
      ],
    },
  ];

  const doFetch = async () => {
    const res = await getUserInfo();
    if (res.code === 0) {
      setUserInfo(res.data);
    } else if (res.code === 20001) {
      message.error("未登录");
      history.replace("/login");
    } else {
      message.error(res.message);
    }

    setLoading(false);
  };
  React.useEffect(() => {
    setLoading(true);
    doFetch();
  }, []);

  const doUpdateUserInfo = async (values: UserinfoFormValues) => {
    if (!userInfo) return;
    const res = await updateUserInfo({
      ...values,
    });
    if (res.code === 0) {
      message.success("修改成功");
      doFetch();
    } else {
      message.error(res.message);
    }
  };

  const handleSave = (values: UserinfoFormValues) => {
    if (!values.user_name) {
      message.error("用户名不能为空");
      return;
    }

    doUpdateUserInfo(values);
  };

  const doModifyPassword = async (input: {
    password: string;
    new_password: string;
  }) => {
    if (!input) return;

    setFetching(true);
    const res = await modifyPassword({
      ...input,
    });

    if (res.code === 0) {
      message.success("修改密码成功");

      setTimeout(() => {
        message.info("您的密码已修改，请重新登录");
        history.replace("/login");
      }, 1000);
    } else if (res.code === 400) {
      message.error(res.message);
    } else if (res.code === 20001) {
      message.error("登陆过时，请重新登录！");
      history.replace("/login");
    } else if (res.code === 20002) {
      message.error("原密码错误！");
    } else if (res.code === 10003) {
      message.error("验证码错误！");
    } else {
      message.info(res.message);
    }
  };

  const handleModifyPassword = (values: ModifyPasswordFormValues) => {
    const { old_password, password } = values;
    doModifyPassword({
      password: old_password,
      new_password: password,
    });
  };

  const doUpdateEmail = async (email: string) => {
    if (!userInfo) return;
    setFetching(true);
    const res = await updateUserInfo({
      email,
      avatar: userInfo.avatar,
      user_name: userInfo.user_name,
    });
    if (res.code === 0) {
      message.success("修改成功");
      setUpdateEmailVisible(false);
      doFetch();
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const handleEmailModalOk = (values: { email: string }) => {
    doUpdateEmail(values.email);
  };

  const UpdateEmailForm = () => {
    return (
      <Form form={updateEmailForm} onFinish={handleEmailModalOk}>
        <Form.Item
          name="email"
          label="电子邮箱"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "请输入您的电子邮箱",
            },
            {
              validator: async (_, value) => {
                if (!value) return;
                const res = await validateEmail({
                  email: value,
                });
                if (res.code === 0) {
                  return Promise.resolve();
                } else if (res.code === 20012) {
                  return Promise.reject("该邮箱已被注册！");
                }
              },
            },
            {
              type: "email",
              message: "请输入正确格式的邮箱",
            },
          ]}
        >
          <Input style={{ width: 300 }} placeholder="邮箱" />
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 24,
            }}
          >
            <Button
              type="primary"
              style={{ width: 130 }}
              htmlType={"submit"}
              loading={fetching}
            >
              确认
            </Button>
            <Button
              style={{ width: 130 }}
              onClick={() => setUpdateEmailVisible(false)}
            >
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div className="userinfo-center">
      <div className="header">
        <PageHeader
          title={<span className="header-title">账号设置</span>}
          extra={[
            <Button
              icon={<HomeOutlined />}
              onClick={() => {
                history.replace("/");
              }}
            >
              主页
            </Button>,
            <Button icon={<LeftOutlined />} onClick={() => history.goBack()}>
              返回
            </Button>,
          ]}
          style={{ paddingBottom: 40 }}
        />
      </div>
      <div className="content">
        <Route path="/userinfo-center" exact>
          {userInfo && (
            <div className="avatar">
              {userInfo.avatar ? (
                <Avatar
                  size={100}
                  src={`/api/user/avatar/${userInfo.avatar}`}
                />
              ) : (
                <Avatar size={100}>{userInfo.user_name}</Avatar>
              )}
            </div>
          )}
          <Spin
            spinning={loading}
            tip="加载中..."
            style={{ position: "absolute", left: "50%", top: "50%" }}
          />
          {avatar && (
            <UploadAvatarWidget
              setAvatar={setAvatar}
              setModalVisible={setModalVisible}
              visible={modalVisible}
              file={avatar}
              userInfo={userInfo as UserInfoItem}
              doFetch={doFetch}
            />
          )}
          <Modal
            visible={updateEmailVisible}
            title="修改邮箱"
            footer={null}
            onCancel={() => setUpdateEmailVisible(false)}
            bodyStyle={{ display: "flex", justifyContent: "center" }}
            destroyOnClose
          >
            <UpdateEmailForm />
          </Modal>

          <Tabs
            defaultActiveKey="1"
            style={{ marginLeft: 190 }}
            tabBarStyle={{ border: "none" }}
            tabBarGutter={48}
          >
            <TabPane tab="基本信息" key="1">
              <div className="base-info">
                <div className="avatar-uploader">
                  <Upload
                    action="v2/api/user/avatar"
                    showUploadList={false}
                    beforeUpload={async (file, fileList) => {
                      setAvatar(file);
                      setModalVisible(true);
                      return Promise.reject(file) as any;
                    }}
                  >
                    <Button style={{ width: 100 }}>修改头像</Button>
                  </Upload>
                </div>
                {userInfo && (
                  <Form
                    form={infoForm}
                    {...layout}
                    onFinish={handleSave}
                    colon={false}
                    initialValues={{
                      user_name: userInfo.user_name,
                      status: userInfo.status,
                      create_time: userInfo.create_time,
                    }}
                    style={{ width: 500, marginTop: 36 }}
                  >
                    <Form.Item name="user_name" label="用户名">
                      <Input />
                    </Form.Item>
                    <Form.Item name="status" label="状态">
                      <Radio.Group disabled>
                        <Radio value={1}>启用</Radio>
                        <Radio value={0}>禁用</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label="注册时间" name="create_time">
                      <Input disabled />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType={"submit"}
                        type="primary"
                        style={{ padding: "0 36px", marginTop: 24 }}
                      >
                        保存
                      </Button>
                    </Form.Item>
                  </Form>
                )}
              </div>
            </TabPane>
            <TabPane tab="安全设置" key="2">
              <List
                itemLayout="horizontal"
                dataSource={data}
                style={{ width: 650, padding: "24px 0" }}
                renderItem={(item, index) => (
                  <List.Item actions={item.actions} key={index}>
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
            </TabPane>
          </Tabs>
        </Route>
        <Route path="/userinfo-center/modify-password">
          {userInfo && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "90px 0",
              }}
            >
              <h2 style={{ marginBottom: 36 }}>修改密码</h2>
              <Form
                form={passwordForm}
                onFinish={handleModifyPassword}
                style={{ width: 300 }}
              >
                <Form.Item
                  name="old_password"
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: "请输入您的原密码！" }]}
                >
                  <Input.Password style={{ width: 300 }} placeholder="原密码" />
                </Form.Item>

                <Form.Item
                  name="password"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: "请输入您的密码!",
                    },
                  ]}
                >
                  <Input.Password style={{ width: 300 }} placeholder="新密码" />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  validateTrigger="onBlur"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "请确认您的密码!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("两次输入密码不一致!");
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    style={{ width: 300 }}
                    placeholder="确认新密码"
                  />
                </Form.Item>

                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: 36,
                    }}
                  >
                    <Button
                      type="primary"
                      style={{ width: 130 }}
                      htmlType={"submit"}
                      loading={fetching}
                    >
                      确认
                    </Button>
                    <Button
                      style={{ width: 130 }}
                      onClick={() => history.goBack()}
                    >
                      取消
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          )}
        </Route>
      </div>
    </div>
  );
};

export default withRouter(UserInfoCenter);
