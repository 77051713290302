import * as React from "react";
import {
  Spin,
  Empty,
  Button,
  Descriptions,
  Layout,
  Result,
  message,
  Upload,
  Tooltip,
  Modal,
} from "antd";
import {
  getCompanyInfo,
  updateCharter,
  updateOpeningPermit,
  UPLOAD_ACTION,
} from "@/requests/company";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import FileViewer from "@/ui-components/FileViewer";
import { useCtxState } from "@/store/App.store";

const CompanyInfo = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [companyInfo, setCompanyInfo] = React.useState<
    CompanyInfoItem | undefined
  >(undefined);
  const [isProcess, setIsProcess] = React.useState(false);
  const [isBind, setIsBind] = React.useState(true);
  const location = useLocation();
  const [{ userInfo }, dispatch] = useCtxState();
  //获取企业信息
  const fetchCompanyInfo = async () => {
    const res = await getCompanyInfo();
    if (res.code === 0) {
      if (res.data !== null) {
        setCompanyInfo(res.data);
      }
    } else if (res.code === 13006) {
      setIsProcess(true);
    } else if (res.code === 13007) {
      setIsBind(false);
    }
    setLoading(false);
  };

  const goBindPage = () => {
    history.push("/base/my-company/bind-apply");
  };
  //提交章程
  const subumitCharter = async (file: string) => {
    if (!companyInfo) return;
    message.loading("正在提交");
    const res = await updateCharter({
      id: companyInfo.id,
      charter: file,
    });
    if (res.code === 0) {
      message.success("提交申请成功，请等待后台人员审核");
    } else {
      message.error(res.message);
    }
    message.destroy();
  };
  //提交开户许可证
  const submitOpeningPermit = async (file: string) => {
    if (!companyInfo) return;
    message.loading("正在提交");
    const res = await updateOpeningPermit({
      id: companyInfo.id,
      opening_permit: file,
    });
    if (res.code === 0) {
      message.success("提交申请成功，请等待后台人员审核");
    } else {
      message.error(res.message);
    }
  };

  React.useEffect(() => {
    if (location.pathname === "/base/my-company/company-info") {
      setLoading(true);
      fetchCompanyInfo();
    }
    const { accreditation_sign, binding_company_status } = userInfo;
    if (
      accreditation_sign === 0 &&
      binding_company_status === 1 &&
      location.pathname !== "/base/my-company/identification"
    ) {
      Modal.confirm({
        title: "提示",
        content:
          "根据《e保理注册协议》以及国家法律规定，您必需进行企业实名认证才能进行相关后续操作，请尽快完成企业实名认证",
        okText: "立即前往",
        cancelText: "以后再说",
        onCancel: () => {},
        cancelButtonProps: { style: { display: "none" } },
        onOk: () => {
          history.push("/base/my-company/identification");
        },
      });
    }
  }, [location]);
  return (
    <Layout>
      <Layout.Content>
        <Spin
          tip="加载中"
          spinning={loading}
          style={{ position: "absolute", top: "50%", left: "50%" }}
        ></Spin>

        {isProcess && (
          <div style={{ backgroundColor: "#ffffff" }}>
            <Result
              status="info"
              title="审核中"
              subTitle="您的企业信息资料正在审核中"
              extra={[
                <Button
                  key="view"
                  type="primary"
                  onClick={() =>
                    history.push({
                      pathname: "/base/my-process/company-info-process",
                    })
                  }
                >
                  点击查看
                </Button>,
              ]}
            />
          </div>
        )}
        {companyInfo && (
          <div
            style={{
              backgroundColor: "#ffffff",
              marginBottom: 16,
              padding: 16,
            }}
          >
            <Descriptions
              title="基础信息"
              bordered
              extra={[
                <Button
                  type="primary"
                  onClick={() => {
                    history.push({
                      pathname: "/base/my-company/submit-company-info",
                    });
                  }}
                >
                  申请变更
                </Button>,
              ]}
            >
              <Descriptions.Item label="企业名称" span={3}>
                {companyInfo.company_name}
              </Descriptions.Item>
              <Descriptions.Item label="企业电话">
                {companyInfo.company_phone}
              </Descriptions.Item>
              <Descriptions.Item label="社会统一信用代码">
                {companyInfo.uniform_social_credit_code}
              </Descriptions.Item>
              <Descriptions.Item label="行业类型" span={2}>
                {companyInfo.industry_type === " "
                  ? "无"
                  : companyInfo.industry_type}
              </Descriptions.Item>

              <Descriptions.Item label="注册地址" span={3}>
                {companyInfo.register_address}
              </Descriptions.Item>
              <Descriptions.Item label="联系地址" span={3}>
                {companyInfo.contact_address}
              </Descriptions.Item>

              <Descriptions.Item label="营业执照" span={3}>
                <FileViewer file={companyInfo.business_license_pic} />
              </Descriptions.Item>
              <Descriptions.Item label="企业征信" span={3}>
                <FileViewer file={companyInfo.company_credit} />
              </Descriptions.Item>
              <Descriptions.Item label="企业简介">
                <FileViewer file={companyInfo.company_profile} />
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}
        {companyInfo && (
          <div
            style={{
              backgroundColor: "#ffffff",
              marginBottom: 16,
              padding: 16,
            }}
          >
            <Descriptions
              title={
                <span>
                  章程
                  <Tooltip title="章程为申请综合授信时必备资料，请及时更新上传您的企业最新章程，支持文件格式为：pdf、jpg、png、word文档等">
                    <QuestionCircleTwoTone style={{ paddingLeft: 5 }} />
                  </Tooltip>
                </span>
              }
              extra={[
                <Upload
                  action={UPLOAD_ACTION}
                  onChange={({ file, fileList }) => {
                    if (file.status === "done") {
                      subumitCharter(file.response.data);
                    }
                  }}
                >
                  <Button type="primary">申请变更</Button>
                </Upload>,
              ]}
            >
              <Descriptions.Item label="企业章程">
                <FileViewer file={companyInfo.charter} />
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}
        {companyInfo && (
          <div
            style={{
              backgroundColor: "#ffffff",
              marginBottom: 16,
              padding: 16,
            }}
          >
            <Descriptions
              title="开户许可证"
              extra={[
                <Upload
                  action={UPLOAD_ACTION}
                  onChange={({ file, fileList }) => {
                    if (file.status === "done") {
                      submitOpeningPermit(file.response.data);
                    }
                  }}
                >
                  <Button type="primary">申请变更</Button>
                </Upload>,
              ]}
            >
              <Descriptions.Item label="开户许可证">
                <FileViewer file={companyInfo.opening_permit} />
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}
        {!isBind && !isProcess && (
          <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
            <Empty description={<span>您还未绑定公司信息</span>}>
              <Button type="primary" onClick={goBindPage}>
                立即绑定
              </Button>
            </Empty>
          </div>
        )}
      </Layout.Content>
    </Layout>
  );
};
export default CompanyInfo;
