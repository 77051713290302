export const USER_REGISTER_PROTOCOL = <div>
<p><strong>特别提示:</strong> 尊敬的用户，欢迎您使用兰青e保理平台服务！本协议是您(用户)与深圳前海兰青商业保理有限公司(以下统称:兰青保理)之间就【兰青e保理】平台(网址:ebaoli.lbaoli,com，以下简称“兰青保理平台”)所提供的各项服务相关事宜所订立的契约。
在使用平台服务之前，请您务必审慎阅读、充分理解本协议各项条款，特别是限制或免除责任条款、隐私保护政策、帐号规则、法律适用和争议解决条款（包括管辖条款），以及其它以加粗加黑和/或加下划线等显示形式提示您注意的重要条款，请务必重点查阅。
若您不同意本协议，则您有充分且完全的权利退出使用平台服务。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您通过网络页面点击注册和/或实际使用平台服务的行为即视为您已阅读、理解并同意接受本协议。 如果您对本协议有任何的疑问、投诉、意见和建议，欢迎您通过本协议所附联系方式与我们沟通反馈，我们十分乐意为您解答。</p>
<h4>一、用户注册及服务对象</h4>
1.1 您在使用兰青保理提供的服务前需要注册一个平台服务账号。服务账号用手机号码绑定注册，请您使用尚未与兰青保理平台服务账号绑定的手机号码进行注册。<br/>
1.2 鉴于兰青保理平台服务账号的绑定注册方式，您同意并知悉兰青保理在注册时将自动提取您用于注册平台账户的手机号码等信息用于注册。<br/>
1.3 您以《授权委托书》方式指定管理人员，代表您进行注册、认证及其他系统操作(包括但不限于提交用户身份认证信息和材料、填写并变更管理员信息；签署平台其他协议；管理服务账户；上传业务材料；平台业务操作；查看企业平台信息等)。为了便于识别您的身份或为您提供更好的使用体验，您同意，在您注册及使用本服务过程中向兰青保理提供管理员与您的信息，信息包括但不限于管理人员的姓名、身份证号、身份证、地址、用户向管理人员出具的《授权委托书》、 用户企业名称、营业执照、统一社会信用代码、通讯地址、法定代表人姓名及其电话、法定代表人身份证及身份证明、机构信用代码、银行开户许可证等；兰青保理承诺对这些信息的使用将受限于隐私政策的约束。您必须保证提交的上述资料是真实、最新、有效及完整的，否则可能无法使用平台服务，或在使用过程中受到限制，甚至影响您是否能够使用平台特定功能。对于因您提供的信息不真实、不准确或不完整导致的责任和损失由您承担。。
平台有权根据您所提供资料的真实性、准确性、完整性以及是否符合平台服务规定的其他条件，决定是否为您提供服务。您在使用平台服务过程中，所提供的信息发生变动，应及时进行变更。<br/>
1.4 您完成注册后获得的兰青保理平台服务账号，可以作为平台登录凭证。<br/>
1.5 您保证，在您同意接受本协议并注册成为用户时，您是在中国大陆地区合法开展经营活动、具有完全民事权利和民事行为能力，能够独立承担民事责任的企业法人；本协议内容不受您所属国家或地区法律的排斥。如不符合本项条件，请勿使用“服务”。兰青保理可随时自行全权决定拒绝向任何服务对象提供“服务”。“服务”不会提供给被暂时或永久中止资格的会员。<br/>
1.6 您同意并知悉，若您丧失全部或部分民事权利能力或民事行为能力，兰青保理有权根据有效法律文书(包括但不限于生效的法院判决、生效的遗嘱等) 或其认可的其他资料处置您保理平台账户内的资料。<br/>
1.7 您应及时更新本协议第1.3条约定的资料，否则兰青保理有权将登录名、账户绑定的邮箱、手机号码开放给其他用户注册或使用。因您未及时更新资料导致的一切后果，均应由您自行承担，该后果包括但不限于导致本服务无法提供或提供时发生任何错误、账户及账户内信息被别人盗用，且您不得将此作为取消交易、拒绝执行协议的理由。<br/>
1.8 您有责任自行负责保管帐号的用户名和密码等信息，否则因该等事项引发的法律责任由用户自行承担。凡使用平台服务登录帐号和登录凭证的行为，平台服务视为您本人的操作，操作所产生的电子信息记录均为平台服务用户行为的有效凭据。<br/>

<h4>二、用户服务条款的确认</h4>
2.1 您同意并知悉，兰青保理可以根据您的需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知您。<br/>
2.2 您理解并知悉，兰青保理有权遵照法律法规规定，在必要时修改本协议（包括适时制定并发布其他政策、规则、公告声明），更新后的协议条款将代替原来的协议并在法律规定的期限届满后正式生效，您可以在相关服务页面查阅最新版本的协议条款，无须另行通知予您。本协议修改后，如果您继续使用平台服务的，即视为您已接受修改后的协议。如果您不接受修改后的协议，则您有权利停止使用平台服务。在法律允许的最大限度范围内，兰青保理对本协议及兰青保理平台的内容拥有最终解释权。<br/>
2.3 考虑到互联网服务以及产品频繁迭代更新等行业特点，为了更全面的界定您与平台之间的权利义务，本协议包括我们根据法律法规规定以及本协议制定的其他政策、规则、公告声明等（除非特有所指，合称为“本协议”），您也应当加以遵守。<br/>

<h4>三、服务使用规范</h4>
3.1 您承诺在使用兰青保理平台服务过程中实施的所有行为均应遵守国家法律、法规等规范性文件及兰青保理的各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不违反本协议及相关规则。您如果违反前述承诺，产生任何法律后果的， 应以自己的名义独立承担所有的法律责任，并确保兰青保理免于因此产生任何损失。<br/>
3.2 行为禁止：您可在本协议约定的范围内使用平台和服务，您不得利用平台从事以下行为：<br/>
(1)不对兰青保理平台上的任何数据作商业性利用，包括但不限于在未经兰青保理事先书面同意的情况下，以复制、传播等任何方式使用兰青保理平台上展示的资料；<br/>
(2)超出授权或恶意使用平台服务；<br/>
(3)恶意虚构或协助虚构事实、评价等信息或数据；<br/>
(4)进行任何危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/账户；未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；未经许可，企图探查、扫描、测试本“软件”系统或网络的弱点或其它实施破坏网络安全的行为；企图干涉、破坏本“软件”系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称；利用平台及服务上传任何病毒、木马，或者蠕虫等危害网络健康的内容；<br/>
(5)对平台进行反向工程、反向编译或反向汇编或以其它方式企图发现平台的源代码和算法，未经许可修改、禁用软件的任何功能或创建基于软件的衍生作品。去除平台或文档上的任何所有权声明或标签，或将其他软件与平台合并；<br/>
(6)进行任何破坏我们提供服务公平性或者其他影响应用正常秩序的行为，如主动或被动刷分、合伙作弊、使用外挂或者其他的作弊软件、利用BUG（又叫“漏洞”或者“缺陷”）来获得不正当的非法利益，或者利用互联网或其他方式将外挂、作弊软件、BUG公之于众；<br/>
(7)从事其他法律法规、政策及公序良俗、社会公德禁止的行为以及侵犯其他个人、公司、社会团体、组织的合法权益的行为，包括但不限于：反对宪法所确定的基本原则，危害国家安全、泄漏国家秘密、颠覆国家政权、破坏国家统一的；损害国家荣誉和利益的；宣扬恐怖主义、极端主义的；宣扬民族仇恨、民族歧视、破坏民族团结的；煽动地域歧视、地域仇恨的；破坏国家宗教政策的，宣扬邪教和迷信的；编造、散布谣言、虚假信息，扰乱经济秩序和社会秩序、破坏社会稳定的；侮辱或诽谤他人，损害他人合法利益或权益的；泄露他人隐私、个人信息或资料的；侵犯他人名誉权、肖像权、知识产权等合法权益的；散布商业广告或类似商业招揽信息、过度营销信息及垃圾信息的；<br/>
(8)利用平台进行洗钱、赌博、传销或其他金融违规、犯罪等违法行为的；<br/>
(9)利用平台或平台提供的服务从事与平台交易无关的行为；<br/>
(10)恶意使用技术手段或其他手段攻击平台或平台其他用户的；<br/>
(11)其他兰青保理可以合理判断的不当行为。<br/>

3.3 对于您在兰青保理平台上发布的涉嫌违法或涉嫌侵犯他人合法权利或违反本协议和/或规则的信息，兰青保理有权不经通知即予以删除，且按规定对您进行处罚。<br/>
3.4 如您涉嫌违反有关法律或者本协议之规定，使兰青保理遭受任何损失，或受到任何第三方的索赔，或受到任何行政管理部门的处罚，您应当赔偿兰青保理因此造成的损失及(或)发生的费用，包括但不限于诉讼费、仲裁费、保全担保费、鉴定费、鉴定费、保全费、担保费、律师费、公证费、差旅费等。<br/>
3.5 您同意授权兰青保理平台对您提交的信息进行数据收集的权利。我们承诺，除非获得用户同意，平台不会收集、使用其提供服务所必需以外的用户个人信息或者将信息用于提供服务之外的目的。<br/>
<h4>四、知识产权条款</h4>
4.1 我们对平台中所包含的受知识产权或其他法律保护的资料享有相应的权利；除依法由用户享有著作权的内容之外，平台的整体内容的知识产权归我们或关联公司所有。<br/>
4.2 您在使用我们服务时利用平台及服务所发表上传的文字、图片、视频、软件以及表演等原创信息的知识产权归您所有（或由第三方内容提供方和您另行约定），但是基于平台运营的需求，您确认您对该等信息的发表、上传行为视同为对我们授权知识产权的使用、复制、传播等权利。<br/>
4.3 基于对数据的合法加工而获得的具有竞争性的数据权益，除法律法规另有规定外，我们享有独立的使用权益而无须获得您的同意。<br/>
4.4 您保证所提供的资料及数据信息将授予兰青保理许可使用权利(并有权在 多个层面对该权利进行再授权)。<br/>
4.5 您理解，兰青保理平台所刊登的资料信息(诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件)，均是兰青保理或其内容提供者的财产，受中国和国际版权法的保护。兰青保理平台上所有内容的汇编是兰青保理的排他财产，受中国和国际版权法的保护。未经兰青保理明确的书面许可，您不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本平台的信息内容，否则，兰青保理有权追究您的法律责任。<br/>
<h4>五、账号管理</h4>
5.1 您注册成功后，我们将为您配置帐号，该帐号之所有权归平台所有，用户在本协议有效期内仅享有使用权。未经平台同意，用户的帐号仅供用户本人使用，帐号信息和权限不得以转让、赠与、借用、售卖、授权或租用等方式许可给第三方使用或和第三方共同控制、使用。否则，您应承担由此产生的全部责任，并与实际使用人承担连带责任。<br/>
5.2 您应对注册账号信息、账号密码、身份信息进行妥善保管，因密码、身份信息、校验码等泄露所致的损失由您自行承担。 您同意在任何情况下不使用其他用户的账号或密码。如您发现有他人冒用或盗用您的登录名及密码或任何其他未经合法授权之情形，或发生与兰青保理平台账户关联的手机或其他设备遗失或其他可能危及到账户安全时，应立即以有效方式通知兰青保理，向兰青保理申请 暂停相关服务，以保障您的合法权益。<br/>
5.3 您应当妥善保管处于您或应当处于您掌控下的这些产品或设备，对于这些产品或设备遗失所致的任何损失，由您自行承担。<br/>
5.4 兰青保理通过您的账户及密码来识别您的指令，您确认，使用用户账户和密码登录后在平台的一切行为均代表用户本人。账户操作所产生的电子信息记录均为您行为的有效凭据，并由您承担由此产生的全部责任。<br/>
5.5 您理解并同意，如(a)您连续一年未登录您的保理账户；或(b)您在兰青保理平台上有欺诈、虚假交易、侵犯他人合法权益或其他严重违反兰青保理平台管理规则及法律法规行为的，兰青保理有权注销您名下账号，由此造成的不利后果由您自行承担。<br/>
5.6 如您选择停止使用兰青保理平台服务并申请注销的，兰青保理有权从服务器上永久性地删除您的数据并不再予以恢复。<br/>
5.7 兰青保理有权基于单方独立判断，在其认为可能发生危害交易安全等情形时，不经通知而先行暂停、中断或终止向您提供本协议项下的全部或部分用户服务，并将注册资料移除或删除，且无需对您或任何第三方承担任何责任，包括但不限于:<br/>
(1) 兰青保理认为您提供的资料不具有真实性、有效性或完整性，包括但不限于盗用他人证件信息注册、认证信息不匹配等；<br/>
(2) 兰青保理发现异常交易或有疑义或有违法之虞时；<br/>
(3) 兰青保理认为用户账户涉嫌洗钱、套现、传销、被冒用或其他兰青保理认为有风险之情形； <br/>
(4)兰青保理发现用户使用非法或不正当的技术手段进行危害交易安全或影响公平交易的行为，包括但不限于非法侵入他人网络、干扰他人网络正常功能、窃取网络数据、提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全的程序、工具、使用未经许可的数据或进入未经许可的服务器或账户、未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息、未经许可企图探查、扫描平台系统或网络的弱点、企图干扰平台 或系统的正常运营，传播恶意程序或病毒、对平台软件及系统进行反向工程、反向编译、修改交易记录、窃取平台用户信息等； <br/>
（5）兰青保理发现用户实施本协议项下其他禁止行为。 <br/>
5.8 您同意，账户的暂停、中断或终止不代表您责任的终止，您仍应对适用平台服务期间的行为承担可能的违约或损害赔偿责任。 <br/>
<h4>六、用户承诺</h4>
6.1 您作为合法成立并有效存续的主体，已为签署本协议履行了必要的批准、核准或备案手续，并能够履行在本协议项下的义务。 <br/>
6.2 您的注册信息真实有效；通过兰青保理平台上传或发布材料、信息均真实、有效、完整、详细、准确，通过兰青保理平台签署的任何电子合同均有效，您在兰青保理平台上的操作都视为您的真实、完整、有效的意思表示。 <br/>
6.4 您对在兰青保理平台上提供的信息、材料，从事交易活动产生的所有后果承担责任。 <br/>
6.4 您已对管理人员出具了充分的授权，管理人员在兰青保理平台上的所有操作视为您的真实、完整、有效的意思表示，对于管理人员在兰青保理平台上的所有操作，您承诺均无条件、不可撤销的认可并承担所有相关权利、义务及法律责任。  <br/>
6.5 您了解并同意，兰青保理平台及平台的董事、员工、代理人均不以任何明示 或默示的方式对所有平台用户通过平台形成的交易及其履行提供任何担保。您应基于自己的判断决定是否与其他用户进行交易，并自行承担后果。兰青保理平台在此提示，平台已经充分地向您陈述此类责任承担问题，您注册并使用本平台的行为，表明您已知晓并接受平台关于此类责任的划分。 <br/>
<h4>七、责任范围和责任限制</h4>
7.1 您了解并同意，兰青保理不对因下述任一情况而导致的任何损害赔偿承担责任，包括但不限于利润、商誉、数据等方面的损失或其它无形损失的损害赔偿： <br/>
(1)兰青保理不保证平台用户上传信息的真实性、充分性、可靠性、准确性、完整性和有效性，用户应依赖于其独立判断进行交易，并对其做出的判断承担全部责任。  <br/>
(2)对于用户在任何情况下，使用平台过程中涉及由第三方(包括电子身份认证、电子签章服务)提供相关服务的责任由该第三方承担，兰青保理不承担该责任。  <br/>
(3)对于因用户未按照本协议或平台的业务规则和指引进行操作等自身原因导致的任何损失或责任，兰青保理不承担责任。 <br/> 
(4)兰青保理平台的内容可能涉及由第三方所有、控制或者运营的其他网站(“第三方网站”)。如平台设置进入第三方网站的链接，用户应按照第三方网站的使用协议确定相关权利义务，兰青保理不承担因用户使用该等链接而引起的任何责任。  <br/>
(5)不可抗力免责基于互联网的特殊性，兰青保理不保证兰青保理平台服务不会中断，也不保证平台服务的及时性和安全性。平台系统因相关状况无法正常运作，导致用户无法使用任何平台服务或在使用任何平台服务时受到任何影响时，兰青保理对用户或第三方不负任何责任，包括但不限于: <br/>(a) 兰青保理或平台系统停机维护、升级期间； <br/>(b) 用户或兰青保理的计算机软件、系统、硬件和通信线路出现故障； <br/>(c) 用户操作不当或用户通过非兰青保理授权的方式使用平台服务； <br/>(d) 平台系统、程序版本过时、设备老化或存在兼容性问题； <br/>(e) 电信基础设施、电信传播设施出现故障导致平台服务中断或延迟； <br/>(f) 由于黑客攻击、计算机病毒、木马、恶意程序影响或网络供应商技术调整或故障、平台升级、银行或政府有权机构的问题等原因而造成的平台服务中断或延迟； <br/>(g) 因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成平台系统障碍不能执行业务的； <br/>(h) 因国家法规调整、政策调整或主管监督机构要求平台终止或暂停服务的； <br/>(i) 其他兰青保理无法控制或合理遇见的情况。 <br/> 
(6)在任何情况下，兰青保理对于与本协议有关或由本协议引起的任何间接的、惩罚性的、特殊的、派生的损失(包括业务损失、收益损失、利润损失、使用数据或其他经济利益的损失)，均不负任何责任。  <br/>
(7)在任何情况下，兰青保理对于平台参与方的赔偿责任的上限不超过兰青保理已累计在特定平台参与方收取的费用(如有)。 <br/>
<h4>八、隐私保护和授权</h4>
8.1 兰青保理平台对于用户提供的、平台自行收集的、经注册的用户信息将按照本协议予以保护、使用或者披露。为了用户享受更多兰青保理的产品和服务，兰青保理有权向第三方转让与兰青保理平台有关的全部或部分权利和义务，但第三方应提供书面文件承诺遵循本协议项下隐私保护政策。未经兰青保理事先书面同意，用户不得转让其在本协议项下的任何权利和义务。 <br/>
8.2 为更好的为用户提供平台服务、保障平台交易的安全性，用户授权同意兰青保理有权向包括运营商、合作银行、存证机构、电子认证服务机构等第三方机构进行上述目的范围内的信息交换、验证。 <br/>
8.3 用户授权兰青保理有权自公开及私人资料来源收集用户的额外资料，以更好地掌握用户情况，并为用户度身定造平台服务、助力用户在平台进行安全交易以及解决争议。 <br/>
8.4 为更好的提供平台服务，兰青保理有权按照用户在平台上的行为自动追踪关于用户的资料(包括但不限于注册资料、交易行为数据及全部展示于兰青保理平台的各类信息)。在不透露用户的隐私资料的前提下，兰青保理有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。 <br/>
8.5 用户同意，兰青保理可在兰青保理平台的某些网页上使用诸如“Cookies”的资料收集装置。 <br/>
8.6 用户同意兰青保理可使用关于用户的相关资料(包括但不限于兰青保理持有的有关用户的档案中的资料，兰青保理从用户目前及以前在平台上的活动所获取的其他资料以及兰青保理通过其他方式自行收集的资料)以解决争议、对纠纷进行调停。为建设更好的平台生态和秩序，用户同意兰青保理可通过人工或自动程 序对用户的资料进行评价或评级。 <br/>
8.7 兰青保理采用行业标准惯例以保护用户的资料。用户因履行本协议提供给兰青保理的信息，兰青保理不会出售或免费共享给任何第三方，但以下情况除外: 
(a) 提供独立服务且仅要求服务相关的必要信息的供应商，如印刷厂、邮递公司、电子签名服务机构、运营商、存证公司等； <br/>
(b) 具有合法调阅信息权限并从合法渠道调阅信息的政府部门或其他机构，如公安机关、法院； <br/>
(c) 兰青保理的关联方基于更好的提高平台服务质量目的； <br/>
(d) 经平台使用方或平台使用方授权代表同意的第三方； <br/>
(e) 为完成兰青保理合并、分立、收购或资产转让而转移； <br/>
(f) 用户自行向其他公司、组织和个人共享、转让、公开的； <br/>
(g) 涉及国家安全、国防安全、公共安全、公共卫生、公共利益直接相关，不主动汇报给行政机关可能导致公众利益受损的。 <br/>
(h) 根据法律法规、部门规章、行政指导文件的要求或用户另外进行授权的。  <br/>
8.8 兰青保理可能会通过与第三方进行合作来为用户提供相关服务，在此情况下，如该第三方同意承担与兰青保理同等的保护用户隐私的责任，则视为用户授权兰青保理可将用户的个人信息和资料提供给该第三方。 <br/>
8.9 用户同意并授权兰青保理，在用户未能按照与兰青保理签订的协议(包括但不限于本协议)，或者与其他用户签订的协议履行自己应尽的义务，兰青保理有权对用户提供的及兰青保理自行收集的用户的个人信息和资料编辑入平台黑名单，并将该等个人信息和资料以及相关违约事实通过包括但不限于网络、报刊、电视等方式对任何第三方披露，或与任何第三方进行数据共享，由此可能给用户造成的任何损失，兰青保理不承担法律责任。兰青保理有权对用户提供的及兰青保理自行收集的用户的个人信息和资料编辑入平台黑名单，并将该等个人信息和资料以及相关违约事实通过包括但不限于网络、报刊、电视等方式对任何第三 方披露，或与任何第三方进行数据共享，由此可能给用户造成的任何损失，兰青保理不承担法律责任。 <br/>
8.10 用户不得在兰青保理平台提供的交易活动中，要求兰青保理或平台提供其他用户的资料，法律另有规定的除外。 <br/>
8.11 用户授权兰青保理或平台合作资金机构通过中国人民银行企业信用信息基础数据库查询用户信用报告，用于该平台涉及的供应链金融业务的调查、审查、审批、授信后管理及清收工作。
<h4>第九条 法律适用、管辖与其他 </h4>
9.1 本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律(但不包括其冲突法规则)。如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。 <br/>
9.2 如您与兰青保理就本协议内容或其执行发生任何争议，可与兰青保理协商解决，或向兰青保理经营地人民法院提起诉讼或仲裁。 <br/>
<h4>第十条 协议生效及通知</h4>
10.1 本协议自您以勾选方式签署之日起生效。 <br/>
</div>
