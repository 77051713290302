import * as React from "react";
import { bindESign, submitInfo, getAuthInfo } from "@/requests/bestSign";
import { getCompanyInfo } from "@/requests/company";
import { Button, Empty, message, Modal, Result, Form } from "antd";
import { useCtxState } from "@/store/App.store";
import { SafetyCertificateFilled } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router";

const IdentificationInfo = () => {
  const history = useHistory();
  const location = useLocation();

  const [hasAuthed, setHasAuthed] = React.useState(false);
  const [{ userInfo }, dispatch] = useCtxState();
  const [company, setCompany] = React.useState("");
  const [isProcess, setIsProcess] = React.useState(false);
  const [isBind, setIsBind] = React.useState(true);

  const fetchAuthInfo = async () => {
    const res = await getAuthInfo();
    if (res.code === 0) {
      setCompany(res.data.entName);
    } else {
      message.error(res.message);
    }
  };
  const newWin = (url: string, id: string) => {
    let a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("target", "_blank");
    a.setAttribute("id", id);
    // 防止反复添加
    if (!document.getElementById(id)) {
      document.body.appendChild(a);
    }
    a.click();
  };

  const doBind = async () => {
    message.loading(
      "正在提交资料，可能需要较长时间，请耐心等待，不要进行任何操作",
      0
    );
    const submitRes = await submitInfo({});
    if (submitRes.code === 0) {
      message.destroy();

      const bindRes = await bindESign({
        return_url: "",
      });
      if (bindRes.code === 0) {
        message.loading("即将跳转第三方页面");
        newWin(bindRes.data.value, bindRes.data.value);
      } else {
        message.error(bindRes.message);
      }
    } else {
      message.destroy();
      message.error(submitRes.message);
    }
  };

  const gobind = () => {
    Modal.confirm({
      title: "认证提示",
      content:
        "实名认证需跳转第三方服务，系统将自动上传您的企业资料至第三方平台以完成验证",
      okText: "确认前往",
      cancelText: "取消",
      onOk: doBind,
    });
  };

  const fetchCompanyInfo = async () => {
    const res = await getCompanyInfo();
    if (res.code === 0) {
    } else if (res.code === 13006) {
      setIsProcess(true);
    } else if (res.code === 13007) {
      setIsBind(false);
    }
  };

  const goBindPage = () => {
    history.push("/base/my-company/bind-apply");
  };

  React.useEffect(() => {
    console.log(userInfo);
    if (userInfo.accreditation_sign === 1) {
      fetchAuthInfo();
    }

    fetchCompanyInfo();
    setHasAuthed(userInfo.accreditation_sign === 1 ? true : false);
  }, []);

  React.useEffect(() => {
    if (
      userInfo.accreditation_sign === 1 &&
      userInfo.legal_person_auth === 0 &&
      location.pathname !== "/base/contract/signing-contracts"
    ) {
      Modal.confirm({
        title: "提示",
        content:
          "您还未签署法人授权书，根据《e保理注册协议》及相关法律规定，您的账号必须签署法人授权书方可进行相关操作，请及时完成签署",
        okText: "前往签署",
        cancelText: "以后再说",
        onOk: () => history.push("/base/contract/signing-contracts"),
        onCancel: () => {},
        cancelButtonProps: { style: { display: "none" } },
      });
    }
  }, [location]);
  return (
    <div style={{ padding: 16, backgroundColor: "#ffffff" }}>
      {!!!hasAuthed && isBind && !isProcess && (
        <Empty description={"您尚未完成实名认证"}>
          <Button type="primary" onClick={gobind}>
            立即认证
          </Button>
        </Empty>
      )}

      {hasAuthed && (
        <Result
          icon={<SafetyCertificateFilled />}
          title={"您已完成实名认证"}
          extra={[
            <div style={{ margin: 8 }}>认证企业：{company}</div>,
            <span>
              认证信息由
              <a
                href="https://ent.bestsign.cn/account-center/login?id=pinzhuan-zhumianfeishiyong&utm_source=baidupc"
                target="_blank"
              >
                上上签
              </a>
              提供
            </span>,
          ]}
        />
      )}
      {!isBind && (
        <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
          <Empty
            description={<span>您还未绑定企业信息，请先绑定您的企业信息</span>}
          >
            <Button type="primary" onClick={goBindPage}>
              立即绑定
            </Button>
          </Empty>
        </div>
      )}
      {isProcess && (
        <div style={{ backgroundColor: "#ffffff" }}>
          <Result
            status="info"
            title="审核中"
            subTitle="您的企业信息资料正在审核中"
            extra={[
              <Button
                key="view"
                type="primary"
                onClick={() =>
                  history.push({
                    pathname: "/base/my-process/company-info-process",
                  })
                }
              >
                点击查看
              </Button>,
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default IdentificationInfo;
