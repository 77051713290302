import React from "react";
import { Modal, Input, Form, Select, message, Button } from "antd";
import { applyCreditContract } from "@/requests/contract";

interface ModalProps {
  visible: boolean;
  setVisible: Function;
  fetchData: Function;
}

interface FormValuesType {
  creditor_contacts: string; //应收账款债权人指定接收人姓名
  creditor_address: string; //应收账款债权人指定送达地址
  creditor_phone?: string; //应收账款债权人指定接收人电话（座机）
  creditor_mobile: string; //应收账款债权人指定接收人手机
  creditor_email: string; //应收账款债权人接收人电子邮箱
  creditor_wechat: string; //应收账款债权人指定接收人微信
  bank_account: string; //应收账款接收银行账户账号
  bank_account_name: string; //应收账款接收银行账户户名
  bank_account_sub_name: string; //应收账款接收银行账户开户行
  invoicing_method: string; //发票开具方式
}

const ApplyCreditContractModal = (props: ModalProps) => {
  const { visible, setVisible, fetchData } = props;
  const [fetching, setFetching] = React.useState(false);
  const [form] = Form.useForm();

  //取消申请综合授信合同
  const handleCancel = (e: any) => {
    Modal.confirm({
      title: "温馨提示",
      content: "确认放弃当前编辑内容吗？",
      okText: "放弃",
      cancelText: "继续编辑",
      autoFocusButton: "cancel",
      onOk: () => setVisible(false),
      onCancel: () => {},
    });
  };

  //确认提交合同申请
  const handleOk = (values: FormValuesType) => {
    doApply(values);
  };

  const doApply = async (values: FormValuesType) => {
    setFetching(true);
    const {
      creditor_address,
      creditor_contacts,
      creditor_email,
      creditor_mobile,
      creditor_wechat,
      creditor_phone,
      bank_account,
      bank_account_name,
      bank_account_sub_name,
      invoicing_method,
    } = values;

    const res = await applyCreditContract({
      creditor_address,
      creditor_contacts,
      creditor_email,
      creditor_mobile,
      creditor_wechat,
      creditor_phone: creditor_phone ? creditor_phone : "无",
      creditor_account: JSON.stringify({
        户名: bank_account_name,
        开户行: bank_account_sub_name,
        账号: bank_account,
      }),
      invoicing_method,
    });
    if (res.code === 0) {
      message.success("提交申请成功，请等待业务人员审核，审核成功后方可签署");
      fetchData();
      setVisible(false);
    } else {
      message.error(`提交失败：${res.message}`);
    }
    setFetching(false);
  };

  return (
    <Modal
      visible={visible}
      title={
        <span>
          <div className="title-prefix"></div>申请综合授信合同{" "}
        </span>
      }
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
    >
      <Form
        form={form}
        preserve={false}
        layout={"vertical"}
        onFinish={handleOk}
      >
        <Form.Item
          label="应收账款指定接收人姓名"
          name="creditor_contacts"
          rules={[
            {
              required: true,
              message: "请输入应收账款指定接收人姓名",
            },
          ]}
        >
          <Input placeholder="必填" />
        </Form.Item>
        <Form.Item
          label="应收账款指定送达地址"
          name="creditor_address"
          rules={[
            {
              required: true,
              message: "请输入应收账款指定送达地址",
            },
          ]}
        >
          <Input.TextArea placeholder="必填" />
        </Form.Item>
        <Form.Item label="应收账款指定接收人电话（座机）" name="creditor_phone">
          <Input placeholder="选填" />
        </Form.Item>
        <Form.Item
          label="应收账款指定接收人手机"
          name="creditor_mobile"
          rules={[
            {
              required: true,
              message: "请输入应收账款指定接收人手机",
            },
            {
              len: 11,
              message: "请输入正确格式的手机号",
            },
          ]}
        >
          <Input placeholder="必填" />
        </Form.Item>
        <Form.Item
          label="应收账款指定接收人电子邮箱"
          name="creditor_email"
          rules={[
            {
              required: true,
              message: "请输入应收账款指定接收人电子邮箱",
            },
            {
              type: "email",
              message: "请输入正确格式的电子邮箱",
            },
          ]}
        >
          <Input placeholder="必填" />
        </Form.Item>
        <Form.Item
          label="应收账款指定接收人即时通讯号（微信）"
          name="creditor_wechat"
          rules={[
            {
              required: true,
              message: "请输入应收账款指定接收人即时通讯号",
            },
          ]}
        >
          <Input placeholder="必填" />
        </Form.Item>
        <Form.Item
          label="应收账款指定接收银行账户户名"
          name="bank_account_name"
          rules={[
            {
              required: true,
              message: "请输入应收账款指定接收银行账户户名",
            },
          ]}
        >
          <Input placeholder="必填" />
        </Form.Item>
        <Form.Item
          label="应收账款指定接收银行账户账号"
          name="bank_account"
          rules={[
            {
              required: true,
              message: "请输入应收账款指定接收银行账户账号",
            },
          ]}
        >
          <Input placeholder="必填" />
        </Form.Item>
        <Form.Item
          label="应收账款指定接收银行账户开户行"
          name="bank_account_sub_name"
          rules={[
            {
              required: true,
              message: "请输入应收账款指定接收银行账户开户行",
            },
          ]}
        >
          <Input placeholder="必填" />
        </Form.Item>
        <Form.Item
          label="发票开具方式"
          name="invoicing_method"
          rules={[
            {
              required: true,
              message: "请选择发票开具方式",
            },
          ]}
        >
          <Select placeholder="必填">
            <Select.Option value="不需要发票">不需要发票</Select.Option>
            <Select.Option value="增值税普通发票">增值税普通发票</Select.Option>
            <Select.Option value="增值税专用发票">增值税专用发票</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 36,
              padding: "0 100px",
            }}
          >
            <Button
              type="primary"
              htmlType={"submit"}
              style={{ padding: "0 36px" }}
              loading={fetching}
            >
              确认
            </Button>
            <Button onClick={handleCancel} style={{ padding: "0 36px" }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ApplyCreditContractModal;
