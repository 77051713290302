import * as React from "react";

import { Descriptions, Collapse, Divider, Image } from "antd";
import FileViewer from "@/ui-components/FileViewer";

enum ShareholderType {
  "个人" = 0,
  "企业" = 1,
}

const ShareholderDisplayer = ({ item }: { item: ShareholderItem }) => {
  if (item.type === 0) {
    return (
      <Descriptions bordered column={2}>
        <Descriptions.Item label="姓名">{item.name}</Descriptions.Item>
        <Descriptions.Item label="持股比例">
          {item.shareholding_ratio}%
        </Descriptions.Item>
        <Descriptions.Item label="类型" span={2}>
          {ShareholderType[item.type]}
        </Descriptions.Item>

        <Descriptions.Item label="联系电话">{item.phone}</Descriptions.Item>
        <Descriptions.Item label="联系邮箱">{item.email}</Descriptions.Item>
        <Descriptions.Item label="联系地址" span={2}>
          {item.address}
        </Descriptions.Item>

        <Descriptions.Item label="征信报告">
          <FileViewer file={item.credit} />
        </Descriptions.Item>
        <Descriptions.Item label="身份证信息">
          {item.identity_pic &&
            item.identity_pic.map((x) => <FileViewer file={x} key={x} />)}
        </Descriptions.Item>
      </Descriptions>
    );
  }

  if (item.type === 1) {
    return (
      <div>
        <Divider>基础信息</Divider>
        <Descriptions bordered column={2}>
          <Descriptions.Item label="企业名称">
            {item.company_name}
          </Descriptions.Item>
          <Descriptions.Item label="持股比例">
            {item.shareholding_ratio}%
          </Descriptions.Item>
          <Descriptions.Item label="类型" span={2}>
            {ShareholderType[item.type]}
          </Descriptions.Item>
          <Descriptions.Item label="企业电话">
            {item.company_phone}
          </Descriptions.Item>
          <Descriptions.Item label="社会统一信用代码">
            {item.uniform_social_credit_code}
          </Descriptions.Item>
          <Descriptions.Item label="注册地址" span={2}>
            {item.company_register_address}
          </Descriptions.Item>
          <Descriptions.Item label="联系地址" span={2}>
            {item.company_register_address}
          </Descriptions.Item>
          <Descriptions.Item label="营业执照">
            <FileViewer file={item.business_licence_pic} />
          </Descriptions.Item>
          <Descriptions.Item label="征信报告">
            <FileViewer file={item.credit} />
          </Descriptions.Item>
        </Descriptions>
        <Divider>法人信息</Divider>
        <Descriptions bordered column={2}>
          <Descriptions.Item label="姓名">
            {item.legal_person}
          </Descriptions.Item>
          <Descriptions.Item label="职位">
            {item.legal_person_position}
          </Descriptions.Item>
          <Descriptions.Item label="联系电话">
            {item.legal_person_phone}
          </Descriptions.Item>
          <Descriptions.Item label="电子邮箱">
            {item.legal_person_email}
          </Descriptions.Item>

          <Descriptions.Item label="联系地址" span={2}>
            {item.legal_person_address}
          </Descriptions.Item>
          <Descriptions.Item label="身份证号">
            {item.legal_person_id_card_num}
          </Descriptions.Item>

          <Descriptions.Item label="身份证信息" span={3}>
            {item.legal_person_id_card_pic &&
              item.legal_person_id_card_pic.map((x, index) => {
                return <FileViewer file={x} key={x} />;
              })}
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
  return null;
};

export default ShareholderDisplayer;
