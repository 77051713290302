import React from "react";
import {
  Modal,
  Input,
  Form,
  Button,
  Upload,
  Image,
  InputNumber,
  Radio,
} from "antd";
import { UPLOAD_ACTION } from "@/requests/company";
import { UploadOutlined } from "@ant-design/icons";
import { UploadPicWidget } from "@/ui-components";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

interface PropsType {
  visible: boolean;
  setVisible: Function;
  companyInfo: CompanyInfoItem;
  setCompanyInfo: Function;
  setEdited: Function;
}

const AddShareholderModal = (props: PropsType) => {
  const { visible, setVisible, companyInfo, setCompanyInfo, setEdited } = props;
  const [form] = Form.useForm();
  const [personAVisible, setPersonAVisible] = React.useState(false);
  const [personBVisible, setPersonBVisible] = React.useState(false);
  const [type, setType] = React.useState(0);
  const handleOk = (values: any) => {
    console.log(values);
    let arr = companyInfo.shareholder_message.map((x) => x);
    if (type === 0) {
      const { pic_1, pic_2 } = values;
      arr.push({
        name: values.name,
        email: values.email,
        shareholding_ratio: String(values.shareholding_ratio),
        phone: values.phone,
        address: values.address,
        credit: values.credit ? values.credit.fileList[0].response.data : "",

        identity_pic: [
          pic_1[pic_1.length - 1].response.data,

          pic_2 && pic_2[pic_2.length - 1].response.data,
        ],
        type: 0,
      });
    }
    if (type === 1) {
      const { legal_person_id_card_pic_1, legal_person_id_card_pic_2 } = values;
      arr.push({
        company_name: values.company_name,
        company_register_address: values.company_register_address,
        company_contact_address: values.company_contact_address,
        company_phone: values.company_phone,
        uniform_social_credit_code: values.uniform_social_credit_code,
        business_licence_pic: values.business_licence_pic.file.response.data,
        legal_person: values.legal_person,
        legal_person_position: values.legal_person_position,
        legal_person_id_card_num: values.legal_person_id_card_num,

        legal_person_id_card_pic: [
          legal_person_id_card_pic_1[legal_person_id_card_pic_1.length - 1]
            .response.data,
          legal_person_id_card_pic_2 &&
            legal_person_id_card_pic_2[legal_person_id_card_pic_2.length - 1]
              .response.data,
        ],
        legal_person_phone: values.legal_person_phone,
        legal_person_email: values.legal_person_email,
        legal_person_address: values.legal_person_address,
        shareholding_ratio: String(values.shareholding_ratio),
        credit: values.credit ? values.credit.fileList[0].response.data : "",
        type: 1,
      });
    }

    console.log(arr);
    setCompanyInfo({
      ...companyInfo,
      shareholder_message: arr,
    });
    setEdited(true);
    setVisible(false);
  };

  const handleCancel = () => {
    Modal.confirm({
      title: "温馨提示",
      content: "确认放弃当前编辑内容吗？",
      okText: "放弃",
      cancelText: "继续编辑",
      autoFocusButton: "cancel",
      onOk: () => setVisible(false),
      onCancel: () => {},
    });
  };
  const UploadButton = ({ disabled = false }) => (
    <Button icon={<UploadOutlined />} disabled={disabled}>
      点击上传
    </Button>
  );

  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>添加股东
        </span>
      }
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
    >
      <Form
        onFinish={handleOk}
        {...formItemLayout}
        preserve={false}
        form={form}
      >
        <Form.Item label="类型" name="type" initialValue={type}>
          <Radio.Group
            onChange={(e) => {
              setType(e.target.value);
            }}
            value={type}
          >
            <Radio value={0}>自然人</Radio>
            <Radio value={1}>企业</Radio>
          </Radio.Group>
        </Form.Item>
        {type === 0 && (
          <>
            <Form.Item
              label="姓名"
              name="name"
              rules={[{ required: true, message: "请输入股东名称" }]}
            >
              <Input placeholder="请输入股东名称" />
            </Form.Item>
            <Form.Item
              label="联系电话"
              name="phone"
              rules={[{ required: true, message: "请输入联系电话！" }]}
            >
              <Input placeholder="请输入联系电话" />
            </Form.Item>
            <Form.Item
              label="联系地址"
              name="address"
              rules={[{ required: true, message: "请输入联系地址！" }]}
            >
              <Input.TextArea placeholder="请输入联系地址" />
            </Form.Item>
            <Form.Item
              label="电子邮箱"
              name="email"
              rules={[
                { required: true, message: "请输入电子邮箱" },
                {
                  type: "email",
                  message: "请输入正确格式的邮箱",
                },
              ]}
            >
              <Input placeholder="请输入电子邮箱" />
            </Form.Item>

            <Form.Item label="身份证证件" required>
              <div style={{ marginTop: 4, color: "red" }}>
                原件正反面照片或复印件加盖公章：若身份证正反面在同一张图片，只需上传一张图片
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  paddingTop: 8,
                }}
              >
                <Form.Item
                  style={{ display: "inline-block" }}
                  name="pic_1"
                  extra={
                    <span>
                      身份证正面{" "}
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setPersonAVisible(true);
                        }}
                      >
                        示例
                      </a>
                    </span>
                  }
                  valuePropName="value"
                >
                  <UploadPicWidget action={UPLOAD_ACTION} />
                </Form.Item>
                <Form.Item
                  style={{ display: "inline-block" }}
                  name="pic_2"
                  extra={
                    <span>
                      身份证反面{" "}
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setPersonBVisible(true);
                        }}
                      >
                        示例
                      </a>
                    </span>
                  }
                >
                  <UploadPicWidget action={UPLOAD_ACTION} />
                </Form.Item>
              </div>
            </Form.Item>
          </>
        )}
        {type === 1 && (
          <>
            <Form.Item
              label="公司名称"
              name="company_name"
              rules={[{ required: true, message: "请输入公司名称" }]}
            >
              <Input placeholder="请输入公司名称" />
            </Form.Item>

            <Form.Item
              label="注册地址"
              name="company_register_address"
              rules={[{ required: true, message: "请输入注册地址" }]}
            >
              <Input.TextArea placeholder="请输入注册地址" />
            </Form.Item>
            <Form.Item
              label="联系地址"
              name="company_contact_address"
              rules={[{ required: true, message: "请输入联系地址" }]}
            >
              <Input.TextArea placeholder="请输入联系地址" />
            </Form.Item>
            <Form.Item
              label="企业电话"
              name="company_phone"
              rules={[{ required: true, message: "请输入企业电话" }]}
            >
              <Input placeholder="请输入企业电话" />
            </Form.Item>
            <Form.Item
              label="三证合一代码"
              name="uniform_social_credit_code"
              rules={[{ required: true, message: "请输入社会统一信用代码" }]}
            >
              <Input placeholder="请输入社会统一信用代码" />
            </Form.Item>
            <Form.Item
              label="营业执照"
              name="business_licence_pic"
              extra="请上传原件照片、扫描件、或复印件加盖公章，支持格式包括.jpg/.png/.pdf，文件大小不超过10M"
              rules={[{ required: true, message: "请上传企业营业执照" }]}
              hasFeedback
            >
              <Upload
                action={UPLOAD_ACTION}
                onChange={({ file, fileList }) => {
                  if (file.status === "success") {
                    console.log(file.response);
                  }
                }}
                onRemove={() => {
                  form.setFieldsValue({ business_license_pic: undefined });
                }}
              >
                <UploadButton />
              </Upload>
            </Form.Item>
            <Form.Item
              label="法人姓名"
              name="legal_person"
              rules={[{ required: true, message: "请输入法人姓名" }]}
            >
              <Input placeholder="请输入法人姓名" />
            </Form.Item>
            <Form.Item
              label="法人职位"
              name={"legal_person_position"}
              rules={[{ required: true, message: "请输入法人职位" }]}
            >
              <Input placeholder="请输入法人职位" />
            </Form.Item>
            <Form.Item
              label="法人身份证号"
              name="legal_person_id_card_num"
              rules={[{ required: true, message: "请输入法人身份证号" }]}
            >
              <Input placeholder="请输入法人身份证号" />
            </Form.Item>
            <Form.Item label="法人身份证" required>
              <div style={{ marginTop: 4, color: "red" }}>
                原件正反面照片或复印件加盖公章，若正反面在同一张图片，只需上传一张图片
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  paddingTop: 8,
                }}
              >
                <Form.Item
                  style={{ display: "inline-block" }}
                  name="legal_person_id_card_pic_1"
                  rules={[{ required: true, message: "请上传法人身份证正面" }]}
                  extra={
                    <span>
                      身份证正面{" "}
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setPersonAVisible(true);
                        }}
                      >
                        示例
                      </a>
                    </span>
                  }
                  valuePropName="value"
                >
                  <UploadPicWidget action={UPLOAD_ACTION} />
                </Form.Item>
                <Form.Item
                  style={{ display: "inline-block" }}
                  name="legal_person_id_card_pic_2"
                  // rules={[{ required: true, message: "请上传法人身份证反面" }]}
                  extra={
                    <span>
                      身份证反面{" "}
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setPersonBVisible(true);
                        }}
                      >
                        示例
                      </a>
                    </span>
                  }
                >
                  <UploadPicWidget action={UPLOAD_ACTION} />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item
              label="法人电话号码"
              name="legal_person_phone"
              rules={[
                { required: true, message: "请输入法人电话号码" },
                {
                  len: 11,
                  message: "请输入正确格式的手机号码",
                },
              ]}
            >
              <Input placeholder="请输入法人电话号码" />
            </Form.Item>
            <Form.Item
              label="法人电子邮箱"
              name="legal_person_email"
              rules={[
                { required: true, message: "请输入法人电子邮箱" },
                {
                  type: "email",
                  message: "请输入正确格式的邮箱",
                },
              ]}
            >
              <Input placeholder="请输入法人电子邮箱" />
            </Form.Item>
            <Form.Item
              label="法人联系地址"
              name="legal_person_address"
              rules={[{ required: true, message: "请输入法人联系地址" }]}
            >
              <Input.TextArea placeholder="请输入法人联系地址" />
            </Form.Item>
          </>
        )}
        <Form.Item label="持股比例" required>
          <Form.Item
            name="shareholding_ratio"
            noStyle
            rules={[{ required: true, message: "请输入持股比例" }]}
          >
            <InputNumber min={0} max={100} />
          </Form.Item>
          %
        </Form.Item>
        <Form.Item
          label="征信报告"
          name="credit"
          extra="请上传原件照片、扫描件、或复印件加盖公章，支持格式包括.jpg/.png/.pdf，文件大小不超过10M"
          hasFeedback
        >
          <Upload
            action={UPLOAD_ACTION}
            onChange={({ file, fileList }) => {
              if (file.status === "success") {
                console.log(file.response);
              }
            }}
            onRemove={() => {
              form.setFieldsValue({ shareholder_credit: undefined });
            }}
          >
            <UploadButton />
          </Upload>
        </Form.Item>
        <Form.Item noStyle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              margin: "36px 0 24px 0",
              padding: "0 100px",
            }}
          >
            <Button
              type="primary"
              htmlType={"submit"}
              style={{ padding: "0 36px" }}
            >
              确认
            </Button>
            <Button onClick={handleCancel} style={{ padding: "0 36px" }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
      <Modal
        title=""
        footer={null}
        visible={personAVisible}
        onCancel={() => {
          setPersonAVisible(false);
        }}
      >
        <Image
          src={
            "https://lq-fe-img.oss-cn-hangzhou.aliyuncs.com/lq-credit/sample/person-A.jpg"
          }
        />
      </Modal>
      <Modal
        title=""
        footer={null}
        visible={personBVisible}
        onCancel={() => {
          setPersonBVisible(false);
        }}
      >
        <Image
          src={
            "https://lq-fe-img.oss-cn-hangzhou.aliyuncs.com/lq-credit/sample/person-B.jpg"
          }
        />
      </Modal>
    </Modal>
  );
};

export default AddShareholderModal;
