import * as React from "react";
import { Layout, Dropdown, Avatar, Menu, message, Modal, Tabs } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import * as UserRequests from "@/requests/user";
import { useCtxState } from "@/store/App.store";
import {
  withRouter,
  RouteComponentProps,
  useLocation,
  useHistory,
} from "react-router-dom";
import "./index.less";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { dropByCacheKey } from "react-router-cache-route";

const titleCfg = [
  { path: "/base/my-company/company-info", title: "企业信息" },
  { path: "/base/my-company/submit-company-info", title: "提交企业基础信息" },
  { path: "/base/my-company/bind-apply", title: "绑定企业" },
  { path: "/base/my-company/company-change-apply", title: "企业信息变更" },
  {
    path: "/base/my-company/company-apply-list",
    title: "提交记录（企业信息）",
  },
  {
    path: "/base/my-company/apply-resubmit",
    title: "企业信息重提交",
  },
  {
    path: "/base/my-company/company-apply-detail",
    title: "提交详情（企业信息）",
  },
  {
    path: "/base/my-company/legal-person-info",
    title: "法人信息",
  },
  {
    path: "/base/my-company/submit-legal-person-info",
    title: "提交法人信息",
  },
  {
    path: "/base/my-company/agent-info",
    title: "经办人信息",
  },
  {
    path: "/base/my-company/submit-agent-info",
    title: "提交经办人信息",
  },
  {
    path: "/base/my-company/tax-info",
    title: "财税资料",
  },
  {
    path: "/base/my-company/submit-tax-info",
    title: "提交财税资料",
  },
  {
    path: "/base/my-company/shareholder-info",
    title: "股东信息",
  },
  {
    path: "/base/my-company/submit-shareholder-info",
    title: "提交股东信息",
  },
  {
    path: "/base/my-company/shareholder-info/detail",
    title: "股东详情",
  },
  {
    path: "/base/my-company/identification",
    title: "实名认证",
  },
  { path: "/base/my-credit/credit-info", title: "授信信息" },
  { path: "/base/my-credit/credit-apply", title: "综合授信申请" },
  { path: "/base/my-credit/credit-record", title: "授信记录" },
  { path: "/base/my-process/company-info-process", title: "基础资料提交记录" },
  { path: "/base/my-process/credit-process", title: "综合授信提交记录" },
  { path: "/base/my-credit/base-quota", title: "综合额度" },
  { path: "/base/contract/all-contracts", title: "所有合同" },
  { path: "/base/contract/signing-contracts", title: "待签署的" },
  { path: "/base/contract/applying-contracts", title: "申请中的" },
  { path: "/base/business/product-list", title: "业务办理" },
  { path: "/base/business/product-list/detail", title: "产品详情" },
  { path: "/base/business/apply", title: "业务申请" },
  { path: "/base/my-process/business-process", title: "业务办理记录" },
  { path: "/base/business/record/detail", title: "业务办理详情" },
  { path: "/base/my-process/billing-process", title: "放款记录" },
  { path: "/base/my-process/billing-detail", title: "放款详情" },
  { path: "/base/my-process/repayment-process", title: "还款记录" },
  { path: "/base/my-process/repayment-process/detail", title: "还款申请详情" },
];

/**
 * @global_state
 */

const urlMap2Title = (url: string) => {
  const matchedItem = titleCfg.find((x) => x.path === url);
  if (matchedItem) {
    return matchedItem.title;
  } else {
    return "";
  }
};
const MyHeader = (props: RouteComponentProps) => {
  const location = useLocation();
  const history = useHistory();
  const [cachedRoutes, setCachedRoutes] = React.useState<string[]>([]);
  const [activeRoute, setActiveRoute] = React.useState("");
  const [{ userInfo, sidebarCollapsed }, dispatch] = useCtxState();
  const [title, setTitle] = React.useState("资产清单");
  React.useEffect(() => {
    const { pathname } = location;
    setTitle(urlMap2Title(pathname));
    setActiveRoute(pathname);
    const isExist = !!cachedRoutes.find((x) => x === pathname);
    if (!isExist && pathname !== "/base") {
      let arr = cachedRoutes.map((x) => x);
      arr.push(pathname);
      setCachedRoutes(arr);
    }
  }, [location]);

  const doLogout = async () => {
    try {
      const res = await UserRequests.logout();
      if (res.code === 12006) {
        message.warn("您已退出登录，请重新登录！");
        setTimeout(() => window.location.replace(`/#/login`), 500);
      } else {
        message.error(res.code);
      }
    } catch (e) {}
  };
  const handleMenuClick = ({ key }: any) => {
    if (key === "logout") {
      Modal.confirm({
        title: "温馨提示",
        content: "确认退出登录当前账号吗？",
        icon: <ExclamationCircleFilled />,
        onOk() {
          doLogout();
        },
        onCancel() {},
        cancelText: "取消",
        okText: "确认",
      });
    }
    if (key === "setting") {
      history.push("/userinfo-center");
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="setting">
        <span className="dropmenu-item">账号设置</span>
      </Menu.Item>
      <Menu.Item key="logout">
        <span className="dropmenu-item">退出登录</span>
      </Menu.Item>
    </Menu>
  );
  if (!userInfo) return null;

  return (
    <div>
      <Layout.Header className="credit_header">
        <span
          style={{ fontSize: "20px", cursor: "pointer" }}
          onClick={() => {
            dispatch({ type: "toggle:sidebarCollapsed" });
          }}
        >
          {sidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          <span
            style={{
              color: "#2d3843",
              fontSize: 20,
              marginLeft: 36,
              fontWeight: 500,
            }}
          >
            {title}
          </span>
        </span>
        <span>
          <span>
            欢迎您，
            <a
              style={{ paddingRight: 16 }}
              onClick={(e) => {
                e.preventDefault();
                history.push("/userinfo-center");
              }}
            >
              {userInfo.user_name}
            </a>
          </span>
          <Dropdown overlay={menu} className="dropdown-menu">
            <span>
              {userInfo.avatar ? (
                <Avatar size={40} src={`/api/user/avatar/${userInfo.avatar}`} />
              ) : (
                <Avatar size={40} className="avatar-circle">
                  {userInfo.user_name.slice(0, 1)}
                </Avatar>
              )}
            </span>
          </Dropdown>
        </span>
      </Layout.Header>
      <div className="card-container">
        <Tabs
          type="editable-card"
          onChange={(activeKey) => {
            history.push(`${activeKey}`);
          }}
          activeKey={activeRoute}
          onEdit={(targetKey, action) => {
            if (action === "remove") {
              if (cachedRoutes.length < 2) {
                return;
              }
              let arr = cachedRoutes.filter((x) => x !== targetKey);

              setCachedRoutes(arr);
              dropByCacheKey(targetKey.toString());
              history.push(`${arr[arr.length - 1]}`);
            }
          }}
        >
          {cachedRoutes.map((route) => {
            return (
              <Tabs.TabPane tab={urlMap2Title(route)} key={route} closable>
                {null}
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default withRouter(MyHeader);
