/// <reference path="type.d.ts" />
/// <reference path="user.d.ts" />

import request from "./_base";

/**
 * 登录
 * @param  {LoginReq} input
 */
export async function login(
  input: LoginReq
): Promise<ResponseProto<{ data: UserInfoItem }>> {
  return request({
    method: "POST",
    url: `/login`,
    data: input,
  }) as any;
}

/**
 * 获取用户登录信息
 */
export async function getUserInfo(): Promise<
  ResponseProto<{ data: UserInfoItem }>
> {
  return request({
    method: "GET",
    url: `/user/info`,
  }) as any;
}

/**
 * 注册
 * @param  {RegisterReq} input
 */
export async function register(
  input: RegisterReq
): Promise<ResponseProto<{ data: UserInfoItem }>> {
  return request({
    method: "POST",
    url: `/user/register`,
    data: input,
  }) as any;
}

/**
 * 用户名唯一校验
 * @param  {string} user_name
 */
export async function validateUserName(input: {
  user_name: string;
}): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/user/register/username/unique`,
    data: input,
  }) as any;
}

/**
 * 根据phone获取图片校验码
 * @param  {string} phone
 */
export async function getRegisterImgCode(
  phone: UserInfoItem["phone"]
): Promise<ResponseProto<{}>> {
  return request({
    method: "GET",
    url: `/user/register/image/${phone}`,
  }) as any;
}

/**
 * 退出登录
 */
export async function logout(): Promise<ResponseProto<{}>> {
  return request({
    method: "GET",
    url: `/logout`,
  }) as any;
}

/**
 * 获取注册短信验证码
 * @param {GetRegisterSmCodeReq} input
 */
export async function getRegisterSmCode(
  input: GetRegisterSmCodeReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/user/register/sm/code`,
    data: input,
  }) as any;
}

/**
 * 用户手机号唯一校验
 * @param  {string} phone
 */
export async function validatePhone(input: {
  phone: string;
}): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/user/register/phone/unique`,
    data: input,
  }) as any;
}

/**
 * 用户邮箱唯一校验
 * @param  {string} email
 */
export async function validateEmail(input: {
  email: string;
}): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/user/register/email/unique`,
    data: input,
  }) as any;
}

/**
 * 找回（重置）密码
 * @param  {ResetPasswordReq} input
 */
export async function resetPassword(
  input: ResetPasswordReq
): Promise<ResponseProto<{ data: null }>> {
  return request({
    method: "PUT",
    url: `/user/retrieve/password`,
    data: input,
  }) as any;
}

/**
 * 修改用户信息
 * @param  {UpdateUserInfoReq} input
 */
export async function updateUserInfo(
  input: UpdateUserInfoReq
): Promise<ResponseProto<{ data: null }>> {
  return request({
    method: "PUT",
    url: `/user/info`,
    data: input,
  }) as any;
}

/**
 * 修改密码
 * @param  {ModifyPasswordReq} input
 */
export async function modifyPassword(
  input: ModifyPasswordReq
): Promise<ResponseProto<{ data: null }>> {
  return request({
    method: "PUT",
    url: `/user/password`,
    data: input,
  }) as any;
}

/**
 * 获取重置密码短信验证码
 * @param {GetRegisterSmCodeReq} input
 */
export async function getRetrieveSmCode(
  input: GetRegisterSmCodeReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/user/retrieve/sm/code`,
    data: input,
  }) as any;
}

/**
 * 根据phone获取图片校验码(重置密码)
 * @param  {string} phone
 */
export async function getResetPasswordImgCode(
  phone: UserInfoItem["phone"]
): Promise<ResponseProto<{}>> {
  return request({
    method: "GET",
    url: `/user/register/image/${phone}`,
  }) as any;
}
/**
 * 上传头像(重置密码)
 * @param  {any} phone
 */
export function uploadAvatar(input: {
  file: File;
}): Promise<ResponseProto<{ data: string }>> {
  let formData = new FormData();

  formData.append("file", input.file);
  return request({
    method: "POST",
    url: "/user/avatar/upload",
    data: formData,
  }) as any;
}
